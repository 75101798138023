import LightLogo from '../Components/LightLogo';
import { useNavigate, useParams } from 'react-router-dom';
import './Pages.css';
import ExamplePic from '../Assets/example.jpeg';
import { useEffect } from 'react';
import JoinFB from '../Components/JoinFB';

interface HesMineProps {
    queryResult: any;
    setQueryResult: React.Dispatch<React.SetStateAction<any>>;
    authToken: string;
    uploadedImages: Array<string>;
    setUploadedImages: React.Dispatch<React.SetStateAction<Array<string>>>;
}

const HesMine:React.FC<HesMineProps> = ({ queryResult, authToken, setQueryResult, uploadedImages, setUploadedImages }) => {
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!queryResult || queryResult.query_id !== id) {
            fetch(`https://api.ishemine.com/evals/history/${id}/`, {
                method: "GET",
                headers: {
                    Authorization: authToken,
                }
            }).then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        console.log(json);
                        setQueryResult(json);
                        setUploadedImages(json.photos.map((photo: any) => photo.url));
                    })
                }   
            })
        }
    }, [id]);

    useEffect(() => {
        console.log(queryResult);
        console.log(uploadedImages);
        if (!queryResult || !queryResult.query_id) navigate("/");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", authToken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "query_id": id
        });
        var requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: raw
        };
        fetch("https://api.ishemine.com/evals/query/opt-in/", requestOptions)
    }, [queryResult]);
    
    return (
        <div className="hes-mine-bg flex flex-col min-h-screen">
            <header className="p-4">
                <div className="container mx-auto flex items-center justify-between hover:cursor-pointer" onMouseUp={() => navigate("/")}>
                    <LightLogo/>
                </div>
            </header>
            <main className="flex-1 mx-auto p-4 text-center overflow-hidden w-screen">
                <div id="inner" className="lock-border">
                    <div className="flex justify-center m-6 z-10">
                        <img src={uploadedImages.length > 0 ? uploadedImages[0] : ExamplePic} alt="example pic" className="h-80 w-60 object-cover rounded-xl"/>
                    </div>
                    <div className="dela-gothic text-white z-10">
                        <h1 className="text-4xl">BACK OFF EVERYONE!</h1>
                        <h3 className="text-2xl">He's yours.</h3>
                    </div>
                    { queryResult && 
                        <p className="mt-2 text-cyan-300 text-lg z-10">Scanned {queryResult.date ? (new Date(queryResult.date)).toLocaleDateString('en-US', {month: 'short', day: '2-digit', year: 'numeric'}) :  (new Date()).toLocaleDateString('en-US', {month: 'short', day: '2-digit', year: 'numeric'})}</p>}
                </div>
                <JoinFB/>
            </main>
        </div>
    );
}

export default HesMine;