import React, { useState, useEffect, useRef } from 'react';
import Slideshow from './Slideshow';
import { IoChatboxEllipsesOutline } from 'react-icons/io5';
import { HiOutlineFlag } from 'react-icons/hi2';
import ReportModal from './ReportModal';
import { PostTypes } from '../Pages/types';
import { FaChevronUp, FaChevronDown, FaRegCommentDots, FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MessageCircle } from 'react-feather';
import { Confession } from '../Pages/Subject';
import dayjs from 'dayjs';


interface PostProps {
    id: string;
    authToken: string;
    images: string[];
    full: boolean;
    elapsedTime: string;
    onChatClick?: () => void;
    initialRating: number;
    caption: string;
    postType: PostTypes;
    initialComments: Confession[];
}

const Post: React.FC<PostProps> = ({ 
    id,
    images,
    full,
    elapsedTime,
    authToken,
    onChatClick,
    initialRating,
    postType,
    caption,
    initialComments
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false); // State to track if the caption is expanded
    const [rating, setRating] = useState<number>(initialRating); // State to track the rating
    const [ratingSelected, setRatingSelected] = useState(0); // 0: none, 1: up, -1: down
    const [showMoreButton, setShowMoreButton] = useState(false);
    const captionRef = useRef(null);
    const [comments, setComments] = useState<Confession[]>(initialComments);      

    // Additional state to manage the currently replying-to comment ID
    const [replyingTo, setReplyingTo] = useState<string | null>(null);
    const commentInputRef = useRef<HTMLInputElement>(null);

    // New state to keep track of collapsed comments
    const [collapsedComments, setCollapsedComments] = useState<string[]>(() => {
        // Initialize with all comment IDs to have replies collapsed by default
        return comments.flatMap(comment => comment.id);
    });

    const toggleCaption = () => {
        setIsExpanded(!isExpanded);
    };

    const executeRating = (postId: string, type: 'upvote' | 'downvote', token: string) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", authToken)
        const requestOptions: RequestInit = {
            method: 'POST',
            redirect: 'follow',
            headers: myHeaders
        };
        fetch(`https://api.ishemine.com/social/subject_comments/${postId}/${type}`, requestOptions)
    }

    const incrementRating = () => {
        if (ratingSelected === 1) {
          // If already upvoted, un-upvote
          setRating(rating - 1);
          setRatingSelected(0);
        } else {
          // If downvoted or not voted, upvote
          setRating(ratingSelected === -1 ? rating + 2 : rating + 1);
          setRatingSelected(1);
        }
        executeRating(id, 'upvote', authToken);
    };

    const decrementRating = () => {
        if (ratingSelected === -1) {
            // If already downvoted, un-downvote
            setRating(rating + 1);
            setRatingSelected(0);
        } else {
            // If upvoted or not voted, downvote
            setRating(ratingSelected === 1 ? rating - 2 : rating - 1);
            setRatingSelected(-1);
        }
        executeRating(id, 'downvote', authToken);
    };

    const truncateText = (text: string, charLimit: number) => {
        const truncated = text.length > charLimit ? text.substring(0, charLimit) + '...' : text;
        return truncated;
    };

    const renderCaption = () => {
        // Approximate character limit for 3 lines of text; you may need to adjust this based on your font size and container width.
        const charLimit = 150;
        return isExpanded ? caption : truncateText(caption, charLimit);
    };

    // Function to handle upvote on comments and replies
    const handleCommentUpvote = (commentId: string) => {
        const updateVotes = (comments: Confession[]): Confession[] => {
            return comments.map(comment => {
                if (comment.id === commentId) {
                    let newUpvotes = comment.num_upvotes;
                    let newDownvotes = comment.num_downvotes;

                    if (comment.interaction_type === 'U') {
                        newUpvotes--;
                    } else {
                        newUpvotes++;
                        if (comment.interaction_type === 'D') newDownvotes--;
                    }

                    return {
                        ...comment,
                        upvotes: newUpvotes,
                        downvotes: newDownvotes,
                        userHasUpvoted: !(comment.interaction_type === 'U'),
                        userHasDownvoted: false,
                        replies: comment.replies ? updateVotes(comment.replies) : []
                    };
                } else if (comment.replies) {
                    return { ...comment, replies: updateVotes(comment.replies) };
                }
                return comment;
            });
        };
        setComments(updateVotes(comments));
    };

    // Function to handle downvote on comments and replies
    const handleCommentDownvote = (commentId: string) => {
        const updateVotes = (comments: Confession[]): Confession[] => {
            return comments.map(comment => {
                if (comment.id === commentId) {
                    let newDownvotes = comment.num_downvotes;
                    let newUpvotes = comment.num_upvotes;

                    if (comment.interaction_type === 'U') {
                        newDownvotes--;
                    } else {
                        newDownvotes++;
                        if (comment.interaction_type === 'D') newUpvotes--;
                    }

                    return {
                        ...comment,
                        upvotes: newUpvotes,
                        downvotes: newDownvotes,
                        userHasDownvoted: !(comment.interaction_type === 'D'),
                        userHasUpvoted: false,
                        replies: comment.replies ? updateVotes(comment.replies) : []
                    };
                } else if (comment.replies) {
                    return { ...comment, replies: updateVotes(comment.replies) };
                }
                return comment;
            });
        };
        setComments(updateVotes(comments));
    };


    // Function to add a comment or reply
    const addComment = (text: string) => {
        const newComment: Confession = {
            id: `c-${Date.now()}`, // A more unique ID
            user: '-1',
            content: text,
            num_upvotes: 0,
            num_downvotes: 0,
            created_at: Date.now().toString(),
            replies: [],
            attachments: [],
            interaction_type: null,
            reply_to: null,
            type: 'confession' 
        };

        const addReplyToComments = (comments: Confession[], replyingToId: string, newComment: Confession): Confession[] => {
            return comments.map(comment => {
                if (comment.id === replyingToId) {
                    return { ...comment, replies: [...comment.replies, newComment] };
                } else if (comment.replies.length > 0) {
                    return { ...comment, replies: addReplyToComments(comment.replies, replyingToId, newComment) };
                }
                return comment;
            });
        };

        if (replyingTo) {
            setComments(currentComments => addReplyToComments(currentComments, replyingTo, newComment));
            setReplyingTo(null);
        } else {
            setComments(currentComments => [...currentComments, newComment]);
        }

        if (commentInputRef.current) {
            commentInputRef.current.value = ''; // Clear the comment input
        }
    };

    // Function to handle replying to a comment
    const handleReply = (commentId: string) => {
        // Find the comment being replied to
        const commentToReply = findCommentById(comments, commentId);
        
        // Set the placeholder text with the comment's text, truncated if necessary
        const placeholderText = commentToReply
            ? `Replying to "${truncateText(commentToReply.content, 50)}"...`
            : "Write a comment...";

        setReplyingTo(commentId);

        if (commentInputRef.current) {
            commentInputRef.current.focus(); // Focus on the comment input
            commentInputRef.current.placeholder = placeholderText;
        }
    };

    // Recursive function to find a comment by ID
    const findCommentById = (comments: Confession[], id: string): Confession | null => {
        for (let comment of comments) {
            if (comment.id === id) {
                return comment;
            }
            if (comment.replies) {
                const foundReply = findCommentById(comment.replies, id);
                if (foundReply) return foundReply;
            }
        }
        return null; // Confession not found
    };


    // Helper function to recursively count replies
    const countReplies = (comments:Confession[]): number => {
        return comments.reduce((acc:number, comment:Confession) => {
            return acc + (comment.replies ? countReplies(comment.replies) : 0) + 1;
        }, 0);
    };

    // Helper function to render comments and replies
    const renderComments = (comments: Confession[], depth = 0) => {
        return comments.map((comment) => (
        <div key={comment.id} className="mt-1 mb-2" style={{ marginLeft: `${depth * 20}px`, borderLeft: depth > 0 ? '1px solid gray' : 'none', paddingLeft: '10px' }}>
            <p className="text-xs text-gray-400">Anonymous • {dayjs(comment.created_at).fromNow()}</p>
            <p className="text-sm">{comment.content}</p>
            <div className="flex items-center text-xs text-gray-400">
            <button className={`flex items-center mr-2 ${comment.interaction_type === 'U' ? 'text-green-500' : ''}`} onClick={() => handleCommentUpvote(comment.id)}>
                <FaRegThumbsUp className="mr-1" />
                {comment.num_upvotes}
            </button>
            <button className={`flex items-center mr-2 ${comment.interaction_type === 'D' ? 'text-red-500' : ''}`} onClick={() => handleCommentDownvote(comment.id)}>
                <FaRegThumbsDown className="mr-1" />
                {comment.num_downvotes}
            </button>
            <button className="flex items-center font-bold" onClick={() => handleReply(comment.id)}>Reply</button>
            </div>
            {/* Toggle button for replies */}
            {comment.replies && comment.replies.length > 0 && (
            <button onClick={() => toggleCollapse(comment.id)} className="text-xs text-gray-300 italic">
                {collapsedComments.includes(comment.id) ? `Show Replies (${totalRepliesCount(comment.replies)})` : "Hide Replies"}
            </button>
            )}
            {/* Conditionally render replies */}
            {!collapsedComments.includes(comment.id) && comment.replies && (
            <div className="ml-4">
                {renderComments(comment.replies, depth + 1)}
            </div>
            )}
        </div>
        ));
    };  

    // Toggle the collapse state for replies
    const toggleCollapse = (commentId: string) => {
        setCollapsedComments(prev => {
        if (prev.includes(commentId)) {
            // If already collapsed, remove from the list to show replies
            return prev.filter(id => id !== commentId);
        } else {
            // If not collapsed, add to the list to hide replies
            return [...prev, commentId];
        }
        });
    };

    // Recursively count all replies in the comment tree
    const totalRepliesCount = (replies: Confession[]): number => {
        return replies.reduce((acc, reply) => acc + (reply.replies ? totalRepliesCount(reply.replies) : 0), replies.length);
    };

    useEffect(() => {
        if (captionRef.current) {
            // If the scrollHeight (actual height of the content) is greater than the clientHeight (visible area), show the "More" button.
            setShowMoreButton(caption.length > 150);
        }
    }, [caption]);

    return (
        <>
            {
                postType === PostTypes.SEARCH
                ? <div className="mt-4 mb-4">
                    <div className="flex flex-row justify-between items-center my-2">
                        <h2 className="font-semibold text-base text-gray-100">IHM Search <span className="ml-1 text-gray-200 text-xs font-normal">{elapsedTime}</span></h2>
                        <HiOutlineFlag className="w-4 h-4 text-white cursor-pointer" onClick={() => setShowModal(true)}/>
                    </div>
                    <div className="flex flex-row justify-between mb-4">
                        <div 
                            className="text-sm mr-4 flex-grow"
                            ref={captionRef}
                        >
                            {renderCaption()}
                            {!isExpanded && showMoreButton && (
                                <button
                                    onClick={toggleCaption}
                                    className="text-gray-300 underline text-sm ml-1"
                                >
                                    More
                                </button>
                            )}
                        </div>
                        <div className="flex flex-col items-center">
                            <FaChevronUp
                                className={`w-7 h-7 cursor-pointer ${ratingSelected === 1 ? 'text-red-500' : 'text-white'}`}
                                onClick={incrementRating}
                            />
                            <span className={`text-2xl font-bold ${ratingSelected !== 0 ? 'text-red-500' : 'text-white'}`}>
                                {rating}
                            </span>
                            <FaChevronDown
                                className={`w-7 h-7 cursor-pointer ${ratingSelected === -1 ? 'text-red-500' : 'text-white'}`}
                                onClick={decrementRating}
                            />
                        </div>
                    </div>
                    {isExpanded && (
                        <button
                            onClick={toggleCaption}
                            className="text-gray-200 underline text-sm mb-4"
                        >
                            Less
                        </button>
                    )}
                    <Slideshow images={images} full={full} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
                    {showModal && <ReportModal setShowModal={setShowModal} authToken={authToken} photoUrl={images[currentIndex]} />}
                    <div className="flex justify-between items-center mt-2">
                        <div className="w-1/4">
                            <div className="flex flex-row items-center justify-between w-14 px-2 py-1 border border-white rounded-full">
                                <MessageCircle className="w-5 h-5 text-white" />
                                <p className="text-sm text-gray font-bold">{countReplies(comments)}</p>
                            </div>
                        </div>
                        <div className="flex-grow flex items-center justify-center"> 
                            <button onClick={onChatClick ? onChatClick : () => console.log('clicked')} className="p-2 bg-purple-600 text-white text-sm rounded flex items-center justify-center hover:bg-purple-700 transition shadow-md w-4/5">
                                <IoChatboxEllipsesOutline className="w-6 h-6 mr-1" />
                                <span>Chat With Her</span>
                            </button>
                        </div>
                        <div className="w-1/4 flex justify-end"> 
                            <button onClick={() => setShowModal(true)} className="p-1 rounded flex items-center justify-center hover:bg-gray-800 transition">
                                {/* <HiOutlineFlag className="w-5 h-5 text-red-500" /> */}
                                <div className="text-red-500 text-sm font-semibold underline">Not him?</div>
                            </button>
                        </div>
                    </div>
                    {/* Confession input section */}
                    <div className="flex items-center mt-4">
                        <FaRegCommentDots className="mr-2" />
                        <input
                        ref={commentInputRef}
                        type="text"
                        placeholder={replyingTo ? `Replying to "${truncateText(findCommentById(comments, replyingTo)?.content || '', 40)}"...` : "Write a comment..."}
                        className="bg-transparent border border-gray-500 text-white rounded-full py-2 px-4 w-full text-sm"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                            addComment(e.currentTarget.value); // Add the comment on Enter key press
                            }
                        }}
                        />
                        {replyingTo && (
                        <AiOutlineCloseCircle
                            className="ml-2 text-lg cursor-pointer"
                            onClick={() => setReplyingTo(null)}
                        />
                        )}
                    </div>
                    {/* Confession section */}
                    <div className="mt-4">
                        {renderComments(comments)}
                    </div>
                </div>
                : postType === PostTypes.CONFESSION
                ? <div className="mt-4 mb-4">
                    <div className="flex flex-row justify-between items-center my-2">
                        <h2 className="font-semibold text-base text-gray-100">IHM Confession <span className="ml-1 text-gray-200 text-xs font-normal">{elapsedTime}</span></h2>
                        <HiOutlineFlag className="w-4 h-4 text-white cursor-pointer" onClick={() => setShowModal(true)}/>
                    </div>
                    <div className="flex flex-row justify-between mb-4">
                        <div 
                            className="text-lg font-bold mr-4 flex-grow"
                            ref={captionRef}
                        >
                            {renderCaption()}
                            {!isExpanded && showMoreButton && (
                                <button
                                    onClick={toggleCaption}
                                    className="text-gray-300 underline text-sm ml-1"
                                >
                                    More
                                </button>
                            )}
                        </div>
                        <div className="flex flex-col items-center">
                            <FaChevronUp
                                className={`w-7 h-7 cursor-pointer ${ratingSelected === 1 ? 'text-red-500' : 'text-white'}`}
                                onClick={incrementRating}
                            />
                            <span className={`text-2xl font-bold ${ratingSelected !== 0 ? 'text-red-500' : 'text-white'}`}>
                                {rating}
                            </span>
                            <FaChevronDown
                                className={`w-7 h-7 cursor-pointer ${ratingSelected === -1 ? 'text-red-500' : 'text-white'}`}
                                onClick={decrementRating}
                            />
                        </div>
                    </div>
                    {isExpanded && (
                        <button
                            onClick={toggleCaption}
                            className="text-gray-200 underline text-lg mb-4"
                        >
                            Less
                        </button>
                    )}
                    {showModal && <ReportModal setShowModal={setShowModal} authToken={authToken} photoUrl={images[currentIndex]} />}
                    <div className="flex justify-between items-center mt-2">
                        <div className="w-1/4">
                            <div className="flex flex-row items-center justify-between w-14 px-2 py-1 border border-white rounded-full">
                                <MessageCircle className="w-5 h-5 text-white" />
                                <p className="text-sm text-gray font-bold">{countReplies(comments)}</p>
                            </div>
                        </div>
                        <div className="flex-grow flex justify-center w-1/2"> 
                            <button onClick={onChatClick ? onChatClick : () => console.log('clicked')} className="p-2 bg-purple-600 text-white text-sm rounded flex items-center justify-center hover:bg-purple-700 transition shadow-md">
                                <IoChatboxEllipsesOutline className="w-6 h-6 mr-1" />
                                <span>Chat With Her</span>
                            </button>
                        </div>
                        <div className="w-1/4"></div>
                    </div>
                    {/* Confession input section */}
                    <div className="flex items-center mt-4">
                        <FaRegCommentDots className="mr-2" />
                        <input
                        ref={commentInputRef}
                        type="text"
                        placeholder={replyingTo ? `Replying to "${truncateText(findCommentById(comments, replyingTo)?.content || '', 40)}"...` : "Write a comment..."}
                        className="bg-transparent border border-gray-500 text-white rounded-full py-2 px-4 w-full text-sm"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                            addComment(e.currentTarget.value); // Add the comment on Enter key press
                            }
                        }}
                        />
                        {replyingTo && (
                        <AiOutlineCloseCircle
                            className="ml-2 text-lg cursor-pointer"
                            onClick={() => setReplyingTo(null)}
                        />
                        )}
                    </div>
                    {/* Confession section */}
                    <div className="mt-4">
                        {renderComments(comments)}
                    </div>
                </div>
                : postType === PostTypes.TINDER
                ? <div className="mt-4 mb-4">
                    <div className="flex flex-row justify-between items-center my-2">
                        <h2 className="font-semibold text-base text-gray-100">Spotted on... <span className="text-red-400 text-bold">Tinder</span> <span className="ml-1 text-gray-200 text-xs font-normal">{elapsedTime}</span></h2>
                        <HiOutlineFlag className="w-4 h-4 text-white cursor-pointer" onClick={() => setShowModal(true)}/>
                    </div>
                    <Slideshow images={images} full={full} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
                    {showModal && <ReportModal setShowModal={setShowModal} authToken={authToken} photoUrl={images[currentIndex]} />}
                    <div className="flex justify-between items-center mt-2">
                        <div className="w-1/4">
                            <div className="flex flex-row gap-2 items-center">
                                <FaChevronUp
                                    className={`w-5 h-5 cursor-pointer ${ratingSelected === 1 ? 'text-red-500' : 'text-white'}`}
                                    onClick={incrementRating}
                                />
                                <span className={`text-lg ${ratingSelected !== 0 ? 'text-red-500' : 'text-white'}`}>
                                    {rating}
                                </span>
                                <FaChevronDown
                                    className={`w-5 h-5 cursor-pointer ${ratingSelected === -1 ? 'text-red-500' : 'text-white'}`}
                                    onClick={decrementRating}
                                />
                            </div>
                        </div>
                        <div className="w-1/4 flex justify-end"> 
                            <button onClick={() => setShowModal(true)} className="p-1 rounded flex items-center justify-center hover:bg-gray-800 transition">
                                {/* <HiOutlineFlag className="w-5 h-5 text-red-500" /> */}
                                <div className="text-red-500 text-sm font-semibold underline">Not him?</div>
                            </button>
                        </div>
                    </div>
                    {/* Confession input section */}
                    <div className="flex items-center mt-4">
                        <FaRegCommentDots className="mr-2" />
                        <input
                        ref={commentInputRef}
                        type="text"
                        placeholder={replyingTo ? `Replying to "${truncateText(findCommentById(comments, replyingTo)?.content || '', 40)}"...` : "Write a comment..."}
                        className="bg-transparent border border-gray-500 text-white rounded-full py-2 px-4 w-full text-sm"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                            addComment(e.currentTarget.value); // Add the comment on Enter key press
                            }
                        }}
                        />
                        {replyingTo && (
                        <AiOutlineCloseCircle
                            className="ml-2 text-lg cursor-pointer"
                            onClick={() => setReplyingTo(null)}
                        />
                        )}
                    </div>
                    <div className="flex flex-row items-center justify-between w-14 px-2 py-1 border border-white rounded-full mt-4">
                        <MessageCircle className="w-5 h-5 text-white" />
                        <p className="text-sm text-gray font-bold">{countReplies(comments)}</p>
                    </div>
                    {/* Confession section */}
                    <div className="mt-4">
                        {renderComments(comments)}
                    </div>
                </div>
                : <></> // unhandled post type
            }
        </>
    );
};

export default Post;