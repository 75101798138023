import React from 'react';
import LoadBar from "../Components/LoadBar";
import Layout from "../Layout";
import './Pages.css';

const Loading = () => {
    return (
        <>
            <Layout>
                <div className="flex flex-col items-center min-h-screen space-y-4 p-4">
                    <h1 className="dela-gothic text-3xl mt-10 relative">LOADING
                        <span className="animate-dot1 opacity-0 ml-1">.</span>
                        <span className="animate-dot2 opacity-0 ml-1">.</span>
                        <span className="animate-dot3 opacity-0 ml-1">.</span>
                    </h1>
                    <LoadBar/>
                </div>
        </Layout>
        </>
    );
};

export default Loading;

