import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../Components/Footer';
import LightLogo from '../Components/LightLogo';
import CaptionedSlideshow from '../Components/CaptionedSlideshow';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Post from '../Components/Post';
import { PostTypes, tags, attributeEmojis } from './types';
import { BsChat } from 'react-icons/bs';
import { FiXCircle } from 'react-icons/fi';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

interface FoundPhoto {
  url: string;
  date: string;
}
interface OtherQuery {
  id: string;
  creatorId: number;
  photos: Array<FoundPhoto>;
}
interface UnlockedQueryResult {
  subject_id: string;
  my_photos: Array<FoundPhoto>;
  queries: Array<OtherQuery>;
}
interface ResultsProps {
  authToken: string;
  setUploadedImages: React.Dispatch<React.SetStateAction<string[]>>;
}

const Results:React.FC<ResultsProps> = ({ authToken, setUploadedImages }) => {
  const navigate = useNavigate();
  const [queryResult, setQueryResult] = useState<UnlockedQueryResult | null>(null);
  const [showAlternativeMatchesModal, setShowAlternativeMatchesModal] = useState(false);
  const [posts, setPosts] = useState<Array<any>>([]);
  const { id } = useParams();

  const [showDisclaimer, setShowDisclaimer] = useState(true); 
  const handleDisclaimerClose = () => {
    document.body.style.overflow = 'auto'; // Re-enable scrolling when the disclaimer is closed
    setShowDisclaimer(false);
  }

  useEffect(() => {
    if (showDisclaimer) {
      document.body.style.overflow = 'hidden';
    }
    // Re-enable scrolling when the component is unmounted
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showDisclaimer]);

  const createChat = (query: OtherQuery) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "other_user_id": query.creatorId,
      "avatar": query.photos.length > 0 ? query.photos[0].url : null
    });

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.ishemine.com/chat/get/", requestOptions)
      .then<[boolean, any]>(response => response.json().then(data => [response.ok, data]))
      .then((value: [boolean, any]) => {
          const [ok, result] = value;
          if (ok) {
            navigate(`/chat/${result.id}`);
          }
      })
      .catch(error => alert(error));

  }

  const loadResults = (failureCount = 0) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authToken);

    var requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(`https://api.ishemine.com/evals/query/results/?query_id=${id}`, requestOptions)
    .then(response => {
      if (!response.ok) {
        if (response.status === 412) {
          response.json().then(json => {
            setUploadedImages(json.my_photos.map((photo: any) => photo.url));
            navigate(`/hes-mine/${id}`, { replace: true });
          });
        } else if (failureCount > 1) {
          navigate(`/checkout/${id}`, { replace: true }); // need to redirect to payment if truly failed
        } else {
          setTimeout(() => loadResults(failureCount + 1), 1000); // Possibly stripe webhook not yet processed
        }
      } else {
        response.json().then(json => {
          fetch(`https://api.ishemine.com/social/subject_comments/?subject=${json.subject_id}`, requestOptions)
          .then(response => {
            if (!response.ok) {
              if (response.status === 404) {
                setPosts([]);
              } else {
                throw new Error("Error fetching comments");
              }
            } else {
              response.json().then(json => {
                setPosts(json);
              });
            }
          })
        });
      }
    }).catch(error => {
      console.log('Error:', error);
    })
  }
  
  useEffect(() => {
    if (id === "") navigate("/");
    loadResults();
  }, [id]);

  useEffect(() => {
    if (queryResult && queryResult.queries.length === 0) {
      setUploadedImages(queryResult.my_photos.map((photo: any) => photo.url));
      navigate(`/hes-mine/${id}`, { replace: true });
    }
  }, [queryResult]);

  const handleDefinitelyNotHimClick = () => {
    setShowAlternativeMatchesModal(true);
  };
  
  return (
      <div className="flex flex-col bg-gray-900 relative">
        {/* Disclaimer modal */}
        {showDisclaimer && (
          <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 p-8">
            <div className="bg-red-100 p-4 rounded-lg text-center border border-blue-200 w-full md:w-1/2 lg:w-1/3">
              <h2 className="font-bold text-xl mb-2 dela-gothic">Disclaimer</h2>
              <p className="mb-4 text-left">Our facial detection algorithm operates with ~95% confidence. This means, if your man is in our database, he <b>will</b> show up in this search. We have found <b>8 matches</b> in total.</p>
              <p className="mb-4 text-left">If you believe the main match shown is not the correct person, <b>please click <span className="underline text-red-700">Definitely not him?</span></b> to view other possible matches.</p>
              <p className="mb-8 text-left">If you find individual uploads or posts that seem out of place, <b>click <span className="underline text-red-700">Not Him?</span></b> to report these specific instances as <b>not your boyfriend.</b></p>
              <button className="bg-red-600 text-white px-4 py-2 rounded text-sm font-bold transition duration-300 ease-in-out transform hover:scale-105" onClick={handleDisclaimerClose}>I understand!</button>
            </div>
          </div>
        )}
        <div className="min-h-screen">
          <header className="fixed p-4 z-10 w-full bg-gray-900">
            <div className="container mx-auto flex items-center justify-between hover:cursor-pointer" onMouseUp={() => navigate("/")}>
              <LightLogo/>
            </div>
          </header>
          {/* <div className="h-px bg-gray-500"></div> */}
          <main className="flex-1 pt-20 p-4 text-center text-white">
            <h1 className="dela-gothic text-2xl">Hey girly...<br/>We found a match. 🙊</h1>
            <h3 className="text-sm">We're here for you.</h3>
            <div className="flex justify-center flex-col w-full md:w-2/3 lg:w-1/2 mx-auto">
              <div className="mt-4 mb-4">
                <div className="p-4 bg-gray-700 rounded-lg shadow-lg max-w-md mx-auto">
                  <h2 className="font-bold text-lg mb-2 italic">His first upload (Nov 3, 2023)</h2>
                  <img src={posts[posts.length-1].attachments[0]} alt="First upload" className="w-64 h-auto rounded-lg mb-4 mx-auto"/>
                  <div className="flex flex-col gap-4 text-base">
                  <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl flex items-center justify-center gap-2 mb-2 transition duration-300 ease-in-out">
                    <BsChat className="w-5 h-5" />
                    Chat with the Uploader
                  </button>
                  <button 
                    className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-xl flex items-center justify-center gap-2 transition duration-300 ease-in-out"
                    onClick={handleDefinitelyNotHimClick}
                    >
                    <FiXCircle className="w-5 h-5" />
                    Definitely not him?
                  </button>
                  </div>
                </div>
              </div>
                {queryResult ? 
                  <div className="text-left mt-4 w-full md:w-1/2 lg:w-1/3">
                      {queryResult.queries.map((query: OtherQuery, idx: number) => {
                          return (
                              <div key={idx} className="border-b-8 border-gray-700">
                                  {/* <Post
                                      postType={PostTypes.SEARCH}
                                      onChatClick={() => createChat(query)}
                                      full={true}
                                      images={query.photos.map(photo => photo.url)}
                                      initialRating={} // TODO: get rating from backend
                                      elapsedTime={dayjs(query.photos[0].date).fromNow()}
                                      caption={""} // TODO: get caption from backend
                                      authToken={authToken}
                                  /> */}
                              </div>
                          );
                      })}

                      <h2 className="font-bold">Searches on IsHeMine.com ({queryResult.queries.length})</h2>
                      {queryResult.queries.map((query: OtherQuery, idx: number) => {
                        return (
                          <div key={idx} className="border-b border-gray-300"> {/* Added Border Here */}
                            <CaptionedSlideshow onChatClick={() => createChat(query)} full={true} images={query.photos.map(photo => photo.url)} captions={query.photos.map((photo) => `${dayjs(photo.date).fromNow()}`)} authToken={authToken} />
                          </div>
                        );
                      })}
                  </div> : <div className="h-px bg-gray-500">Loading...</div> // TODO: loading animation!
                }
              </div>
            </main>
        </div>
        <Footer/>
      </div>
  );
}

export default Results;
