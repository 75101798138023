import React from 'react';
import './Components.css';

interface OutgoingChatBubbleProps {
    message: string;
}

const OutgoingChatBubble: React.FC<OutgoingChatBubbleProps> = ({ message }) => {
    const multipleLines = message.split('\n').length > 1 || message.length > 40;

    return (
        <div className="flex flex-col items-end mb-2">
            <div className={`bg-blue-500 text-white 
            pl-4 pr-4 pt-2 pb-2 text-sm mt-1 mb-1
            ${multipleLines ? 'w-5/6 block rounded-2xl rounded-br-none' : 'inline-block rounded-full rounded-br-none'}`}>
                {message}
            </div>
        </div>
    );
};

export default OutgoingChatBubble;