import React, { useState, useEffect, useRef } from 'react';
import './Pages.css';
import IncomingChatBubble from '../Components/IncomingChatBubble';
import OutgoingChatBubble from '../Components/OutgoingChatBubble';
import Logo from '../Components/Logo';
import { useNavigate, useParams } from 'react-router-dom';
import ExampleReveal from '../Assets/example-reveal.png';
import Close from '../Components/Close';
import ChatInstPopup from '../Components/ChatInstPopup';

interface ChatUser {
    id: string;
}
interface ChatMessage {
    id: number;
    content: string;
    timestamp: string;
    sender: ChatUser;
}

interface Conversation {
    id: number;
    messages: ChatMessage[];
    other_user: ChatUser;
    curr_user: ChatUser;
}

interface ChatProps {
}

const FakeChat:React.FC<ChatProps> = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isImageOpen, setIsImageOpen] = useState<boolean>(false);
    const [inputMessage, setInputMessage] = useState<string>('');
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [conversion, setConversion] = useState<Conversation | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setConversion({
            id: 0,
            messages: [],
            other_user: {
                id: "-1"
            },
            curr_user: {
                id: "0"
            }
        });
    }, []);


    const handleSend = () => {
        if (inputMessage.trim() && conversion && messages) {
            const localId = conversion.messages.length > 0 ? conversion.messages[conversion.messages.length - 1].id : 0;
            setMessages([...messages, {
                id: localId,
                content: inputMessage.trim().replace('\\', ''),
                timestamp: (new Date()).toISOString(),
                sender: {
                    id: inputMessage.includes('\\') ? "-1" : "0"
                }
            }])
            setInputMessage('');
        }
    };

    const [showModal, setShowModal] = useState<boolean>(true);

    useEffect(() => {
        // Autofocus the input field when the page loads
        if (!showModal) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [showModal]);

    return (
        <div className="h-screen bg-gray-100 flex flex-col">
            {showModal && <ChatInstPopup setShowModal={setShowModal}/>}
            <header className="p-4">
                <div className="container mx-auto flex items-center justify-between hover:cursor-pointer" onMouseUp={() => navigate("/")}>
                    <Logo />
                </div>
            </header>
            <div className="h-px bg-gray-300"></div>
            <main className="flex-grow p-5 overflow-y-auto">
                {
                    messages.map((message, index) => {
                        return message.sender.id === conversion?.other_user.id ? (
                                <IncomingChatBubble key={index} message={message.content} />
                            ) : (
                                <OutgoingChatBubble key={index} message={message.content} />
                            )
                    })
                }
            </main>
            <footer className="p-4 bg-white border-t border-gray-300">
                <div className="container mx-auto flex items-center">
                    <input
                        type="text"
                        placeholder="Spill the tea..."
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        onKeyUp={(e) => e.key === 'Enter' && handleSend()}
                        className="flex-grow p-2 rounded-l-md border border-gray-300 focus:outline-none focus:border-blue-400"
                        ref={inputRef} // Ref added here
                        autoFocus // Autofocus attribute added here
                    />
                    <button
                        onClick={handleSend}
                        className="p-2 bg-blue-500 text-white font-bold rounded-r-md hover:bg-blue-600 focus:outline-none"
                    >
                        SEND
                    </button>
                </div>
            </footer>
            {isImageOpen && (
                <div className="fixed top-0 left-0 w-full h-screen bg-black flex justify-center items-center">
                    <img src={ExampleReveal} alt="Fullscreen Reveal" className="max-w-full max-h-full" />
                    <div 
                        className="absolute top-5 right-5 p-2 rounded-full cursor-pointer focus:outline-none"
                        onClick={() => setIsImageOpen(false)}
                    >
                        <Close/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FakeChat;