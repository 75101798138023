import React, { useState, useEffect } from "react";
import LightLogo from "../Components/LightLogo";
import { useNavigate } from "react-router-dom";

const OneMoment = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState("18,000");

    useEffect(() => {
        // Fetch the count from the API
        fetch('https://api.ishemine.com/users/count/')
            .then(response => response.json())
            .then((data: { count: number }) => {
                // Set the count state with the value from the API
                setUsers(`${data.count.toLocaleString()}`);
            })
            .catch((error: Error) => {
                console.error("Error fetching count:", error);
            });
    }, []);


    return (
        <div className="flex flex-col min-h-screen bg-zinc-900">
            <header className="p-4">
                <div className="container mx-auto flex items-center justify-between hover:cursor-pointer" onMouseUp={() => navigate("/")}>
                    <LightLogo/>
                </div>
            </header>
            <main className="flex-1 mx-auto p-4 text-center overflow-hidden w-screen text-white">
                <h1 className="dela-gothic text-2xl">One moment...</h1>
                <h4 className="text-sm mt-2">Sign in to see his results <span className="italic">literally for free</span> in <span className="font-bold">just two steps.</span></h4>
                <div className="flex flex-col items-center text-left p-4 w-full md:w-1/2 mx-auto">
                    <div className="flex items-center mb-3 w-full">
                        <span className="rounded-full bg-blue-500 text-white mr-2 w-8 h-8 p-5 border border-white flex items-center justify-center text-lg dela-gothic">1</span>
                        <div><span className="font-bold">Enter your phone number</span> so we can keep you updated.</div>
                    </div>
                    <div className="flex items-center mb-6 w-full">
                        <span className="rounded-full bg-blue-500 text-white mr-2 w-8 h-8 p-5 border border-white flex items-center justify-center text-lg dela-gothic">2</span>
                        <div><span className="font-bold">Verify your womanhood</span> through face scan. We do this to protect our community.</div>
                    </div>
                    <p className="w-full">Join the <span className="font-bold text-green-500">{users}</span> other women who have found out.</p>
                </div>
                <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-6 rounded-full text-lg transition duration-300 ease-in-out transform hover:scale-105" onClick={() => navigate("/login")}>Let's find out</button>
            </main>
        </div>
    );
};

export default OneMoment;