import React, { FormEventHandler, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { StripeLinkAuthenticationElementChangeEvent, StripePaymentElementOptions } from "@stripe/stripe-js";
import { SearchResult } from "../Pages/FindYourMan";
import "./Stripe.css";

interface CheckoutFormProps {
    clientSecret: string;
    authToken: string;
}
export default function CheckoutForm(props: CheckoutFormProps) {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const { id } = useParams();

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        const clientSecret = props.clientSecret;
        if (!stripe) {
          return;
        }

        if (!clientSecret) {
          return;
        }

        stripe.retrievePaymentIntent(clientSecret).then<any>(({ paymentIntent }) => {
            if (!paymentIntent) return;
            switch (paymentIntent.status) {
                case "succeeded":
                    navigate(`/results/${id}`);
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    // setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe, id]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.origin + `/results/${id}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message ? error.message : "failed so bad i can't even get an error message");
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "auto"
  }

  return (
    <form className="w-full mx-auto shadow-lg bg-white rounded-xl p-10" onSubmit={handleSubmit}>
        {props.clientSecret && <LinkAuthenticationElement
            className="mb-6"
            onChange={(e: StripeLinkAuthenticationElementChangeEvent) => setEmail(e.value.email)}
        />}
        {props.clientSecret && <PaymentElement className="mb-6" options={paymentElementOptions} />}
        <button 
            className={`w-full py-3 px-4 rounded font-semibold transition duration-300 ${
                isLoading || !stripe || !elements ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 text-white'
            }`} 
            disabled={isLoading || !stripe || !elements}>
            {isLoading ? <span className="loader"></span> : "Pay $4.99"}
        </button>
        {message && <div className="mt-3 text-center text-gray-700">{message}</div>}
    </form>
  );
}