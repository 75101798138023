import React from 'react';

type WordleCardFrontProps = {
    letter: string;
};

const WordleCardFront:React.FC<WordleCardFrontProps> = ({ letter }) => {
    return (
        <div className="w-8 md:w-10 h-8 md:h-10 bg-zinc-800 border border-neutral-500 border-2"></div>
    );
};

export default WordleCardFront;