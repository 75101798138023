import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useLocalStorageState from 'use-local-storage-state'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/next"
import Landing from './Pages/Landing';
import Error from './Pages/Error';
import VerifyPhone from './Pages/VerifyPhone';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import CookiePolicy from './Pages/CookiePolicy';
import Contact from './Pages/Contact';
import UploadHim from './Pages/UploadHim';
import IsThisHim from './Pages/IsThisHim';
import Congrats from './Pages/Congrats';
import HesMine from './Pages/HesMine';
import FindYourMan, { ImageData } from './Pages/FindYourMan';
import Chat from './Pages/Chat';
import StatusUpdate from './Pages/StatusUpdate';
import RevealInfo from './Pages/RevealInfo';
import Loading from './Pages/Loading';
import Match from './Pages/Match';
import Results from './Pages/Results';
import CheckoutForm from './Pages/Checkout';
import AuthLanding, { PastQuery } from './Pages/AuthLanding';
import VerifyWoman from './Pages/VerifyWoman';
import OptOut from './Pages/OptOut';
import CommGuide from './Pages/CommGuide';
import DemoLanding from './Pages/DemoLanding';
import LoyaltyInst from './Pages/LoyaltyInst';
import CheatingInst from './Pages/CheatingInst';
import FakeUploadHim from './Pages/FakeUploadHim';
import FakeStatusUpdate from './Pages/FakeStatusUpdate';
import FakeCongrats from './Pages/FakeCongrats';
import FakeHesMine from './Pages/FakeHesMine';
import FakeMatch from './Pages/FakeMatch';
import FakeResults from './Pages/FakeResults';
import FakeChat from './Pages/FakeChat';
import NewLanding from './Pages/NewLanding';
import OneMoment from './Pages/OneMoment';
import Login from './Pages/Login';
import AuthlessFindYourMan from './Pages/AuthlessFindYourMan';
import TestVerifyWoman from './Pages/TestVerifyWoman';
import SubjectDetailPage from './Pages/Subject';

const App = () => {

    const searchParams = new URLSearchParams(window.location.search);
    
    const [phoneNumberMem, setPhoneNumberMem] = useLocalStorageState<string>("phoneNumber", {
        defaultValue: "",
    });
    const [authToken, setAuthToken] = useLocalStorageState<string>("authToken", {
        defaultValue: "",
    });
    const [photoIds, setPhotoIds] = useLocalStorageState<Array<string>>("photoIds", {
        defaultValue: [],
    });
    const [imageData, setImageData] = useLocalStorageState<Array<ImageData>>("imageData", {
        defaultValue: [],
    });
    const [landingImageData, setLandingImageData] = useLocalStorageState<Array<ImageData>>("landingImageData", {
        defaultValue: [],
    });
    const [queryResult, setQueryResult] = useLocalStorageState<any>("queryResult", {
        defaultValue: null,
    });
    const [uploadedImages, setUploadedImages] = useLocalStorageState<Array<string>>("uploadedImages", {
        defaultValue: [],
    });
    const [trackerToken, setTrackerToken] = useLocalStorageState<string | null>("trackerToken", {
        defaultValue: null});

    const [pastQueries, setPastQueries] = useLocalStorageState<PastQuery[]>("pastQueries", {defaultValue: []});

    const [otherGirlPhotos, setOtherGirlPhotos] = useLocalStorageState<string[]>("otherGirlPhotos", {defaultValue: []});
    const [demoPhotos, setDemoPhotos] = useLocalStorageState<string[]>("demoPhotos", {defaultValue: ["", "", "", "", "", ""]});
    const [demoState, setDemoState] = useLocalStorageState<string>("demoState", {defaultValue: ""});
    const [facesToSearch, setFacesToSearch] = useLocalStorageState<string[]>("facesToSearch", {defaultValue: []});
    const [loginMethod, setLoginMethod] = useLocalStorageState<string>("loginMethod", {defaultValue: ""});

    useEffect(() => {
        if (imageData) {
            setUploadedImages([...imageData.map((img: ImageData) => img.photo).filter((item, index, arr) => arr.indexOf(item) === index)]);
        }
    }, [imageData]);

    useEffect(() => {
        if (searchParams.get("trkr")) {
            setTrackerToken(searchParams.get("trkr"));
        }
    }, [searchParams]);

    useEffect(() => {
        if (trackerToken) {
            fetch(`https://api.ishemine.com/users/trckr/?token=${trackerToken}`, {
                method: "GET",
                headers: {
                    Authorization: authToken,
                }
            })
        }
    }, [authToken])

    return (
        <HelmetProvider>
            <Analytics />
            <BrowserRouter>
                <Routes>
                    {
                        (authToken === "")
                        ? (
                        <Route index element={
                            <>
                                <Helmet>
                                    <title>Is He Mine? - Discover the Truth About Your Relationship</title>
                                    <meta name="description" content="Is He Mine? offers insightful relationship analysis to help you understand your partner better. Discover more about your relationship today." />
                                </Helmet>
                                <NewLanding phoneNumberMem={phoneNumberMem} setPhoneNumberMem={setPhoneNumberMem} setImageData={setLandingImageData} setLoginMethod={setLoginMethod} />
                            </>
                        }/>
                        )
                        : (
                            <>
                                <Route index element={<AuthLanding authToken={authToken} setUploadedImages={setUploadedImages} previousUploads={pastQueries} setPreviousUploads={setPastQueries}/>} />
                                <Route path="/liveness" element={
                                    <>
                                        <Helmet>
                                            <title>Is He Mine? - Woman Verification</title>
                                            <meta name="description" content="Verify your identity on Is He Mine? as a part of our secure and confidential process." />
                                        </Helmet>
                                        <VerifyWoman authToken={authToken} loginMethod={loginMethod} faceIds={facesToSearch} imageData={imageData} setQueryResult={setQueryResult} />
                                    </>
                                } />
                                <Route path="/upload" element={<UploadHim authToken={authToken} setPhotoIds={setPhotoIds} photoIds={photoIds} setImageData={setImageData}/>}/>
                                <Route path="/find-your-man" element={<FindYourMan imageData={imageData} authToken={authToken} setQueryResult={setQueryResult}/>}/>
                                <Route path="/is-this-him" element={<IsThisHim/>}/>
                                <Route path="/congrats" element={<Congrats uploadedImages={uploadedImages} authToken={authToken} queryResult={queryResult}/>}/>
                                <Route path="/hes-mine/:id" element={
                                    <>
                                        <Helmet>
                                            <title>Is He Mine? - He's Definitely Mine</title>
                                            <meta name="description" content="Celebrate the confirmation that he's truly yours with Is He Mine? Embrace the joy and security of your relationship." />
                                        </Helmet>
                                        <HesMine authToken={authToken} queryResult={queryResult} setQueryResult={setQueryResult} uploadedImages={uploadedImages} setUploadedImages={setUploadedImages}/>
                                    </>
                                }/>
                                <Route path="/chat/:id" element={<Chat authToken={authToken}/>}/>
                                <Route path="/status-update" element={<StatusUpdate queryResult={queryResult}/>}/>
                                <Route path="/reveal-info" element={<RevealInfo/>}/>
                                <Route path="/loading" element={<Loading/>}/>
                                <Route path="/match" element={<Match queryResult={queryResult}/>}/>
                                <Route path="/checkout/:id" element={<CheckoutForm authToken={authToken} queryResult={queryResult}/>}/>
                                <Route path="/results/:id" element={
                                    <>
                                        <Helmet>
                                            <title>Is He Mine? - Your Results Are In</title>
                                            <meta name="description" content="Discover the results of your inquiry on Is He Mine? Understand your relationship better with our detailed analysis." />
                                        </Helmet>
                                        <Results authToken={authToken} setUploadedImages={setUploadedImages}/>
                                    </>
                                }/>
                                <Route path="/results/:query_id/subject/:user_id" element={
                                    <>
                                        <Helmet>
                                            <title>Is He Mine? - Your Results Are In</title>
                                            <meta name="description" content="Discover the results of your inquiry on Is He Mine? Understand your relationship better with our detailed analysis." />
                                        </Helmet>
                                        <SubjectDetailPage authToken={authToken} queryPhotos={uploadedImages} ranked_matches={[]}/>
                                    </>
                                }/>
                            </>
                        )
                    }
                    {phoneNumberMem ? <Route path="/verify" element={<VerifyPhone phoneNumber={phoneNumberMem} setAuthToken={setAuthToken} />}/> : null}
                    <Route path="/auth-landing" element={<AuthLanding authToken={authToken} setUploadedImages={setUploadedImages} previousUploads={pastQueries} setPreviousUploads={setPastQueries}/>} />
                    <Route path="/terms" element={
                        <>
                            <Helmet>
                                <title>Is He Mine? - Terms and Conditions</title>
                                <meta name="description" content="Read the terms and conditions for using Is He Mine? Understand our policies to enhance your experience." />
                            </Helmet>
                            <Terms/>
                        </>
                    }/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/cookie-policy" element={<CookiePolicy/>}/>
                    <Route path="/contact" element={
                        <>
                            <Helmet>
                                <title>Contact Us - Is He Mine?</title>
                                <meta name="description" content="Get in touch with the Is He Mine? team. We're here to help with your inquiries and support needs." />
                            </Helmet>
                            <Contact/>
                        </>
                    }/>
                    <Route path="/opt-out" element={<OptOut/>}/>
                    <Route path="/comm-guide" element={
                        <>
                            <Helmet>
                                <title>Is He Mine? - Community Guidelines</title>
                                <meta name="description" content="Explore the Community Guidelines of Is He Mine? Understand our standards and practices to ensure a respectful and supportive community." />
                            </Helmet>
                            <CommGuide/>
                        </>
                    }/>
                    <Route path="/ugc" element={<DemoLanding setDemoState={setDemoState} setOtherGirlPhotos={setOtherGirlPhotos} setDemoPhotos={setDemoPhotos} />}/>
                    <Route path="/ugc/loyalty-inst" element={
                        <>
                            <Helmet>
                                <title>Is He Mine? - He's Loyal: Demo Instructions</title>
                                <meta name="description" content="Discover how to demonstrate your partner's loyalty using Is He Mine? in our demo. Ideal for UGC creators exploring loyalty themes." />
                            </Helmet>
                            <LoyaltyInst/>
                        </>
                    }/>
                    <Route path="/ugc/cheating-inst" element={<CheatingInst otherGirlPhotos={otherGirlPhotos} setOtherGirlPhotos={setOtherGirlPhotos} />}/>
                    <Route path="/ugc/upload-him" element={<FakeUploadHim demoPhotos={demoPhotos} setDemoPhotos={setDemoPhotos} />}/>
                    <Route path="/ugc/status-update" element={<FakeStatusUpdate demoState={demoState} />}/>
                    <Route path="/ugc/congrats" element={<FakeCongrats uploadedImages={demoPhotos}/>}/>
                    <Route path="/ugc/hes-mine" element={
                        <>
                            <Helmet>
                                <title>Is He Mine? - He's Definitely Mine</title>
                                <meta name="description" content="Celebrate the confirmation that he's truly yours with Is He Mine? Embrace the joy and security of your relationship." />
                            </Helmet>
                            <FakeHesMine uploadedImages={demoPhotos}/>
                        </>
                    }/>
                    <Route path="/ugc/match" element={<FakeMatch otherGirlPhotos={otherGirlPhotos}/>}/>
                    <Route path="/ugc/results" element={
                        <>
                            <Helmet>
                                <title>Is He Mine? - Your Results Are In</title>
                                <meta name="description" content="Discover the results of your inquiry on Is He Mine? Understand your relationship better with our detailed analysis." />
                            </Helmet>
                            <FakeResults otherGirlPhotos={otherGirlPhotos} demoPhotos={demoPhotos}/>
                        </>
                    }/>
                    <Route path="/ugc/chat" element={<FakeChat />}/>
                    <Route path="/ugc/test-verify" element={<TestVerifyWoman />}/>
                    <Route path="/new" element={
                        <>
                            <Helmet>
                                <title>Is He Mine? - Discover the Truth About Your Relationship</title>
                                <meta name="description" content="Is He Mine? offers insightful relationship analysis to help you understand your partner better. Discover more about your relationship today." />
                            </Helmet>
                            <NewLanding phoneNumberMem={phoneNumberMem} setPhoneNumberMem={setPhoneNumberMem} setImageData={setLandingImageData} setLoginMethod={setLoginMethod} />
                        </>
                    }/>
                    <Route path="/find-your-man-no-auth" element={<AuthlessFindYourMan imageData={landingImageData} authToken="" setFaceIds={setFacesToSearch}/>}/>
                    <Route path="/one-moment" element={<OneMoment />}/>
                    <Route path="/login" element={
                        <>
                            <Helmet>
                                <title>Login - Is He Mine?</title>
                                <meta name="description" content="Access your Is He Mine? account. Log in to view your relationship insights and manage your profile." />
                            </Helmet>
                            <Login phoneNumberMem={phoneNumberMem} setPhoneNumberMem={setPhoneNumberMem} />
                        </>
                    }/>
                    <Route path="*" element={<Error/>}/>
                </Routes>
            </BrowserRouter>
        </HelmetProvider>
    );
};

export default App;