import React, { useState, useEffect } from 'react';
import ReactCardFlip from 'react-card-flip';
import WordleCardFront from './WordleCardFront';
import WordleCardBack from './WordleCardBack';

const letters = ['C', 'O', 'N', 'G', 'R', 'A', 'T', 'S', '!'];

const WordleCongrats: React.FC = () => {
    const [showLetters, setShowLetters] = useState(Array(letters.length).fill(false));

    useEffect(() => {
        const animationTimeouts = letters.map((_, index) =>
            setTimeout(() => {
                setShowLetters((prevShowLetters) =>
                    prevShowLetters.map((_, i) => i <= index)
                );
            }, 200 * index)
        );

        return () => {
            animationTimeouts.forEach((timeout) => clearTimeout(timeout));
        };
    }, []);


    return (
        <div className="helvetica-neue text-white flex flex-row space-x-1 text-xl md:text-2xl">
            {letters.map((letter, index) =>
                <ReactCardFlip isFlipped={showLetters[index]} flipDirection="vertical">
                    <WordleCardFront letter={letter}/>
                    <WordleCardBack letter={letter}/>
                </ReactCardFlip>
            )}
        </div>
    );
};

export default WordleCongrats;