import React from "react";
import { useNavigate } from "react-router-dom";
import LockHimDown from "../Components/LockHimDown";
import WordleCongrats from "../Components/WordleCongrats";
import Layout from "../Layout";
import Confetti from 'react-confetti';
import JoinFB from "../Components/JoinFB";

interface CongratsProps {
    uploadedImages: Array<string>;
    authToken: string;
    queryResult: any;
}
const Congrats:React.FC<CongratsProps> = ({ uploadedImages, authToken, queryResult }) => {

    const navigate = useNavigate();

    const upload = () => {
        if (!queryResult || !queryResult.query_id) navigate("/");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", authToken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "query_id": queryResult.query_id
        });
        var requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: raw
        };
        fetch("https://api.ishemine.com/evals/query/opt-in/", requestOptions)
    }

    return (
        <Layout>
            <div className="flex justify-center">
                <WordleCongrats/>
            </div>
            <div className="font-bold mt-5 text-lg">
                He's yours.
            </div>
            <div className="flex justify-center mt-6">
                <LockHimDown uploadedImages={uploadedImages} callback={upload} queryId={queryResult.query_id}/>
            </div>
            <JoinFB/>
            <Confetti recycle={false} numberOfPieces={600} />
        </Layout>
    );
};

export default Congrats;