// HorizontalCarousel.tsx
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface PhotoData {
    url: string;
    date: string;
}

interface UploadData {
    query_id: number;
    photos: PhotoData[];
    date: string;
}

interface HorizontalCarouselProps {
    data: UploadData[];
    setUploadedImages: React.Dispatch<React.SetStateAction<string[]>>;
}

const HorizontalCarousel: React.FC<HorizontalCarouselProps> = ({ data, setUploadedImages }) => {
    const navigate = useNavigate();
    const carouselRef = useRef<HTMLDivElement | null>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [startPosition, setStartPosition] = useState<number>(0);
    const [scrollStart, setScrollStart] = useState<number>(0);

    const [showLeftIndicator, setShowLeftIndicator] = useState<boolean>(false);
    const [showRightIndicator, setShowRightIndicator] = useState<boolean>(true);

    useEffect(() => {
        const handleScroll = () => {
            if (carouselRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
                setShowLeftIndicator(scrollLeft > 0);
                setShowRightIndicator(scrollLeft !== (scrollWidth - clientWidth));
            }
        };

        carouselRef.current?.addEventListener('scroll', handleScroll);

        return () => {
            carouselRef.current?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="relative">
            {showLeftIndicator && <div className="absolute top-0 bottom-0 left-0 w-8 bg-gradient-to-r from-red-200 to-transparent z-10"></div>}
            {showRightIndicator && <div className="absolute top-0 bottom-0 right-0 w-8 bg-gradient-to-l from-red-200 to-transparent z-10"></div>}
            
            <div 
                className="horizontal-carousel overflow-x-scroll whitespace-nowrap py-2 px-2 relative z-0" 
                ref={carouselRef}
                onMouseDown={(e) => {
                    setIsDragging(true);
                    setStartPosition(e.clientX);
                    if (carouselRef.current) {
                        setScrollStart(carouselRef.current.scrollLeft);
                    }
                }}
                onMouseMove={(e) => {
                    if (isDragging && carouselRef.current) {
                        carouselRef.current.scrollLeft = scrollStart - (e.clientX - startPosition);
                    }
                }}
                onMouseUp={() => setIsDragging(false)}
                onMouseLeave={() => setIsDragging(false)}
            >
                {data.map(upload => (
                    <div onClick={() => {
                        setUploadedImages(upload.photos.map(photo => photo.url))
                        navigate(`/results/${upload.query_id}`);
                    }} key={upload.query_id} className="inline-block relative group cursor-pointer transform transition-transform duration-200 hover:scale-105 m-2 w-48 h-64">
                        {upload.photos.reverse().map((image, idx) => (
                            <img 
                                key={idx}
                                src={image.url}
                                alt={`Uploaded on: ${image.date}`}
                                className={`w-full h-full rounded-md border border-gray-200 shadow-md absolute top-${idx * 0.5}rem left-${idx * 0.5}rem`}
                                draggable="false"
                            />
                        ))}
                        <div className="absolute top-0 left-0 mt-2 ml-2 text-white text-xs bg-black bg-opacity-70 p-1 rounded-md shadow-lg">Uploaded on: {new Date(upload.date).toLocaleDateString('us')}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HorizontalCarousel;
