import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Components/Components.css';
import Icon from '../Components/Icon';
import PhoneNumberInput from '../Components/PhoneNumberInput';
import ReCAPTCHA from "react-google-recaptcha";
import { parsePhoneNumber, AsYouType } from 'libphonenumber-js';
import BlueSubmitBtn from '../Components/BlueSubmitBtn';

type GetStartedProps = {
  phoneNumberMem: string;
  setPhoneNumberMem: (value: string) => void;
};

const Login: React.FC<GetStartedProps> = ({ phoneNumberMem, setPhoneNumberMem }) => {
  const navigate = useNavigate();

  const [numberEntered, setNumberEntered] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const [recaptchaToken, setRecaptchaToken] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // window?.visualViewport?.addEventListener('resize', _ => window.scrollTo(0, 0));

  function toggleLoading() {
    setIsLoading((prevLoading) => !prevLoading);
  }

  function handleNumberChange(value: string) {
    const asYouType = new AsYouType('US');
    const formattedNumber = asYouType.input(value);
    const digitsOnly = formattedNumber.replace(/\D/g, ''); // Remove non-digit characters
    if (digitsOnly.length <= 10) {
      const isRightLength = digitsOnly.length === 10;
      setPhoneNumber(isRightLength ? formattedNumber : digitsOnly);
      setNumberEntered(isRightLength);
      setPhoneNumberError(null); // Reset any previous errors
    }
  }

  function handleSignUp() {
    if (recaptchaRef && recaptchaRef.current) {
      recaptchaRef.current.execute();
    } else {
      console.log("Recaptcha ref not found");
    }
  }

  useEffect(() => {
    if (recaptchaToken && phoneNumber) {
      console.log(recaptchaToken);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const parsedPhoneNumber = parsePhoneNumber(phoneNumber, "US");
      
      if (!parsedPhoneNumber.isValid()) {
        setPhoneNumberError('Please enter a valid phone number.');
        return;
      }

      const urlencoded = new URLSearchParams();
      urlencoded.append("mobile", parsedPhoneNumber ? parsedPhoneNumber.number : "+1");
      urlencoded.append("captcha", recaptchaToken);

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      toggleLoading();

      fetch("https://api.ishemine.com/auth/mobile/", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}. Come back in a few minutes!`);
        }
        return response.text();
      })
      .then(result => {
        console.log(result);
        toggleLoading();
        setSubmitted(true);
        setPhoneNumberMem(phoneNumber);
      })
      .catch(error => {
        console.log('error', error);
        setPhoneNumberError(`An error occurred: ${error.message}`);
        toggleLoading();
        setPhoneNumber("");
        setNumberEntered(false);
      });
    }
  }, [recaptchaToken, phoneNumber]);

  useEffect(() => {
    if (phoneNumberMem && submitted) {
      console.log("Navigating to verify...");
      setSubmitted(false);
      navigate("/verify");
    }
  }, [phoneNumberMem, submitted, navigate]);

  return (
    <div className="bg-gray-900 w-full h-full min-h-screen p-6 text-center align-middle shadow-xl text-white">
        <div className="flex flex-col items-center mt-20 md:mt-12 mb-8 md:mb-4">
            <Icon />
        </div>
        <h3 className="text-4xl font-medium leading-6 text-white dela-gothic justify-center">
            Get Started
        </h3>
        <div className="w-full md:w-1/2 lg:w-1/3 mx-auto">
            <div className="mt-8 mb-3 text-left">
                <h4 className="font-bold text-xl">My number is</h4>
            </div>
            <PhoneNumberInput onChange={handleNumberChange} errorMessage={phoneNumberError} phoneNumber={phoneNumber}/>
            <p className="text-center my-4 text-sm">
                By clicking Sign Up, you agree to our <a href="/terms" className="text-blue-600 font-bold underline hover:no-underline">Terms</a> and agree to receive text messages about important updates (e.g., possible future matches). Learn about how we process your data in our <a href="/privacy" className="text-blue-600 font-bold underline hover:no-underline">Privacy Policy</a> and <a href="/cookie-policy" className="text-blue-600 font-bold underline hover:no-underline">Cookie Policy</a>.
            </p>
            <BlueSubmitBtn isLoading={isLoading} numberEntered={numberEntered} handleClick={handleSignUp} text={"Sign Up"} />
        </div>
        <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LcdznYnAAAAAFicIrLfu9EJuODfhkIScxm1_nCS"
            onChange={(token: string | null) => token ? setRecaptchaToken(token) : null}
        />
    </div>
  );
};

export default Login;