import React, { useState } from 'react';
import Slideshow from './Slideshow';
import { IoChatboxEllipsesOutline } from 'react-icons/io5';
import { HiOutlineFlag } from 'react-icons/hi2';
import ReportModal from './ReportModal';

interface SlideshowProps {
  authToken: string;
  images: string[];
  full: boolean;
  captions: string[];
  onChatClick?: () => void;
}

const CaptionedSlideshow: React.FC<SlideshowProps> = ({ images, full, captions, authToken, onChatClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="mt-4 mb-4">
      <Slideshow images={images} full={full} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
      {showModal && <ReportModal setShowModal={setShowModal} authToken={authToken} photoUrl={images[currentIndex]} />}
      <div className="flex justify-between items-center mt-2">
        <div className="w-1/4"> 
            <p className="text-xs text-gray truncate">{captions[currentIndex]}</p>
        </div>
        <div className="flex-grow flex items-center justify-center"> 
            <button onClick={onChatClick ? onChatClick : () => console.log('clicked')} className="p-2 bg-purple-600 text-white text-sm rounded flex items-center justify-center hover:bg-purple-700 transition shadow-md w-4/5">
              <IoChatboxEllipsesOutline className="w-6 h-6 mr-1" />
              <span>Chat With Her</span>
            </button>
        </div>
        <div className="w-1/4 flex justify-end"> 
            <button onClick={() => setShowModal(true)} className="p-1 rounded flex items-center justify-center hover:bg-gray-800 transition">
              {/* <HiOutlineFlag className="w-5 h-5 text-red-500" /> */}
              <div className="text-red-500 underline">Not him?</div>
            </button>
        </div>
      </div>
    </div>
  );
}

export default CaptionedSlideshow;
