import { useEffect, useState } from "react";
import Layout from "../Layout";
import { useNavigate } from "react-router-dom";

interface FakeMatchProps {
    otherGirlPhotos: Array<string>;
}
const FakeMatch:React.FC<FakeMatchProps> = ({otherGirlPhotos}) => {
    const navigate = useNavigate();
    const [users, setUsers] = useState("");

    useEffect(() => {
        // Fetch the count from the API
        fetch('https://api.ishemine.com/users/count/')
            .then(response => response.json())
            .then((data: { count: number }) => {
                // Set the count state with the value from the API
                setUsers(`${data.count.toLocaleString()}`);
            })
            .catch((error: Error) => {
                console.error("Error fetching count:", error);
            });
    }, []);

    return (
        <Layout>
            <div className="mx-auto container flex flex-col justify-center items-center space-y-8 px-4 sm:px-6 lg:px-8">
                <div className="dela-gothic text-2xl text-center w-full md:w-3/4 lg:w-1/2">
                    Hey girly...<br/>We found a match. 🙊
                </div>
                <div className="text-center w-full md:w-3/4 lg:w-1/2">
                    <b>Results on IsHeMine.com:</b> {otherGirlPhotos.length}<br/>
                </div>
                <div className="w-full md:w-3/4 lg:w-1/2 text-white bg-red-400 rounded-xl p-4 space-y-2">
                    <p className="text-base">
                        Pay <b>$4.99</b> for immediate access to the <b>uploaded photos, other people's comments, and anonymous chat with the person who uploaded</b>.<span className="text-blue-600">*</span> <strong>Disclaimer:</strong> our AI operates with 95% accuracy.
                    </p>
                    <p className="text-sm">
                        <span className="text-blue-600">*</span> Contact is only possible with people who uploaded photos through IsHeMine.com and we are not able to issue refunds.
                    </p>
                </div>
                <button onClick={() => {navigate("/ugc/results")}} className="w-3/4 md:w-auto transition-transform transform scale-100 hover:scale-105 bg-blue-900 text-white font-bold py-3 px-5 md:px-20 rounded-lg shadow-xl hover:shadow-2xl">
                    PAY NOW
                </button>

                <div className="text-blue-700 text-xl dela-gothic">Join the {users} women<br/>who have found out...</div>
                
                {/* OR bar */}
                <div className="flex items-center relative w-full md:w-3/4 lg:w-1/2">
                    <hr className="flex-grow border-t border-gray-500" />
                    <span className="px-4 text-gray-600 font-bold">OR</span>
                    <hr className="flex-grow border-t border-gray-500" />
                </div>

                <div className="w-full md:w-3/4 lg:w-1/2 space-y-6">
                    <p className="text-left text-sm">
                        Fill out an application to <a href="https://airtable.com/appc8CLuIK7gTr4dB/shr9fJjhNq2lgbbXi"><b>get verified</b></a> in 1-3 business days. Verified users have a badge on their posts (uploads) and are able to access search results free-of-charge.
                    </p>
                    <a href="https://airtable.com/appc8CLuIK7gTr4dB/shr9fJjhNq2lgbbXi">
                        <button className="w-3/4 md:w-auto transition-transform transform scale-100 hover:scale-105 font-bold text-gray-700 border border-2 border-gray-700 py-2 px-5 md:px-8 rounded-xl mt-4 m-auto">
                            Apply for verification
                        </button>
                    </a>
                </div>
            </div>
        </Layout>
    );
};

export default FakeMatch;