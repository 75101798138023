import dayjs from "dayjs";
import { Subject, makeTimeline, Query, Confession, TinderProfile } from "../Pages/Subject";

interface TLDRTimeLineProps {
    subject: Subject;
    scrollToPost: (index: number) => void
}

const TLDRTimeLine:React.FC<TLDRTimeLineProps> = ({subject, scrollToPost}) => {
    
    return (
      <div className="mt-8 mb-4 px-4">
        <h3 className="text-lg font-bold mb-2 text-pink-500">The TL;DR 💫</h3>
        <div className="border-l-2 border-pink-300 pl-4">
          {makeTimeline(subject).map((event, index) => {
            const date = dayjs().subtract(index * 30, 'day').format('MM/DD/YY');
            let timelineText;
  
            // TODO: this should be based on the post type for Results, not randomized based on index
            switch (event.type) {
                case "query":
                  timelineText = `Uploaded to IsHeMine.com on ${date}`;
                  break;
                case "confession":
                    timelineText = `Uploaded to IsHeMine.com on ${date}`;
                    break;
                case "tinder_profile":
                  timelineText = `Spotted on Tinder on ${date}`;
                  break;
              }
  
            return (
              <div key={index} className="mb-2 flex items-center">
                <div className="rounded-full bg-pink-500 h-4 w-4 flex items-center justify-center -ml-2">
                  <div className="rounded-full bg-white h-2 w-2"></div>
                </div>
                <button 
                  onClick={() => scrollToPost(index)}
                  className="ml-2 hover:text-pink-600 transition duration-300 ease-in-out"
                >
                  {timelineText}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }; 

  export default TLDRTimeLine;