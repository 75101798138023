import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import './Pages.css';
import FakePhotoUploadGrid from '../Components/FakePhotoUploadGrid';
import BlueSubmitBtn from '../Components/BlueSubmitBtn';
import { useNavigate } from 'react-router-dom';

type FakeUploadHimProps = {
    demoPhotos: string[];
    setDemoPhotos: React.Dispatch<React.SetStateAction<string[]>>;
}

const FakeUploadHim: React.FC<FakeUploadHimProps> = ({ demoPhotos, setDemoPhotos }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [photoUploaded, setPhotoUploaded] = useState(false);
    const [numPhotos, setNumPhotos] = useState(0); // What is the point of this when we can and should do photoIds.length ?
    const [submitted, setSubmitted] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [showAltContinue, setShowAltContinue] = useState(false);

    useEffect(() => {
        setDemoPhotos([]);
    }, []);

    useEffect(() => {
        setShowAltContinue(false);
        if (numPhotos > 0) setPhotoUploaded(true);
        else {
            setPhotoUploaded(false);
            if (submitted) setSubmitted(false);
        }
    }, [numPhotos]);

    const handleContinue = () => {
        navigate("/ugc/status-update");
    };

    useEffect(() => {
        console.log(demoPhotos);
    }, [demoPhotos]);

    return (
        <Layout>
            <div className="center-container">
                <h1 className="dela-gothic text-2xl md:text-4xl mt-4 mb-4 md:m-4">Upload Pictures of Him</h1>
                <div className="flex flex-col items-center">
                    <p className="font-bold text-zinc-700 w-7/8 md:w-4/5 text-sm md:text-base text-center">(Max 6. Min. 1) The more photos you upload, the more likely a match will be found (if there is one).</p>
                </div>
                <FakePhotoUploadGrid numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotos={setDemoPhotos} photos={demoPhotos} />
                <div className="text-left mt-5 text-sm font-bold">
                    <p>
                        We keep all photos confidential to everyone except others who upload the same man. 
                    </p>
                    <p className="mt-2">
                        Pics that contain both of you tend to do best...
                    </p>
                </div>
                <div className="absolute bottom-2 md:bottom-5 w-full md:w-3/4 lg:w-1/2 p-4">
                    { errorMsg && <p className="text-red-500 text-sm mb-4">{errorMsg}</p>
                    }
                    {showAltContinue && 
                        <BlueSubmitBtn isLoading={isLoading} numberEntered={true} handleClick={handleContinue} text={"Try Again"}/>
                    }
                    {showAltContinue ? 
                        <BlueSubmitBtn isLoading={isLoading} numberEntered={true} handleClick={() => navigate("/find-your-man")} text={"Continue anyway"}/> :
                        <BlueSubmitBtn isLoading={isLoading} numberEntered={demoPhotos.length > 0} handleClick={handleContinue} text={"Continue"}/>}
                </div>
            </div>
        </Layout>
    );
};

export default FakeUploadHim;