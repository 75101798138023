import React from 'react';
import { 
    MdOutlineFileUpload, 
    MdSearch, 
    MdOutlineAddTask, 
    MdOutlineNotificationsActive 
} from 'react-icons/md';
import { FaShieldAlt, FaPeopleArrows } from 'react-icons/fa';

type HowOurServiceWorksProps = {
    openGSModal: () => void;
}

const NewHowOurServiceWorks: React.FC<HowOurServiceWorksProps> = ({ openGSModal }) => {
    return (
        <div className="bg-black bg-opacity-30 p-6 sm:p-8 shadow-lg text-white">
            <h2 className="text-center text-lg sm:text-xl font-bold mb-8 dela-gothic">How Our Service Works</h2>
            
            <div className="grid grid-cols-1 gap-6 text-left mx-auto md:w-1/2 text-sm md:text-base">
                <div className="bg-gray-800 p-4 rounded-lg flex items-start space-x-4">
                    <MdOutlineFileUpload className="text-pink-500 w-10 h-10" />
                    <p className="flex-1">Upload photos of your significant other.</p>
                </div>
                <div className="bg-gray-800 p-4 rounded-lg flex items-start space-x-4">
                    <MdSearch className="text-pink-500 w-10 h-10" />
                    <p className="flex-1">Using groundbreaking facial-recognition technology, we match your uploaded images against images pooled from other users and external sources like <span className="font-semibold text-red-400"> Tinder</span>.</p>
                </div>
                <div className="bg-gray-800 p-4 rounded-lg flex items-start space-x-4">
                    <FaPeopleArrows className="text-pink-500 w-10 h-10" />
                    <p className="flex-1">Within moments, find out whether your significant other is with someone else.</p>
                </div>
                <div className="bg-gray-800 p-4 rounded-lg flex items-start space-x-4">
                    <MdOutlineAddTask className="text-pink-500 w-10 h-10" />
                    <p className="flex-1">Opt to keep your uploaded photos public for other women to cross-check in the future, even if there’s no match currently.</p>
                </div>
                <div className="bg-gray-800 p-4 rounded-lg flex items-start space-x-4">
                    <MdOutlineNotificationsActive className="text-pink-500 w-10 h-10" />
                    <p className="flex-1">Don’t miss a beat - get notified if your uploaded photo finds a match in the future and confront them through our chat.</p>
                </div>
            </div>

            <div className='flex justify-center mt-10'>
                <button className="bg-gradient-to-r from-pink-500 to-orange-500 text-white py-3 px-20 rounded-full shadow-xl font-semibold text-xl transition duration-300 ease-in-out hover:scale-105" onMouseUp={openGSModal}>
                    Get Started
                </button>
            </div>

            <div className="mt-10 bg-gray-900 p-6 rounded-lg shadow-inner flex items-start space-x-4 mx-auto md:w-1/2 text-left">
                <FaShieldAlt className="text-green-400 w-12 h-12" />
                <div>
                    <h3 className="text-xl font-semibold">Privacy & Accuracy Guaranteed</h3>
                    <p className="mt-2 text-gray-300">
                        We value your privacy. Only essential information is stored and shared with your consent. <strong>Our AI operates at 95% accuracy.</strong>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default NewHowOurServiceWorks;