import React, { useState } from 'react';
import TestLivenessCheck from '../Components/TestLivenessCheck';
import FailureComponent from '../Components/Failure';
import './Pages.css';
import { useNavigate } from 'react-router-dom';
import { ImageData } from './FindYourMan';


const TestVerifyWoman = () => {

    const navigate = useNavigate();

    const [failure, setFailure] = useState(false);

    const onFailure = () => {
        setFailure(true);
    }

    const onSuccess = () => {
        console.log("onSuccess called");
    }

    return (
        <div className="w-full h-screen verify-dark-bg text-center">
            {failure ? <FailureComponent errorMessage="Oh no! 🙁 It seems like our AI couldn’t confirm your womanhood. We understand the diversity of women’s appearances, so if you feel this is a mistake or if you are masc-presenting, please don’t hesitate to email us at help@ishemine.com. We want to make sure every woman feels welcome and protected here, and we’ll help sort things out! 🌟" /> : <TestLivenessCheck onFailure={onFailure} onSuccess={onSuccess} />}
        </div>
    );
}

export default TestVerifyWoman;