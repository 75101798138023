import React, { useState } from 'react';
import LivenessCheck from '../Components/LivenessCheck';
import FailureComponent from '../Components/Failure';
import './Pages.css';
import { useNavigate } from 'react-router-dom';
import { ImageData } from './FindYourMan';

type VerifyWomanProps = {
    authToken: string;
    loginMethod: string;
    faceIds: string[];
    imageData: ImageData[];
    setQueryResult: React.Dispatch<React.SetStateAction<any>>;
}

const VerifyWoman: React.FC<VerifyWomanProps> = ({ authToken, loginMethod, faceIds, imageData, setQueryResult }) => {

    const navigate = useNavigate();

    const [failure, setFailure] = useState(false);

    const onFailure = () => {
        setFailure(true);
    }

    const claimPhotos = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", authToken);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "ids": imageData.map((image) => image.id),
        });

        var requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: raw,
        };

        let endpoint = "https://api.ishemine.com/evals/leader/claim/";
        return fetch(endpoint, requestOptions)
    }

    const onSuccess = () => {
        if (loginMethod === "login") {
            navigate('/');
        } else if (loginMethod === "search" && faceIds.length > 0) {
            // do the same as above except use faceIds
            claimPhotos().then(response => {
                if (response.ok) {
                    var myHeaders = new Headers();
                    myHeaders.append("Authorization", authToken);

                    var requestOptions: RequestInit = {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    let endpoint = "https://api.ishemine.com/evals/query/create/?";
                    faceIds.forEach((faceId) => {
                        endpoint += `face_ids=${faceId}&`
                    });

                    fetch(endpoint, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        setQueryResult(result);
                        navigate("/status-update");
                    })
                    .catch(error => console.log('error', error));
                }
            })
        }
    }

    return (
        <div className="w-full h-screen verify-dark-bg text-center">
            <div className="center-container">
            {failure ? <FailureComponent errorMessage="Oh no! 🙁 It seems like our AI couldn’t confirm your womanhood. We understand the diversity of women’s appearances, so if you feel this is a mistake or if you are masc-presenting, please don’t hesitate to email us at help@ishemine.com. We want to make sure every woman feels welcome and protected here, and we’ll help sort things out! 🌟" /> : <LivenessCheck authToken={authToken} onFailure={onFailure} onSuccess={onSuccess} loginMethod={loginMethod} />}
            </div>
        </div>
    );
}

export default VerifyWoman;