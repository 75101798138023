import LightLogo from '../Components/LightLogo';
import { useNavigate, useParams } from 'react-router-dom';
import './Pages.css';
import ExamplePic from '../Assets/example.jpeg';
import JoinFB from '../Components/JoinFB';

interface FakeHesMineProps {
    uploadedImages: Array<string>;
}

const FakeHesMine:React.FC<FakeHesMineProps> = ({ uploadedImages }) => {
    const navigate = useNavigate();
    
    return (
        <div className="hes-mine-bg flex flex-col min-h-screen">
            <header className="p-4">
                <div className="container mx-auto flex items-center justify-between hover:cursor-pointer" onMouseUp={() => navigate("/")}>
                    <LightLogo/>
                </div>
            </header>
            <main className="flex-1 mx-auto p-4 text-center overflow-hidden w-screen">
                <div id="inner" className="lock-border">
                    <div className="flex justify-center m-6 z-10">
                        <img src={uploadedImages.length > 0 ? uploadedImages[0] : ExamplePic} alt="example pic" className="h-80 w-60 object-cover rounded-xl"/>
                    </div>
                    <div className="dela-gothic text-white z-10">
                        <h1 className="text-4xl">BACK OFF EVERYONE!</h1>
                        <h3 className="text-2xl">He's yours.</h3>
                    </div>
                    <p className="mt-2 text-cyan-300 text-lg z-10">Scanned {(new Date()).toLocaleDateString('en-US', {month: 'short', day: '2-digit', year: 'numeric'})}</p>
                </div>
                <JoinFB/>
            </main>
        </div>
    );
}

export default FakeHesMine;