import React, { useState, Fragment } from 'react';
import Cropper from 'react-easy-crop';
import { Dialog, Transition } from '@headlessui/react';

interface CroppingModalProps {
    isOpen: boolean;
    closeModal: () => void;
    image: string | null;
    onCropConfirm: (croppedImage: string) => void;
}

const CroppingModal: React.FC<CroppingModalProps> = ({
    isOpen,
    closeModal,
    image,
    onCropConfirm,
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<{ width: number; height: number; x: number; y: number } | null>(null);
    
    const handleCropConfirm = async () => {
        const croppedImage = await getCroppedImage(image);
        onCropConfirm(croppedImage);
    };

    const getCroppedImage = async (image: string | null): Promise<string> => {
        if (!image) return '';
    
        const img = new Image();
        img.src = image;
        const crop = croppedAreaPixels;
        if (!crop) return '';

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        
        const maxSize = Math.max(img.width, img.height);
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
        
        canvas.width = safeArea;
        canvas.height = safeArea;

        if (!ctx) return '';
        
        ctx.drawImage(
            img,
            safeArea / 2 - img.width * 0.5,
            safeArea / 2 - img.height * 0.5
        );
        const data = ctx.getImageData(0, 0, safeArea, safeArea);
        
        canvas.width = crop.width;
        canvas.height = crop.height;
        
        ctx.putImageData(
            data,
            Math.round(0 - safeArea / 2 + img.width * 0.5 - crop.x),
            Math.round(0 - safeArea / 2 + img.height * 0.5 - crop.y)
        );
        
        return canvas.toDataURL("image/jpeg");
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative inset-0 overflow-y-auto" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="w-72 p-4 bg-cyan-100 border border-1 border-zinc-300 rounded-2xl">
                            <div className="inline-block w-full h-96 max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-200 shadow-xl">
                                {image && (
                                    <Cropper
                                        image={image}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={3 / 4}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={(_: any, croppedAreaPixels: React.SetStateAction<{ width: number; height: number; x: number; y: number; } | null>) => {
                                            setCroppedAreaPixels(croppedAreaPixels);
                                        }}
                                    />
                                )}
                            </div>
                            <button
                                className="dela-gothic w-full px-4 py-2 mb-10 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600"
                                onClick={handleCropConfirm}
                            >
                                Confirm Crop
                            </button>
                        </div>
                    </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default CroppingModal;