import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import Layout from "../Layout";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {

    const recaptchaRef = React.createRef<ReCAPTCHA>();

    const [recaptchaToken, setRecaptchaToken] = useState<string>("");

    useEffect(() => {
        if (recaptchaToken) {
            fetch("https://api.ishemine.com/users/contact/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    captcha: recaptchaToken,
                    name: formData.name,
                    email: formData.email,
                    subject: formData.subject,
                    message: formData.message
                })
            }).then(
                (response) => {
                    if (response.ok) {
                        alert("Message sent successfully!");
                    } else {
                        alert("Something went wrong. Please try again later.");
                    }
                }
            )
        }
    }, [recaptchaToken]);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(formData);
        if (recaptchaRef && recaptchaRef.current) {
            recaptchaRef.current.execute();
        }
    }

    return (
        <Layout>
            <div className="container mx-auto p-8 flex flex-col">
                <h2 className="text-2xl font-bold mb-5 md:text-center">Contact Us</h2>
                <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto text-left">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Name:</label>
                        <input 
                            type="text" 
                            id="name" 
                            name="name" 
                            value={formData.name} 
                            onChange={handleChange}
                            placeholder="Regina George"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email:</label>
                        <input 
                            type="email" 
                            id="email" 
                            name="email" 
                            value={formData.email} 
                            onChange={handleChange}
                            placeholder="regina.george@example.com"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">Subject:</label>
                        <input 
                            type="text" 
                            id="subject" 
                            name="subject" 
                            value={formData.subject} 
                            onChange={handleChange}
                            placeholder="Inquiry about your services"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">Message:</label>
                        <textarea 
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            rows={5}
                            placeholder="Your message goes here... (Please include your phone number if you are asking to remove account data!)"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        ></textarea>
                    </div>
                    <div className="flex items-center justify-center"> {/* Centered the submit button */}
                        <button 
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Submit
                        </button>
                    </div>
                    <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LcdznYnAAAAAFicIrLfu9EJuODfhkIScxm1_nCS"
                    onChange={(token: string | null) => token ? setRecaptchaToken(token) : null}
                    />
                </form>
            </div>
        </Layout>
    );
};

export default Contact;