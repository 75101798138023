import React, { useState, useEffect } from 'react';
import NewPhotoUploadGrid from '../Components/NewPhotoUploadGrid';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import LoadingSpinner from './LoadingSpinner';
import { ImageData } from '../Pages/AuthlessFindYourMan';

type MiniUploadHimProps = {
    setImageData: React.Dispatch<React.SetStateAction<ImageData[]>>;
    setLoginMethod: React.Dispatch<React.SetStateAction<string>>;
}

const MiniUploadHim:React.FC<MiniUploadHimProps> = ({ setImageData, setLoginMethod }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [numPhotos, setNumPhotos] = useState<number>(0); // [0, 6]
    const [photos, setPhotos] = useState<string[]>([]);

    const handleButtonClick = () => {
        setIsLoading(true);
        setErrorMsg("");

        // Construct API endpoint
        let endpoint = "https://api.ishemine.com/evals/leader/results/?";
        for (const id of photos) {
            endpoint += `ids=${id}&`;
        }

        // API call setup
        const requestOptions: RequestInit = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(endpoint, requestOptions)
            .then(response => response.json())
            .then(results => {
                setIsLoading(false);
                console.log(results);
                if (!photos.every(id => results[id])) {
                    // Some photos were not returned in the response. This would be really weird.
                    setErrorMsg("Error occured. Please try again and reupload photos!");
                    // setPhotoUploaded(false);
                    // setSubmitted(false);
                    return;
                }
                if (photos.every(id => results[id].status === "complete" && results[id].results.length === 0)) {
                    // All photos were indexed but no faces were found. Cannot continue.
                    setErrorMsg("No faces were found in any of the photos. Upload new photos.");
                    // setPhotoUploaded(false);
                    // setSubmitted(false);
                    return;
                }
                const loadedFaces = photos.filter(id => results[id].status === "complete" && results[id].results.length > 0);
                if (loadedFaces.length > 0) {
                    var imageDatas: Array<ImageData> = []
                    for (const id of loadedFaces) {
                        const newImageDatas = results[id].results;
                        imageDatas = imageDatas.concat(newImageDatas.map((imageData: any) => {
                            return {
                                id: id,
                                status: "complete",
                                ...imageData
                            }
                        }));
                    }
                    // Needs to be set for next page before any alt-continue can happen
                    setImageData(imageDatas);
                }
                const notFinished = photos.filter(id => results[id].status === "pending");
                if (notFinished.length > 0) {
                    // Some photos are still being indexed
                    setErrorMsg("Some photos were not indexed yet. Try again in a second.");
                    // setPhotoUploaded(false);
                    // setSubmitted(false);
                    // setShowAltContinue(true);
                    return;
                }
                const noFaces = photos.filter(id => results[id].status === "complete" && results[id].results.length === 0);
                if (noFaces.length > 0) {
                    // Some photos were indexed but no faces were found
                    setErrorMsg("Some photos did not contain a face. Try again?");
                    // setPhotoUploaded(false);
                    // setSubmitted(false);
                    // setShowAltContinue(true);
                    return;
                }
                setLoginMethod("search");
                navigate("/find-your-man-no-auth");
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false);
                setErrorMsg("Error occurred. Please try again!");
            });
    };

    useEffect(() => {
        console.log("numPhotos", numPhotos);
    }, [numPhotos]);

    return (
        <div className="mx-auto w-full md:w-1/2 lg:w-1/3 bg-rose-400 p-4 rounded-lg shadow-lg text-center text-black border border-white">
            <h1 className="dela-gothic text-xl md:text-2xl mt-4 mb-4 md:m-4">Upload Pictures of Him</h1>
            <div className="flex flex-col items-center">
                <p className="font-semibold text-zinc-800 w-7/8 md:w-4/5 text-xs md:text-sm text-center">(Max 6. Min. 1) The more photos you upload, the more likely a match will be found (if there is one).</p>
            </div>
            <NewPhotoUploadGrid numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotoIds={setPhotos} />
            <div className="text-left mt-5 text-xs md:text-sm italic">
                <p>
                    We keep photos confidential to everyone except others who upload the same man. 
                </p>
                <p className="mt-2">
                    Make sure his face is pointed at the camera and unobstructed. Pics that contain both of you tend to do best...
                </p>
            </div>
            {errorMsg && <p className="text-red-500 text-sm mb-4">{errorMsg}</p>}
            <button
                className="bg-purple-500 hover:bg-purple-700 text-white py-2 px-8 rounded rounded-xl mt-6 font-semibold shadow-lg transition duration-300 ease-in-out hover:scale-105 flex items-center justify-center mx-auto  disabled:opacity-70 disabled:pointer-events-none pointer"
                onClick={handleButtonClick}
                disabled={numPhotos === 0 || isLoading}
            >
                {(isLoading || numPhotos !== photos.length) ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <FaSearch className="mr-2" /> Find out
                    </>
                )}
            </button>
        </div>
    );
};

export default MiniUploadHim;