import React from 'react';
import './Components.css';
import { MessageContent } from '../Pages/types';

interface IncomingChatBubbleProps {
    message: MessageContent;
}

const IncomingChatBubble: React.FC<IncomingChatBubbleProps> = ({ message }) => {
    const isImage = message instanceof Object;
    const multipleLines = message.toString().split('\n').length > 1 || message.toString().length > 40; // Example: If more than 40 characters, treat as multiple lines. Adjust as needed.
    
    return (
        <div className="flex flex-col items-start mb-2">
            {isImage ? (
                <div className="rounded-lg w-1/2 md:w-1/4 lg:w-1/6 overflow-hidden">
                    {message}
                </div>
            ) : (
                <div className={`border border-gray-300 
            pl-4 pr-4 pt-2 pb-2 text-sm mt-1 mb-1
            ${multipleLines ? 'w-5/6 block rounded-2xl' : 'inline-block rounded-full'}`}>
                    {message}
                </div>
            )}
        </div>
    );
};

export default IncomingChatBubble;
