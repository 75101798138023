import React, { useEffect, useState } from 'react';
import Layout from '../Layout';
import { FaUpload } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';
import OptOutPhotoUpload from '../Components/OptOutPhotoUpload';

const OptOut: React.FC = () => {
  // const [images, setImages] = useState<FileList | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");

  useEffect(() => {
    if (recaptchaToken && selectedImage) {
      const filename = "default.jpeg";
      const myHeaders = new Headers();
      const formdata = new FormData();

      fetch(selectedImage).then(res => res.blob()).then(blob => {
        formdata.append("photo", blob, filename);
        formdata.append("captcha", recaptchaToken);

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        
        console.log("submission reached");

        fetch("https://api.ishemine.com/evals/opt-out/", requestOptions)
          .then(response => response.text())
          .then(resp => {
              const parsed = JSON.parse(resp);
              console.log(parsed["id"]);
              setIsSubmitted(true);
          })
          .catch(error => console.log('error in indexing', error));
      });
    }
  }, [recaptchaToken, selectedImage])
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (recaptchaRef && recaptchaRef.current) {
      recaptchaRef.current.execute();
    } else {
      console.log("Recaptcha ref not found");
    }
  }
  
  return (
    <Layout>
      <div className="container mx-auto p-8 flex flex-col items-center">
        <h2 className="text-2xl font-bold mb-5 text-center">Opt Out of IsHeMine.com</h2>
        <p className="text-left mb-5 px-4">
          We respect your privacy. If you prefer not to appear on IsHeMine.com, you can opt out by uploading a picture of your face (front-facing with clear lighting and <i>just your face</i>). <br/><br/>We'll ensure photos of you aren't be part of the platform, now or in the future.
        </p>
        <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto text-center">
          <div className="mb-8 align-center flex flex-col items-center justify-center">
            {/* <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="images">Upload Pictures:</label>
            <div className="flex flex-col items-center">
              <FaUpload size={30} className="text-red-400 mb-2"/>
              <input 
                type="file" 
                id="images" 
                name="images" 
                multiple 
                accept="image/*"
                onChange={(e) => setImages(e.target.files)} 
                className="max-w-[70vw] shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div> */}
            <OptOutPhotoUpload selectedImage={selectedImage} setSelectedImage={setSelectedImage}/>
          </div>
          <div className="flex items-center justify-center">
            <button 
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit Opt-out Request
            </button>
          </div>
          <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LcdznYnAAAAAFicIrLfu9EJuODfhkIScxm1_nCS"
                    onChange={(token: string | null) => token ? setRecaptchaToken(token) : null}
                    />
        </form>
        {isSubmitted && (
          <div className="mt-5 text-center">
            <p className="text-green-500">Your opt-out request has been received! We'll process it promptly and ensure your privacy is respected.</p>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default OptOut;
