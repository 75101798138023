import React, { ChangeEvent } from 'react';

type PhoneNumberInputProps = {
    onChange: (value: string) => void;
    errorMessage: string|null;
    phoneNumber: string;
};

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ onChange, errorMessage, phoneNumber }) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        {/* Country Code Input */}
        <input
          type="text"
          className="w-16 md:w-20 mr-2 px-2 py-2 border border-zinc-700 rounded-l-lg focus:ring-indigo-200 focus:border-indigo-500 focus:outline-none bg-black"
          value="+1"
          disabled
        />

        {/* Phone Number Input */}
        <input
          type="tel"
          className="flex-1 px-2 py-2 border border-zinc-700 rounded-r-lg  focus:ring-indigo-200 focus:border-indigo-500 focus:outline-none bg-black"
          placeholder="(555) 1234-5678"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange(e.target.value)
          }
          value={phoneNumber}
          autoFocus
        />
      </div>
      {errorMessage && <p className="text-red-500 mt-2 text-left">{errorMessage}</p>}
    </div>
  );
};

export default PhoneNumberInput;