import Layout from "../Layout";

const CommGuide = () => {
    return (
        <Layout>
            <div className="text-left mx-auto p-6 max-w-3xl bg-pink-100 shadow-md rounded-lg">
                <h1 className="text-3xl font-bold mb-6 border-b pb-2">Community Guidelines for IsHeMine.com</h1>

                <p className="mb-4 text-gray-600"><strong>Last updated:</strong> 9/28/23</p>

                <p className="mb-4">
                    Welcome to IsHeMine.com! 🌸 We aim to provide a comforting and supportive environment focused on protection and care. We are not a platform for shaming or negative discussions, but rather a protective space for women to share and be informed. We seek to foster a community of respect, kindness, and safety.
                </p>

                <h2 className="text-xl font-semibold my-4">🌟 Our Mission 🌟</h2>
                <p>IsHeMine.com is dedicated to creating a secure and caring space where women can upload photos of their significant others to protect themselves and inform others. Our mission is to empower each user, fostering awareness and creating a network of support and safety.</p>

                <p className="my-4">
                    It is crucial to understand that IsHeMine.com is <strong>not</strong> a space to post comments about individuals or to shame anyone. This is a platform exclusive to sharing and protecting, aiming to create a harmonious and respectful environment for everyone involved.
                </p>

                <h2 className="text-xl font-semibold my-4">1. Respect and Privacy 🌿</h2>
                <p>Please maintain a respectful and courteous attitude and ensure the privacy and dignity of every individual are upheld. Remember, we are here to support and protect each other in a loving and caring manner.</p>

                <h2 className="text-xl font-semibold my-4">2. Reporting and Moderation 🌺</h2>
                <p>If you encounter any inappropriate content or violations, please report them immediately. We are committed to maintaining a respectful and safe environment and will address any concerns promptly.</p>

                <h2 className="text-xl font-semibold my-4">3. Opting Out and Contact 🌸</h2>
                <p>If anyone is uncomfortable with their photo being on our platform, they can easily opt out by visiting <a href="https://ishemine.com/opt-out" className="text-blue-500 hover:underline">ishemine.com/opt-out</a>. For any concerns or queries, feel free to reach out to us at <a href="mailto:care@ishemine.com" className="text-blue-500 hover:underline">care@ishemine.com</a>.</p>

                <h2 className="text-xl font-semibold my-4">4. Constructive Participation 🍃</h2>
                <p>Let’s make sure our participation is constructive, positive, and relevant. Let’s nourish the environment with kindness, understanding, and support for each other.</p>

                <h2 className="text-xl font-semibold my-4">5. Helpful Resources 🌼</h2>
                <p>If you or someone else needs support, please consider reaching out to:</p>
                <div className="bg-pink-200 p-4 rounded mb-4">
                    <p>National DV Hotline: (800) 799-7233 | Text: START to 88788</p>
                    <p>Website: <a href="https://www.thehotline.org" className="text-blue-500 hover:underline">thehotline.org</a></p>
                    <p>National SA Hotline: (800) 656-4673</p>
                    <p>Website: <a href="https://hotline.rainn.org" className="text-blue-500 hover:underline">hotline.rainn.org</a></p>
                </div>

                <p className="mt-6">
                    Thank you for being a valuable part of IsHeMine.com and for contributing to a supportive, respectful, and caring community. Let’s work together to maintain a sanctuary of safety and support!
                </p>
            </div>
        </Layout>
    );
};

export default CommGuide;