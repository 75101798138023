import Layout from "../Layout";
import IsThisHimCard from "../Components/IsThisHimCard";
// import { useSwipeable } from 'react-swipeable';

const IsThisHim = () => {
    return (
        <Layout>
            <h4 className="dela-gothic text-2xl md:text-3xl mt-4 mb-4 md:m-4">Hmm...</h4>
            <h1 className="dela-gothic text-red-700 text-4xl md:text-5xl mt-4 mb-4 md:m-4">Is this him?</h1>
            <div className="flex justify-center m-10">
                <IsThisHimCard />
            </div>
        </Layout>
    );
}

export default IsThisHim;