import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';

dayjs.extend(relativeTime)

interface ChatOtherUser {
    id: number;
}
interface ChatMessage {
    sender: number;
    content: string;
    timestamp: string;
}
export interface ChatThumbnailProps {
    id: number;
    avatar: string;
    other_user: ChatOtherUser;
    last_message?: ChatMessage;
    created_at: string;
    status: "read" | "unread";
}

const ChatThumbnail: React.FC<ChatThumbnailProps> = ({ id, avatar, other_user, last_message, status, created_at }) => {
    const navigate = useNavigate();
    return (
        <div key={id} className={`chat-status mb-2 p-2 rounded hover:bg-gray-100 cursor-pointer flex items-center`} onClick={() => navigate(`/chat/${id}`)}>
            <img src={avatar} alt="Avatar" style={{ width: '48px', height: '64px', borderRadius: '8px' }} className="mr-3" />
            <div className={`text-left flex-1 pr-2 ${status === "unread" ? "font-bold" : ""}`}>
                {last_message ? <>
                    <span className="text-sm text-gray-500 block sm:inline">{last_message.sender === other_user.id ? "Anonymous:" : "You:"} </span>
                    <span className="text-sm md:text-base block sm:inline truncate w-5/6">{last_message.content.substring(0, 30)}</span>
                    <div className="text-xs text-gray-400 mt-1">{dayjs(last_message.timestamp).fromNow()}</div>
                </> : <span className="text-sm md:text-base block sm:inline truncate w-5/6">No messages</span>}
            </div>
            <div className="text-xs text-gray-400 self-end mr-2 hidden sm:block">Connected on {new Date(created_at).toLocaleDateString('us')}</div>
            <span className="ml-auto">&#9656;</span> {/* Arrow indicating the chat can be opened */}
        </div>
    );
}

export default ChatThumbnail;