import Layout from "../Layout";

const Terms = () => {
    return (
        <Layout>
            <div className="text-left mx-auto p-6 max-w-3xl bg-red-100 shadow-md rounded-lg">
                <h1 className="text-3xl font-bold mb-6 border-b pb-2">Terms of Service</h1>
                <p className="mb-4 text-gray-600"><strong>Last updated:</strong> 9/29/23</p>
                <p className="mb-4">Welcome to IsHeMine.com. Please read these Terms of Service ('Terms', 'Terms of Service') carefully before using the IsHeMine.com website operated by OuttaControl LLC ('us', 'we', or 'our').</p>
                
                <h2 className="text-xl font-semibold my-4">1. Acceptance of Terms</h2>
                <p className="mb-4">By accessing and using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the Service.</p>
                
                <h2 className="text-xl font-semibold my-4">2. User Conduct</h2>
                <p className="mb-4">You agree not to use the Service for any unlawful purpose or to conduct any unlawful activity, including, but not limited to, fraud, embezzlement, money laundering or insider trading.</p>

                <h2 className="text-xl font-semibold my-4">3. Image Upload and Ownership</h2>
                <p className="mb-4">Users must only upload images that they own or have permission to use. Unauthorized sharing of images without consent may violate privacy regulations and personal rights. If you believe an image of you has been uploaded without your consent, please contact us for its removal.</p>

                <h2 className="text-xl font-semibold my-4">4. Termination</h2>
                <p className="mb-4">We reserve the right to terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.</p>

                <h2 className="text-xl font-semibold my-4">5. Changes to Terms</h2>
                <p className="mb-4">We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

                <h2 className="text-xl font-semibold my-4">6. Contact Us</h2>
                <p className="mb-4">If you have any questions about these Terms, please contact us at <a href="mailto:help@ishemine.com" className="text-blue-500 hover:underline">help@ishemine.com</a>.</p>
            </div>
        </Layout>
    );
};

export default Terms;
