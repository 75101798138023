import React from 'react';
import Close from './Close';

interface ReportModalProps {
  authToken: string;
  setShowModal: (show: boolean) => void;
  photoUrl: string;
}

const ReportModal: React.FC<ReportModalProps> = ({ setShowModal, photoUrl, authToken }) => {
  const [reason, setReason] = React.useState<string>('');

  const reportPhoto = (photoUrl: string, reason: string) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      photoUrl: photoUrl,
      reason: reason
    });

    var requestOptions: RequestInit = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.ishemine.com/evals/report/", requestOptions)
    .then(_ => setShowModal(false))
  }

  return (
    <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg text-black max-w-lg mx-4 relative">
        <button className="absolute top-4 right-4 text-xl font-bold" onClick={() => reportPhoto(photoUrl, reason)}>X</button>
        <h2 className="mb-4 text-lg font-bold">Report Image</h2>
        <p className="mb-6">Please let us know why you are reporting this image. We take every report seriously and strive to maintain a safe and respectful community.</p>
        <textarea className="w-full p-2 mb-4 border rounded" placeholder="Write your report here..." onChange={e => setReason(e.target.value)} rows={4} />
        <button className="bg-red-500 text-white py-2 px-4 rounded" onClick={() => reportPhoto(photoUrl, reason)}>Submit Report</button>
      </div>
    </div>
  );
};

export default ReportModal;