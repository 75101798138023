export type MessageContent = string | JSX.Element;
export enum PostTypes {
    SEARCH,
    CONFESSION,
    TINDER
};

export const tags = [
    "Met on Tinder", "Met on Hinge", "Met on Bumble", 
    "Met on other dating app", "Met IRL", "Long Distance",
    "High School Sweethearts", "College Romance", "Workplace Crush",
    "Unexpected Connection", "Friends First", "Adventure Buddies"
];

export const attributeEmojis: Record<string, string> = {
    humor: '😂',
    courtesy: '🙏',
    honesty: '🤝',
    intelligence: '🧠',
    appearance: '🌟',
    empathy: '💖',
    chemistry: '💥',
    punctuality: '⏰',
    communication: '🗣️',
    ambition: '🚀',
    adventure: '🌍',
    compatibility: '❤️',
};  