import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import './Components.css';
import Icon from './Icon';
import Close from './Close';
import PhoneNumberInput from './PhoneNumberInput';
import ReCAPTCHA from "react-google-recaptcha";
import { parsePhoneNumber, AsYouType } from 'libphonenumber-js';
import BlueSubmitBtn from './BlueSubmitBtn';

type GetStartedProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  phoneNumberMem: string;
  setPhoneNumberMem: (value: string) => void;
};

const GetStarted: React.FC<GetStartedProps> = ({ isOpen, setIsOpen, phoneNumberMem, setPhoneNumberMem }) => {
  const navigate = useNavigate();

  function closeModal() {
    setIsOpen(false);
  }

  const [numberEntered, setNumberEntered] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const [recaptchaToken, setRecaptchaToken] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // window?.visualViewport?.addEventListener('resize', _ => window.scrollTo(0, 0));

  function toggleLoading() {
    setIsLoading((prevLoading) => !prevLoading);
  }

  function handleNumberChange(value: string) {
    const asYouType = new AsYouType('US');
    const formattedNumber = asYouType.input(value);
    const digitsOnly = formattedNumber.replace(/\D/g, ''); // Remove non-digit characters
    if (digitsOnly.length <= 10) {
      const isRightLength = digitsOnly.length === 10;
      setPhoneNumber(isRightLength ? formattedNumber : digitsOnly);
      setNumberEntered(isRightLength);
      setPhoneNumberError(null); // Reset any previous errors
    }
  }

  function handleSignUp() {
    if (recaptchaRef && recaptchaRef.current) {
      recaptchaRef.current.execute();
    } else {
      console.log("Recaptcha ref not found");
    }
  }

  useEffect(() => {
    if (recaptchaToken && phoneNumber) {
      console.log(recaptchaToken);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const parsedPhoneNumber = parsePhoneNumber(phoneNumber, "US");
      
      if (!parsedPhoneNumber.isValid()) {
        setPhoneNumberError('Please enter a valid phone number.');
        return;
      }

      const urlencoded = new URLSearchParams();
      urlencoded.append("mobile", parsedPhoneNumber ? parsedPhoneNumber.number : "+1");
      urlencoded.append("captcha", recaptchaToken);

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      toggleLoading();

      fetch("https://api.ishemine.com/auth/mobile/", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}. Come back in a few minutes!`);
        }
        return response.text();
      })
      .then(result => {
        console.log(result);
        toggleLoading();
        setSubmitted(true);
        setPhoneNumberMem(phoneNumber);
      })
      .catch(error => {
        console.log('error', error);
        setPhoneNumberError(`An error occurred: ${error.message}`);
        toggleLoading();
        setPhoneNumber("");
        setNumberEntered(false);
      });
    }
  }, [recaptchaToken, phoneNumber]);

  useEffect(() => {
    if (phoneNumberMem && submitted) {
      console.log("Navigating to verify...");
      setSubmitted(false);
      navigate("/verify");
    }
  }, [phoneNumberMem, submitted, navigate]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-black bg-opacity-25"
            />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-10 flex items-center justify-center">
              <Dialog.Panel
                className="dialog-panel-dark-bg w-full md:w-1/2 lg:w-1/3 h-full md:h-3/4 p-6 text-center align-middle md:rounded-lg shadow-xl relative"
              >
                <div onMouseUp={closeModal} className="cursor-pointer absolute top-5 right-5">
                    <Close/>
                </div>
                <div className="flex flex-col items-center mt-20 md:mt-12 mb-8 md:mb-4"> {/* Wrap Icon in a container */}
                  <Icon />
                </div>
                <Dialog.Title
                  as="h3"
                  className="text-4xl font-medium leading-6 text-white dela-gothic justify-center"
                >
                  Sign In
                </Dialog.Title>
                <div className="mt-8 mb-3 text-left">
                    <h4 className="font-bold text-xl">My number is</h4>
                </div>
                <PhoneNumberInput onChange={handleNumberChange} errorMessage={phoneNumberError} phoneNumber={phoneNumber}/>
                <p className="text-center mt-4">
                    {/* TODO: Make links to privacy stuff */}
                    By clicking Continue, you agree to our <a href="/terms" className="text-blue-600 font-bold underline hover:no-underline">Terms</a> and agree to receive text messages about important updates (e.g., possible future matches). Learn about how we process your data in our <a href="/privacy" className="text-blue-600 font-bold underline hover:no-underline">Privacy Policy</a> and <a href="/cookie-policy" className="text-blue-600 font-bold underline hover:no-underline">Cookie Policy</a>.
                </p>
                <div className="absolute bottom-5 left-5 right-5">
                  <BlueSubmitBtn isLoading={isLoading} numberEntered={numberEntered} handleClick={handleSignUp} text={"Continue"}/>
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6LcdznYnAAAAAFicIrLfu9EJuODfhkIScxm1_nCS"
                  onChange={(token: string | null) => token ? setRecaptchaToken(token) : null}
                />
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GetStarted;