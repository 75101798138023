import React from 'react';
import Layout from "../Layout";
import { useNavigate } from "react-router-dom";

interface FakeStatusUpdateProps {
    demoState: string;
}

const FakeStatusUpdate:React.FC<FakeStatusUpdateProps> = ({ demoState }) => {
    const navigate = useNavigate();

    const handleCheck = () => {
        if (demoState === "cheating") {
            navigate("/ugc/results");
        } else if (demoState === "loyal") {
            navigate("/ugc/congrats");
        }
    }

    return (
        <Layout>
            <h1 className="font-bold text-lg mt-8">The results are in...</h1>
            <div className="flex justify-center">
                <div className="w-full md:w-1/2 lg:w-1/3 p-4 bg-sky-300 border border-blue-600 rounded-xl text-blue-900 mt-4">
                    <h1 className="dela-gothic text-2xl">Want to check?</h1>
                    <h3 className="text-4xl m-4">🤞🤞🤞</h3>
                    <button className="dela-gothic bg-blue-950 p-4 rounded-lg text-white text-xs shadow border border-2 border-gray-100 transition-all duration-300 hover:bg-blue-800 hover:text-blue-100 hover:shadow-lg" onClick={handleCheck}>HIS REAL RELATIONSHIP STATUS</button>
                    <p className="text-sm italic text-black mt-2">It's completely free.</p>
                </div>
            </div>
        </Layout>
    );
};

export default FakeStatusUpdate;