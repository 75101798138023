import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import './Components.css';
import PlusSign from './PlusSign';
import CroppingModal from './CroppingModal';
import WhiteX from './WhiteX';
import heic2any from "heic2any";
import { readAndCompressImage } from 'browser-image-resizer';

const config = {
    quality: 0.5,
    maxWidth: 800,
    maxHeight: 1000,
    debug: true
  };

type FakePhotoUploadProps = {
    sModalOpen: boolean;
    setSModalOpen: (value: boolean) => void;
    numPhotos: number;
    setNumPhotos: (value: number) => void;
    setPhotos: React.Dispatch<React.SetStateAction<string[]>>;
    defaultPhoto: string;
    arrayIndex: number;
}

const FakePhotoUpload: React.FC<FakePhotoUploadProps> = ({ sModalOpen, setSModalOpen, numPhotos, setNumPhotos, setPhotos, defaultPhoto, arrayIndex }) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [tmpImage, setTmpImage] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const defaultProcessedRef = useRef<boolean>(false);

    useEffect(() => {
        if (defaultPhoto !== "" && !defaultProcessedRef.current) {
            const resizeAndSetDefaultPhoto = async () => {
                console.log("resize called again...")
                const aspectRatio = 4 / 3;
                const resizeConfig = {
                    ...config,
                    width: 800,
                    height: 800 / aspectRatio,
                };

                const convertStringToFile = async (dataURI: string): Promise<File> => {
                    const response = await fetch(dataURI);
                    const blob = await response.blob();
                    return new File([blob], 'default.jpg', { type: 'image/jpeg', lastModified: Date.now() });
                };

                const defaultPhotoFile = await convertStringToFile(defaultPhoto);
                const resizedDefaultPhoto = await readAndCompressImage(defaultPhotoFile, resizeConfig);
                const resizedImageUrl = URL.createObjectURL(resizedDefaultPhoto);
                defaultProcessedRef.current = true;
                setSelectedImage(resizedImageUrl);
                storeImageInOtherGirlPhotos(resizedImageUrl); // Store the default photo in otherGirlPhotos
            };

            resizeAndSetDefaultPhoto();
        }
    }, [defaultPhoto]);

    const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        const inputf = event.target.files?.[0];
        if (!inputf) return;
        const file = await readAndCompressImage(inputf, config);
        if (file) {
            let imageUrl = URL.createObjectURL(file);
            if (file.type === 'image/heic') {
                try {
                    const blob = await fetch(imageUrl).then((res) => res.blob());
                    const conversionResult = await heic2any({ blob, toType: "image/jpeg", quality: 0.5 });

                    if (conversionResult instanceof Blob) {
                        imageUrl = URL.createObjectURL(conversionResult);
                    } else {
                        console.error('HEIC conversion returned unexpected result.');
                    }
                } catch (error) {
                    console.error('HEIC conversion error:', error);
                    return;
                }
            }
            setTmpImage(imageUrl);
            setModalOpen(true);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleComponentClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleConfirmCrop = (croppedImage: string) => {
        console.log("handle confirmCrop called")
        setSelectedImage(croppedImage);
        closeModal();
        storeImageInOtherGirlPhotos(croppedImage);
    };

    const storeImageInOtherGirlPhotos = (image: string) => {
        setPhotos(prevPhotos => {
            const newPhotos = [...prevPhotos];
            newPhotos[arrayIndex] = image;
            return newPhotos;
        });
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        setTmpImage(null);
        setNumPhotos(numPhotos - 1);
        setPhotos(prevPhotos => {
            const newPhotos = [...prevPhotos];
            newPhotos[arrayIndex] = ""; // Set to empty string or remove the element
            return newPhotos;
        });
    };

    useEffect(() => {
        setSModalOpen(modalOpen);
    }, [modalOpen]);

    return (
        <>
            {
                sModalOpen
                ?   <div className="w-24 h-32 md:w-28 md:h-36 pt-3 relative photo-upload-container" onClick={(!selectedImage) ? handleComponentClick : () => null}>
                        {
                            selectedImage
                            ? <div 
                                className="photo-upload-bg w-20 h-28 md:w-24 md:h-32 rounded-lg shadow-md"
                                style={{
                                    backgroundImage: `url(${selectedImage})`,
                                    backgroundSize: 'cover',
                                }}
                            ></div>
                            : <div 
                                className="photo-upload-bg w-20 h-28 md:w-24 md:h-32 rounded-lg border border-dashed border-black border-2"
                            ></div>
                        }
                        <div className="photo-upload-plus-bg text-white font-bold text-3xl w-8 h-8 rounded-full flex items-center justify-center absolute top-0 right-0" onClick={(selectedImage) ? handleRemoveImage : () => null}>
                            {
                                selectedImage
                                ? <WhiteX/>
                                : <PlusSign/>
                            }
                        </div>
                        <CroppingModal
                            isOpen={modalOpen}
                            closeModal={closeModal}
                            image={tmpImage}
                            onCropConfirm={handleConfirmCrop}
                        />
                    </div>
                :   <div className="w-24 h-32 md:w-28 md:h-36 pt-3 relative photo-upload-container hover:cursor-pointer" onClick={(!selectedImage) ? handleComponentClick : () => null}>
                        {
                            selectedImage
                            ? <div 
                                className="photo-upload-bg w-20 h-28 md:w-24 md:h-32 rounded-lg shadow-md"
                                style={{
                                    backgroundImage: `url(${selectedImage})`,
                                    backgroundSize: 'cover',
                                }}
                            ></div>
                            : <div 
                                className="photo-upload-bg w-20 h-28 md:w-24 md:h-32 rounded-lg border border-dashed border-black border-2"
                            ></div>
                        }
                        <div className="photo-upload-plus-bg text-white font-bold text-3xl w-8 h-8 rounded-full flex items-center justify-center absolute top-0 right-0" onClick={(selectedImage) ? handleRemoveImage : () => null}>
                            {
                                selectedImage
                                ? <WhiteX/>
                                : <PlusSign/>
                            }
                        </div>
                        <input
                            type="file"
                            accept="image/jpeg, image/png"
                            onChange={handleImageUpload}
                            className="hidden"
                            ref={fileInputRef}
                        />
                        <CroppingModal
                            isOpen={modalOpen}
                            closeModal={closeModal}
                            image={tmpImage}
                            onCropConfirm={handleConfirmCrop}
                        />
                    </div>
            }
        </>
    );
};

export default FakePhotoUpload;