import React from 'react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { Loader, ThemeProvider } from '@aws-amplify/ui-react';

interface LivenessCheckProps {
  onFailure: () => void;
  onSuccess: () => void;
}

const TestLivenessCheck:React.FC<LivenessCheckProps> = ({ onFailure, onSuccess }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [createLivenessApiData, setCreateLivenessApiData] = React.useState<{
    sessionId: string;
  } | null>(null);

  React.useEffect(() => {
    // const fetchCreateLiveness: () => Promise<void> = async () => {
    //   const myHeaders = new Headers();
    //   myHeaders.append("Authorization", authToken);
    //   const requestOptions: RequestInit = {
    //       method: 'GET',
    //       headers: myHeaders,
    //       redirect: 'follow'
    //   };
    //   const response = await fetch(
    //     `https://api.ishemine.com/evals/liveness/`, requestOptions
    //   );
    //   const data = await response.json();

    //   setCreateLivenessApiData(data);
    //   setLoading(false);
    // };

    // fetchCreateLiveness();
    setCreateLivenessApiData({sessionId: "lol"});
    setLoading(false);
  }, []);

  const handleAnalysisComplete: () => Promise<void> = async () => {
    if (!createLivenessApiData) return;
    const myHeaders = new Headers();
    // myHeaders.append("Authorization", authToken);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(createLivenessApiData);
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: raw
    };
    const response = await fetch(
      `https://api.ishemine.com/evals/liveness/`, requestOptions
    );
    const data = await response.json();

    if (data.success) {
      console.log('User is live');
      onSuccess();
    } else {
      console.log('User is not live');
      onFailure();
    }
  };

  return (
    <ThemeProvider>
      {(loading || !createLivenessApiData) ? (
        <Loader />
      ) : (
        <div className="mb-6">
          <FaceLivenessDetector
          sessionId={createLivenessApiData.sessionId}
          displayText={{
            instructionsHeaderHeadingText: "Let's Confirm It's Really You, Lovely 🌸",
            instructionsHeaderBodyText: "To ensure the safety and comfort of all our wonderful women here, we just need to do a quick check to make sure you’re a real, lovely lady. Simply follow the on-screen instructions, and you’ll be part of our community in a blink! 🌈 We value your privacy – we do NOT save your face, and it's only used for this verification. If anything feels off or if there’s a glitch, please drop us a message at help@ishemine.com. We’re here to assist you! 💖",
          }}
          region="us-east-1"
          onAnalysisComplete={handleAnalysisComplete}
          onError={(error) => {
            console.error(error);
          }}
        />
        </div>
      )}
    </ThemeProvider>
  );
}

export default TestLivenessCheck;