import React from 'react';
import X from '../Assets/white-x.png';


const WhiteX = () => {
    return (
        <img src={X} alt="Close" className="h-3" />
    );
};

export default WhiteX;