import React, { useState } from 'react';
import Close from './Close';

interface ChatInstPopupProps {
    setShowModal: (show: boolean) => void;
}

const ChatInstPopup: React.FC<ChatInstPopupProps> = ({ setShowModal }) => {
    return (
        <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-FFACAC p-6 rounded-lg text-black max-w-lg mx-4 border border-red-400 border-2">
                {/* Close button */}
                <button
                    className="absolute top-4 right-4 text-xl font-bold"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    <Close />
                </button>
                <div className="text-center">
                    <h2 className="mb-4 text-xl font-bold dela-gothic">Guidelines for Chat</h2>
                    <div className="text-left mb-8">
                        <h3 className="font-bold">1. Content</h3>
                        <p className="pl-4">a. <strong>Be clear</strong> in your messages. Written text can be easily misunderstood.</p>
                        
                        <h3 className="font-bold">2. Safety</h3>
                        <p className="pl-4">a. <strong>Feel unsafe?</strong> Disconnect or report immediately.</p>
                        <p className="pl-4">b. <strong>Don't share</strong> sensitive info, even if it's not identifying.</p>
                        
                        <h3 className="font-bold">3. Respect</h3>
                        <p className="pl-4">a. Treat the other with empathy and understanding.</p>
                        <p className="pl-4">b. <strong>Always be respectful.</strong> Hate speech and bullying are not allowed.</p>
                    </div>
                    <p className="text-left font-bold">Note: You'll receive text notifications from IsHeMine.com when the other person responds. All chats are completely anonymous and no personal information will be shared with the other person.</p>
                </div>
            </div>
        </div>
    );
}

export default ChatInstPopup;
