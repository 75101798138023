import React, { useState, useEffect, useRef, createRef } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer';
import LightLogo from '../Components/LightLogo';
import Post from '../Components/Post';
import { PostTypes } from './types';
import { ChevronUp, ChevronDown } from 'react-feather';
import { BsChat } from 'react-icons/bs';
import { FiXCircle } from 'react-icons/fi';

dayjs.extend(relativeTime);

interface FakeResultsProps {
    otherGirlPhotos: string[];
    demoPhotos: string[];
}

const FakeResults:React.FC<FakeResultsProps> = ({ otherGirlPhotos, demoPhotos }) => {
  const navigate = useNavigate();
  const [showDisclaimer, setShowDisclaimer] = useState(true); 
  const [selectedPhotos, setSelectedPhotos] = useState<string[]>(demoPhotos);

  const [textareaValue, setTextareaValue] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textareaLineCount, setTextareaLineCount] = useState(2);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showMoreAttributes, setShowMoreAttributes] = useState(false);
  const postRefs = useRef<React.RefObject<HTMLDivElement>[]>([]);
  postRefs.current = otherGirlPhotos.map((_, i) => postRefs.current[i] ?? createRef());
  // Sample data structure for alternate matches
  const [alternateMatches, setAlternateMatches] = useState([
    { url: otherGirlPhotos[0], probability: 90 },
    { url: otherGirlPhotos[0], probability: 85 },
    { url: otherGirlPhotos[0], probability: 85 },
    // Add more matches as needed
  ]);

  const [showAlternativeMatchesModal, setShowAlternativeMatchesModal] = useState(false);

  const tags = [
    "Met on Tinder", "Met on Hinge", "Met on Bumble", 
    "Met on other dating app", "Met IRL", "Long Distance",
    "High School Sweethearts", "College Romance", "Workplace Crush",
    "Unexpected Connection", "Friends First", "Adventure Buddies"
  ];

  // New state for ratings
  const [ratings, setRatings] = useState<Record<string, number>>({
    humor: 5,
    courtesy: 5,
    honesty: 5,
    intelligence: 5,
    appearance: 5,
    empathy: 5,
    chemistry: 5,
    punctuality: 5,
    communication: 5,
    ambition: 5,
    adventure: 5,
    compatibility: 5,
  });

  const [ratingSelected, setRatingSelected] = useState<Record<string, boolean>>({
    humor: false,
    courtesy: false,
    honesty: false,
    intelligence: false,
    appearance: false,
    empathy: false,
    chemistry: false,
    punctuality: false,
    communication: false,
    ambition: false,
    adventure: false,
    compatibility: false,
  });

  const attributeEmojis: Record<string, string> = {
    humor: '😂',
    courtesy: '🙏',
    honesty: '🤝',
    intelligence: '🧠',
    appearance: '🌟',
    empathy: '💖',
    chemistry: '💥',
    punctuality: '⏰',
    communication: '🗣️',
    ambition: '🚀',
    adventure: '🌍',
    compatibility: '❤️',
  };  

  // Handle change on slider
  const handleRatingChange = (attribute: string, value: number) => {
    setRatings(prevRatings => ({ ...prevRatings, [attribute]: value }));
  };


  const toggleTag = (tag: string) => {
    setSelectedTags(prevTags =>
      prevTags.includes(tag) ? prevTags.filter(t => t !== tag) : [...prevTags, tag]
    );
  };

  const calculateContentHeight = (ta: HTMLTextAreaElement) => {
    const computedStyle = window.getComputedStyle(ta);
    const origHeight = computedStyle.height;
    let overflow = computedStyle.overflow;
    
    // Temporarily change the styles for measurement
    ta.style.overflow = 'hidden';
    ta.style.height = 'auto';
  
    let contentHeight = ta.scrollHeight;
  
    // Check if content is overflowing
    if (ta.offsetHeight < contentHeight) {
      let adjustedHeight = contentHeight;
      while (ta.offsetHeight < ta.scrollHeight) {
        adjustedHeight++;
        ta.style.height = `${adjustedHeight}px`;
      }
      contentHeight = adjustedHeight;
    }
  
    // Reset the styles
    ta.style.height = origHeight;
    ta.style.overflow = overflow;
  
    return contentHeight;
  };
  
  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // TODO: fix this line counting system.
    setTextareaValue(event.target.value);
  
    if (textareaRef.current) {
      const lineHeight = parseInt(window.getComputedStyle(textareaRef.current).lineHeight, 10);
      const height = calculateContentHeight(textareaRef.current);
      const lines = Math.ceil(height / lineHeight);
  
      setTextareaLineCount(lines);
    }
  };  

  const calculatePaddingBottom = () => {
    const lineCountBeyondTwo = textareaLineCount > 2 ? textareaLineCount - 2 : 0;
    return `${lineCountBeyondTwo * 20}px`; // Assuming a line height of 20px
  };

  const handleDisclaimerClose = () => {
    document.body.style.overflow = 'auto'; // Re-enable scrolling when the disclaimer is closed
    setShowDisclaimer(false);
  }

  useEffect(() => {
    if (showDisclaimer) {
      document.body.style.overflow = 'hidden';
    }

    // Re-enable scrolling when the component is unmounted
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showDisclaimer]);

  const removePhoto = (index: number) => {
    setSelectedPhotos(selectedPhotos.filter((_, i) => i !== index));
  }

  const handleSubmitConfession = () => {
    setShowConfirmationModal(true);
  };

  const handleCloseModal = () => {
    setShowConfirmationModal(false);
  };

  const handleRatingSelect = (attribute: string) => {
    setRatingSelected(prev => ({ ...prev, [attribute]: !prev[attribute] }));
  };

  const handleShowMoreClick = () => {
    setShowMoreAttributes(!showMoreAttributes);
  };

  // Rating Sliders Section
  const renderRatingSlider = (attribute: string, index: number) => {
    if (index > 1 && !showMoreAttributes) return null;

    return (
      <div key={attribute} className="mb-4">
        <label htmlFor={attribute} className="block mb-2 capitalize font-semibold text-left">{attributeEmojis[attribute]} {attribute}:</label>
        <div className="flex items-center">
          <button
            onClick={() => handleRatingSelect(attribute)}
            className={`mr-2 px-2 py-1 text-sm font-semibold rounded-full ${ratingSelected[attribute] ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-800'}`}
          >
            {ratingSelected[attribute] ? 'Selected' : 'Select'}
          </button>
          <input
            type="range"
            id={attribute}
            name={attribute}
            min="1"
            max="10"
            value={ratings[attribute]}
            onChange={(e) => handleRatingChange(attribute, parseInt(e.target.value))}
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer slider-thumb"
            disabled={!ratingSelected[attribute]}
          />
          <span className={`ml-2 text-sm font-semibold ${!ratingSelected[attribute] ? 'text-gray-400' : ''}`}>
            {ratingSelected[attribute] ? ratings[attribute] : '-'}
          </span>
        </div>
      </div>
    );
  }; 
  
  const displaySelectedRatings = () => {
    const selectedRatings = Object.keys(ratings).filter(attribute => ratingSelected[attribute]);
    if (selectedRatings.length === 0) {
      return null;
    }
    return selectedRatings.map(attribute => (
      <div key={attribute} className="mb-2">
        <span className="font-semibold">{attributeEmojis[attribute]} {attribute}: </span>
        <span>{ratings[attribute]}</span>
      </div>
    ));
  };

  const scrollToPost = (index: number) => {
    postRefs.current[index]?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const TLDRTimeLine = () => {
    return (
      <div className="mt-8 mb-4 px-4">
        <h3 className="text-lg font-bold mb-2 text-pink-500">The TL;DR 💫</h3>
        <div className="border-l-2 border-pink-300 pl-4">
          {otherGirlPhotos.map((url, index) => {
            const date = dayjs().subtract(index * 30, 'day').format('MM/DD/YY');
            let timelineText;
  
            // TODO: this should be based on the post type for Results, not randomized based on index
            switch (index % 3) {
              case 0:
                timelineText = `Uploaded to IsHeMine.com on ${date}`;
                break;
              case 1:
                timelineText = `Spotted on Tinder on ${date}`;
                break;
              case 2:
                timelineText = `Captured from Instagram on ${date}`;
                break;
              default:
                timelineText = `Posted on ${date}`;
            }
  
            return (
              <div key={index} className="mb-2 flex items-center">
                <div className="rounded-full bg-pink-500 h-4 w-4 flex items-center justify-center -ml-2">
                  <div className="rounded-full bg-white h-2 w-2"></div>
                </div>
                <button 
                  onClick={() => scrollToPost(index)}
                  className="ml-2 hover:text-pink-600 transition duration-300 ease-in-out"
                >
                  {timelineText}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }; 

  const handleDefinitelyNotHimClick = () => {
    setShowAlternativeMatchesModal(true);
  };
  
  return (
      <div className="flex flex-col bg-gray-900 relative">
        {/* Disclaimer modal */}
        {showDisclaimer && (
          <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 p-8">
            <div className="bg-red-100 p-4 rounded-lg text-center border border-blue-200 w-full md:w-1/2 lg:w-1/3">
              <h2 className="font-bold text-xl mb-2 dela-gothic">Disclaimer</h2>
              <p className="mb-4 text-left">Our facial detection algorithm operates with ~95% confidence. This means, if your man is in our database, he <b>will</b> show up in this search. We have found <b>8 matches</b> in total.</p>
              <p className="mb-4 text-left">If you believe the main match shown is not the correct person, <b>please click <span className="underline text-red-700">Definitely not him?</span></b> to view other possible matches.</p>
              <p className="mb-8 text-left">If you find individual uploads or posts that seem out of place, <b>click <span className="underline text-red-700">Not Him?</span></b> to report these specific instances as <b>not your boyfriend.</b></p>
              <button className="bg-red-600 text-white px-4 py-2 rounded text-sm font-bold transition duration-300 ease-in-out transform hover:scale-105" onClick={handleDisclaimerClose}>I understand!</button>
            </div>
          </div>
        )}
        <div className="min-h-screen">
          <header className="fixed p-4 z-10 w-full bg-gray-900">
            <div className="container mx-auto flex items-center justify-between hover:cursor-pointer" onMouseUp={() => navigate("/")}>
              <LightLogo/>
            </div>
          </header>
          {/* <div className="h-px bg-gray-500"></div> */}
          <main className="flex-1 pt-20 p-4 text-center text-white">
            <h1 className="dela-gothic text-xl mb-1">Hey girly...<br/>We found a match. 🙊</h1>
            <h3 className="text-sm">We're here for you.</h3>
            <div className="flex justify-center flex-col w-full md:w-2/3 lg:w-1/2 mx-auto">
              <div className="mt-4 mb-4">
                <div className="p-4 bg-gray-700 rounded-lg shadow-lg max-w-md mx-auto">
                  <h2 className="font-bold text-lg mb-2 italic">His first upload (Nov 3, 2023)</h2>
                  <img src={otherGirlPhotos[0]} alt="First upload" className="w-64 h-auto rounded-lg mb-4 mx-auto"/>
                  <div className="flex flex-col gap-4 text-base">
                  <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl flex items-center justify-center gap-2 mb-2 transition duration-300 ease-in-out">
                    <BsChat className="w-5 h-5" />
                    Chat with the Uploader
                  </button>
                  <button 
                    className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-xl flex items-center justify-center gap-2 transition duration-300 ease-in-out"
                    onClick={handleDefinitelyNotHimClick}
                    >
                    <FiXCircle className="w-5 h-5" />
                    Definitely not him?
                  </button>
                  </div>
                </div>
              </div>
              {showAlternativeMatchesModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-8 text-black">
                  <div className="bg-white p-6 rounded-lg text-center w-full md:w-1/2 lg:w-1/3 relative">
                    {/* Close Button */}
                    <button
                      className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 font-bold text-lg"
                      onClick={() => setShowAlternativeMatchesModal(false)}
                    >
                      &times;
                    </button>

                    <h3 className="text-xl font-semibold mb-4">Other Possible Matches</h3>
                    <div className="flex flex-wrap justify-center gap-4 mb-4">
                      {alternateMatches.map((match, index) => (
                        <div key={index} className="text-center">
                          <img src={match.url} alt={`Match ${index + 1}`} className="w-16 h-16 object-cover rounded-lg mb-2 mx-auto" />
                          <p className="text-sm">{match.probability}% Likelihood</p>
                        </div>
                      ))}
                    </div>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg font-bold transition duration-300 ease-in-out transform hover:bg-blue-600"
                      onClick={() => setShowAlternativeMatchesModal(false)}
                    >
                      None of these are him
                    </button>
                  </div>
                </div>
              )}
              <TLDRTimeLine />
              {/* Experience Sharing Title */}
              <h2 className="text-2xl font-bold mb-3 mt-2 text-left w-full">Share Your Experience</h2>
              {/* What's on your mind section */}
              <div className="w-full relative">
                <div className="relative w-full">
                <textarea
                  ref={textareaRef}
                  className="w-full p-3 bg-gray-800 text-white rounded-lg relative z-0"
                  placeholder="Confess an experience... (It's anonymous.)"
                  onChange={handleTextChange}
                  style={{ minHeight: '180px', height:'180px', paddingBottom: calculatePaddingBottom() }}
                  value={textareaValue}
                ></textarea>
                
                {/* Photos container positioned inside the textarea */}
                <div className="absolute left-0 bottom-0 pl-3 pb-8 flex flex-wrap gap-2 z-2">
                  {
                    selectedPhotos.slice(0, 3).map((photo, index) => (
                      <div key={photo} className="relative w-16 h-16">
                        <img src={photo} alt={`Selected for upload ${index + 1}`} className="object-cover rounded-lg" />
                        <button
                          className="absolute top-0 right-0 bg-black text-white rounded-full p-1 hover:bg-red-600 transition duration-150 ease-in-out"
                          onClick={() => removePhoto(index)}
                          style={{ width: '22px', height: '22px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                          &times;
                        </button>
                      </div>
                    ))
                  }

                  {
                    selectedPhotos.length > 3 &&
                    <div className="relative w-16 h-16 bg-gray-300 flex items-center justify-center rounded-lg text-black">
                      +{selectedPhotos.length - 3} others
                    </div>
                  }
                </div>
                </div>
              </div>
              {/* Rating Sliders Section */}
              {Object.keys(ratings).map((attribute, index) => renderRatingSlider(attribute, index))}

              {Object.keys(ratings).length > 2 && (
                <button
                  onClick={handleShowMoreClick}
                  className="flex items-center text-blue-400 text-sm underline pb-2 mt-2 justify-center font-bold"
                >
                  {showMoreAttributes ? (
                    <>
                      Show Less <ChevronUp className="w-4 h-4 ml-1" />
                    </>
                  ) : (
                    <>
                      Show More <ChevronDown className="w-4 h-4 ml-1" />
                    </>
                  )}
                </button>
              )}
              {/* Tags Section */}
              <div className="flex flex-wrap justify-center gap-2 p-4 mx-auto w-full">
                {tags.map(tag => (
                  <button
                    key={tag}
                    className={`px-3 py-1 text-sm font-semibold rounded-full border-2 
                                ${selectedTags.includes(tag) ? 'border-green-300 bg-green-100 text-green-700' : 'border-white text-white'}
                                transition-colors duration-300 transform hover:bg-green-200 hover:text-green-800`}
                    onClick={() => toggleTag(tag)}
                  >
                    {tag}
                  </button>
                ))}
              </div>
              {/* Share Experience Button */}
              <div className="flex justify-center p-4">
                <button
                  className="bg-green-500 text-white px-6 py-2 rounded-lg text-lg font-bold shadow-lg transform transition duration-300 ease-in-out hover:bg-green-600"
                  onClick={handleSubmitConfession}
                >
                  Share Experience(s) / Photos
                </button>
              </div>

              {/* Confirmation Modal */}
              {showConfirmationModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-8 text-black">
                <div className="bg-white p-6 rounded-lg text-center w-full md:w-1/2 lg:w-1/3 max-h-[80vh] overflow-y-auto">
                  <h3 className="text-xl font-semibold mb-4">Review Your Submission</h3>
                  <p className="text-gray-700 mb-4 whitespace-pre-wrap">{textareaValue}</p>
                  <div className="flex flex-wrap justify-center gap-4 mb-4">
                    {selectedPhotos.map((photo, index) => (
                      <img key={photo} src={photo} alt={`Attachment ${index + 1}`} className="w-16 h-16 object-cover rounded-lg" />
                    ))}
                  </div>
                  {/* Display Selected Tags */}
                  <div className="flex flex-wrap justify-center gap-2 mb-4">
                    {selectedTags.map(tag => (
                      <span key={tag} className="px-3 py-1 text-sm font-semibold rounded-full bg-green-100 text-green-700">
                        {tag}
                      </span>
                    ))}
                  </div>
                  {/* Display selected ratings */}
                  {displaySelectedRatings() && (
                    <div className="mb-4">
                      <h4 className="text-lg font-semibold mb-2">Selected Ratings:</h4>
                      {displaySelectedRatings()}
                    </div>
                  )}
                  <div className="flex flex-col md:flex-row gap-4 justify-center mb-4">
                    <button
                      className="bg-red-500 text-white px-4 py-2 rounded-lg font-bold transition duration-300 ease-in-out transform hover:bg-red-600"
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-green-500 text-white px-4 py-2 rounded-lg font-bold transition duration-300 ease-in-out transform hover:bg-green-600"
                      onClick={() => { /* Logic to handle actual submission */ }}
                    >
                      Confirm Submission
                    </button>
                  </div>
                </div>
              </div>              
              )}
              {/* <textarea
                className="w-full p-3 bg-gray-800 text-white rounded-lg mb-4"
                placeholder="Share your thoughts..."
                value={userInput}
                onChange={handleInputChange}
              ></textarea>
              <div className="flex gap-4 justify-center">
                <button
                  className={`bg-blue-500 text-white px-4 py-2 rounded text-sm font-bold ${isUploadingPhoto ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={handlePhotoUploadClick}
                  disabled={isUploadingPhoto}
                >
                  {isUploadingPhoto ? 'Uploading...' : 'Upload my photos'}
                </button>
                <button
                  className="bg-purple-500 text-white px-4 py-2 rounded text-sm font-bold"
                  onClick={handleConfessClick}
                >
                  Confess an experience
                </button>
              </div> */}
              {otherGirlPhotos ? 
                <div className="text-left mt-4 w-full md:w-2/3 mx-auto">
                    {otherGirlPhotos.map((url: string, idx: number) => {
                        if (url) {
                        // Generate a random number of days between 1 and 365
                        const randomDays = Math.floor(Math.random() * 365) + 1;
                        // Subtract the random number of days from the current date
                        const fakeDate = dayjs().subtract(randomDays, 'day').format('YYYY-MM-DD HH:mm:ss');
                        return (
                          <div ref={postRefs.current[idx]} key={idx} className="border-b-8 border-gray-700">
                                <Post
                                    id='1'
                                    postType={PostTypes.SEARCH}
                                    onChatClick={() => { navigate('/ugc/chat') }}
                                    full={true}
                                    images={[url]}
                                    initialRating={82}
                                    elapsedTime={dayjs(fakeDate).fromNow()}
                                    caption={"Spotted: Our state-of-the-art love radar just caught a wild boyfriend in his natural habitat - the gym! Reportedly, he was flexing more than just his muscles. Stay tuned for more juicy updates in this romantic saga."}
                                    authToken={''}
                                    initialComments={[]}
                                />
                                <Post
                                  id='2'
                                  postType={PostTypes.TINDER}
                                  onChatClick={() => { console.log("no chat for Tinder submissions") }}
                                  full={true}
                                  images={[url]}
                                  initialRating={34}
                                  elapsedTime={dayjs(fakeDate).fromNow()}
                                  caption={""}
                                  authToken={''}
                                  initialComments={[]}
                                />
                                <Post
                                  id='3'
                                  postType={PostTypes.CONFESSION}
                                  onChatClick={() => { navigate('/ugc/chat') }}
                                  full={true}
                                  images={[url]}
                                  initialRating={23}
                                  elapsedTime={dayjs(fakeDate).fromNow()}
                                  caption={"HEAR ME OUT.....this guy is actually awesome!!! best. ice. skater. ever."}
                                  authToken={''}
                                  initialComments={[]}
                                />
                            </div>
                        );
                        }
                    })}
                </div> : <div className="h-px bg-gray-500">Loading...</div> // TODO: loading animation!
              }
            </div>
          </main>
        </div>
        <Footer/>
      </div>
  );
}

export default FakeResults;
