import React from 'react';

const JoinFB: React.FC = () => {
    return (
        <div className="flex justify-center items-center">
            <div className="mt-4 p-6 bg-white rounded-xl shadow-lg border border-cyan-300 space-y-4 w-full md:w-1/2 lg:w-1/3">
                <h1 className="text-center dela-gothic text-cyan-600 text-xl mb-4">Join Our FB Community</h1>
                <p className="text-sm text-left">
                    In today's world, understanding and navigating relationships can feel overwhelming. That's why we've created a safe space for discussion, support, and advice.
                </p>
                <p className="text-sm text-left mt-2">
                    If you're searching for <span className="font-bold text-cyan-500">insightful relationship support</span> or looking to share your own experiences, our community is the perfect place.
                </p>
                <a href="https://www.facebook.com/groups/1396228454264710/" target="_blank" rel="noopener noreferrer" className="dela-gothic text-cyan-600 block text-center text-sm md:text-base mt-4 py-2 px-4 rounded-md border border-cyan-500 hover:bg-cyan-100 transform transition duration-200 ease-in-out hover:scale-105">
                    🌱 Dive into our FB community 🌱
                </a>
            </div>
        </div>
    );
};

export default JoinFB;