import React, {useEffect} from 'react';
import Layout from "../Layout";
import { useNavigate } from "react-router-dom";

type DemoLandingProps = {
    setDemoState: React.Dispatch<React.SetStateAction<string>>;
    setOtherGirlPhotos: React.Dispatch<React.SetStateAction<string[]>>;
    setDemoPhotos: React.Dispatch<React.SetStateAction<string[]>>;
}

const DemoLanding:React.FC<DemoLandingProps> = ({ setDemoState, setOtherGirlPhotos, setDemoPhotos }) => {
    const navigate = useNavigate();

    useEffect(() => {
        setDemoState("");
        setOtherGirlPhotos([]);
        setDemoPhotos([]);
    }, []);

    return (
        <Layout>
            <h1 className="dela-gothic text-2xl">Dear Creators...</h1>
            <p className="m-4">Select one of the following workflows for product demonstration.</p>
            {/* Making the box a clickable div with role="button" */}
            <div className="flex flex-col items-center">
                <div 
                    className="w-full md:w-1/2 h-60 flex-shrink-0 bg-red-200 border border-4 border-red-500 rounded-xl shadow-md flex items-center justify-center transition-transform transform hover:bg-red-300 cursor-pointer"
                    role="button"
                    onClick={() => {
                        setDemoState("loyal");
                        navigate("/ugc/loyalty-inst");
                    }}
                >
                    <p className="dela-gothic text-cyan-500 text-3xl">He's<br/><span className="text-blue-600">LOYAL 👼</span></p>
                </div>

                <div 
                    className="w-full md:w-1/2 h-60 mt-4 flex-shrink-0 bg-red-200 border border-4 border-red-500 rounded-xl shadow-md flex items-center justify-center transition-transform transform hover:bg-red-300 cursor-pointer"
                    role="button"
                    onClick={() => {
                        setDemoState("cheating");
                        navigate("/ugc/cheating-inst");
                    }}
                >
                    <p className="dela-gothic text-cyan-500 text-3xl">He's<br/><span className="text-red-600">CHEATING 😡</span></p>
                </div>
            </div>
        </Layout>
    );
};

export default DemoLanding;
