import React, { ReactNode } from 'react';
import './Layout.css';
import Logo from './Components/Logo';
import { useNavigate } from 'react-router-dom';
import Footer from './Components/Footer';

interface LayoutProps {
  children: ReactNode;
}

const Layout:React.FC<LayoutProps> = ({children}) => {
  const navigate = useNavigate();
  
  return (
      <div className="IsHeMine flex flex-col">
        <div className="min-h-screen">
          <header className="p-4">
            <div className="container mx-auto flex items-center justify-between hover:cursor-pointer" onMouseUp={() => navigate("/")}>
              <Logo />
            </div>
          </header>
          {/* <div className="h-px bg-gray-500"></div> */}
          <main className="flex-1 p-4 text-center">
            {children}
          </main>
        </div>
        <Footer/>
      </div>
  );
}

export default Layout;
