import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../Components/Icon';
import './Pages.css';
import BlueSubmitBtn from '../Components/BlueSubmitBtn';
import parsePhoneNumber from 'libphonenumber-js';
import ReCAPTCHA from "react-google-recaptcha";

type VerifyProps = {
    phoneNumber: string;
    setAuthToken: (value: string) => void;
}

const VerifyPhone: React.FC<VerifyProps> = ({ phoneNumber, setAuthToken }) => {
    const navigate = useNavigate();
    const [value, setValue] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    const [numberEntered, setNumberEntered] = useState<boolean>(false);
    const [continuing, setContinuing] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const recaptchaRef = React.createRef<ReCAPTCHA>();
    const recaptchaRef2 = React.createRef<ReCAPTCHA>();

    const [recaptchaToken, setRecaptchaToken] = useState<string>("");
    const [recaptchaToken2, setRecaptchaToken2] = useState<string>("");

    function toggleLoading() {
        setIsLoading((prevLoading) => !prevLoading);
    }

    window?.visualViewport?.addEventListener('resize', _ => window.scrollTo(0, 0));

    function handleContinue() {
        if (recaptchaRef2 && recaptchaRef2.current) {
            recaptchaRef2.current.execute();
            setContinuing(true);
        } else {
            console.log("Recaptcha ref not found");
        }
    }

    useEffect(() => {
        if (recaptchaToken2 && continuing) {
            console.log("continuing");
            toggleLoading();

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const parsedPhoneNumber = parsePhoneNumber(phoneNumber, "US");

            const urlencoded = new URLSearchParams();
            urlencoded.append("mobile", parsedPhoneNumber ? parsedPhoneNumber.number : "+1");
            urlencoded.append("token", value);
            urlencoded.append("captcha", recaptchaToken2);

            const requestOptions: RequestInit = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
        
            toggleLoading();

            fetch("https://api.ishemine.com/auth/token/", requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.status}.`);
                }
                return response.text();
            })
            .then(result => {
                console.log(result);
                toggleLoading();
                setAuthToken(`Token ${JSON.parse(result).token}`);
                navigate("/liveness");
            })
            .catch(error => {
                console.log('error', error);
                setErrorMessage("Incorrect code. Please try again.");
                setIsLoading((_) => false);
                setValue('');
                setNumberEntered(false);
            });
        }
    }, [continuing, recaptchaToken2]);


    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 6) {
            setValue(event.target.value);
            setNumberEntered(event.target.value.length === 6);
        }
    };

    useEffect(() => {
        if (numberEntered) handleContinue();
    }, [numberEntered]);

    const renderCharacters = () => {
        const maxCharacters = 6; // Set this based on your requirement
        const characters = [];

        for (let i = 0; i < maxCharacters; i++) {
            const characterValue = value[i] || '';
            const isActive = i === value.length;

            characters.push(
                <div key={i} className={`character ${isActive ? 'character--selected' : 'character--inactive'}`}>
                    {characterValue}
                </div>
            );
        }

        return characters;
    };

    function handleResendCode() {
        if (recaptchaRef && recaptchaRef.current) {
            recaptchaRef.current.execute();
        } else {
            console.log("Recaptcha ref not found");
        }
    }

    useEffect(() => {
        if (recaptchaToken && phoneNumber) {
            toggleLoading();
    
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const parsedPhoneNumber = parsePhoneNumber(phoneNumber, "US");

            const urlencoded = new URLSearchParams();
            urlencoded.append("mobile", parsedPhoneNumber ? parsedPhoneNumber.number : "+1");
            urlencoded.append("captcha", recaptchaToken);
            console.log(recaptchaToken);
        
            const requestOptions: RequestInit = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
        
            fetch("https://api.ishemine.com/auth/mobile/", requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.status}`);
                }
                return response.text();
            })
            .then(result => {
                console.log(result);
                toggleLoading();
                setErrorMessage("Code has been resent. Please check your phone before resending."); // Let the user know the code was resent
            })
            .catch(error => {
                console.log('error', error);
                setErrorMessage("An error occurred while resending the code. Please try again.");
                toggleLoading();
            });
        }
    }, [recaptchaToken, phoneNumber]);

    return (
        <div className="w-full h-screen verify-dark-bg text-center overflow-hidden">
            <div className="center-container">
                <div className="flex flex-col items-center pt-20 pd:mt-12 pb-8 md:pb-4">
                    {/* Wrap Icon in a container */}
                    <Icon />
                </div>
                <h1 className="text-2xl font-medium leading-6 text-white dela-gothic justify-center">Enter Verification Code</h1>
                <div className="w-2/3 md:w-1/3 lg:w-1/5 mt-8 font-bold">
                    <div className="container" onClick={() => {
                        inputRef.current?.focus();
                    }}>
                        {renderCharacters()}
                    </div>
                    <input
                        type="text"
                        className="hidden-input"
                        value={value}
                        onChange={handleChange}
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        maxLength={6}
                        ref={inputRef}
                    />
                    <div className="text-sm text-gray-200 text-left mt-3 font-normal">
                        Didn't get it? <button className="underline focus:outline-none" onClick={handleResendCode}>Resend code.</button>
                    </div>
                </div>
                {/* Display the error message if it's not empty */}
                {errorMessage && (
                    <div className="w-full text-red-500 text-center mt-12">
                        {errorMessage}
                    </div>
                )}
                <div className="absolute bottom-5 w-full md:w-3/4 lg:w-1/2 p-4">
                    <BlueSubmitBtn isLoading={isLoading} numberEntered={numberEntered} handleClick={handleContinue} text={"Continue"}/>
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6LcdznYnAAAAAFicIrLfu9EJuODfhkIScxm1_nCS"
                  onChange={(token: string | null) => token ? setRecaptchaToken(token) : null}
                />
                <ReCAPTCHA
                  ref={recaptchaRef2}
                  size="invisible"
                  sitekey="6LcdznYnAAAAAFicIrLfu9EJuODfhkIScxm1_nCS"
                  onChange={(token: string | null) => token ? setRecaptchaToken2(token) : null}
                />
            </div>
        </div>
    );
}

export default VerifyPhone;