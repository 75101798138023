import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "./Loading";
import LightLogo from "../Components/LightLogo";
import { BsChat } from "react-icons/bs";
import { FiXCircle } from "react-icons/fi";
import Post from "../Components/Post";
import { PostTypes } from "./types";
import dayjs from "dayjs";
import Footer from "../Components/Footer";
import TLDRTimeLine from "../Components/TLDRTimeLine";
import { tags, attributeEmojis } from "./types";
import { ChevronDown, ChevronUp } from "react-feather";

interface Attachment {
    photo: string;
}

export interface Confession {
    id: string;
    user: string;
    attachments: Attachment[];
    content: string;
    created_at: string;
    replies: Confession[];
    reply_to: string | null;
    num_upvotes: number;
    num_downvotes: number;
    interaction_type: string | null;
    type: "confession";
}

export interface Query {
    id: string;
    creator_id: string;
    created_at: string;
    photos: Attachment[];
    type: "query";
}

export interface TinderProfile {
    id: string;
    photos: Attachment[];
    name: string;
    bio: string;
    created_at: string;
    type: "tinder_profile";
}

export interface Subject {
    user_id: string;
    preview_photo: string;
    confessions: Confession[];
    queries: Query[];
    tinder_profiles: TinderProfile[];
}

export const makeTimeline = (subject: Subject) => {
    return [
        ...subject.confessions,
        ...subject.queries,
        ...subject.tinder_profiles
    ].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
}

interface SubjectDetailPageProps {
    authToken: string;
    queryPhotos: string[];
    ranked_matches: any[]
}

const SubjectDetailPage:React.FC<SubjectDetailPageProps> = ({authToken, queryPhotos, ranked_matches}) => {

    const navigate = useNavigate();

    const { user_id , query_id} = useParams();
    const [subject, setSubject] = useState<Subject | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(user_id ? null : "Need to include an id in the URL!");
    const [selectedPhotos, setSelectedPhotos] = useState<string[]>(queryPhotos);
    const [showMoreAttributes, setShowMoreAttributes] = useState(false);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    
    const [textareaValue, setTextareaValue] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [textareaLineCount, setTextareaLineCount] = useState(2);
    const postRefs = useRef<React.RefObject<HTMLDivElement>[]>([]);

    const [showAlternativeMatchesModal, setShowAlternativeMatchesModal] = useState(false);
    const [showDisclaimer, setShowDisclaimer] = useState(true);

    const [ratings, setRatings] = useState<Record<string, number>>({
        humor: 5,
        courtesy: 5,
        honesty: 5,
        intelligence: 5,
        appearance: 5,
        empathy: 5,
        chemistry: 5,
        punctuality: 5,
        communication: 5,
        ambition: 5,
        adventure: 5,
        compatibility: 5,
    });
    
    const [ratingSelected, setRatingSelected] = useState<Record<string, boolean>>({
        humor: false,
        courtesy: false,
        honesty: false,
        intelligence: false,
        appearance: false,
        empathy: false,
        chemistry: false,
        punctuality: false,
        communication: false,
        ambition: false,
        adventure: false,
        compatibility: false,
    });

    const handleDisclaimerClose = () => {
        document.body.style.overflow = 'auto'; // Re-enable scrolling when the disclaimer is closed
        setShowDisclaimer(false);
    }

    const getSubjectHistory = (token: string) => {
        const headers = new Headers();
        headers.append("Authorization", token)

        return fetch(`https://api.ishemine.com/evals/subject/${user_id}/history/`)
            .then(response => response.json())
            .then(json => {
                return {
                    "preview_photo": json["preview_photo"],
                    "queries": json["queries"],
                }
            })
    }

    const getSubjectComments = (token: string): Promise<Confession[]> => {
        const headers = new Headers();
        headers.append("Authorization", token)
        return fetch(`https://api.ishemine.com/social/subject_comments/?subject=${user_id}`)
            .then(response => response.json())
    }

    const getTinderProfiles = (token: string): Promise<TinderProfile[]> => {
        const headers = new Headers();
        headers.append("Authorization", token);
        return fetch(`https://api.ishemine.com/evals/subject/${user_id}/tinder/`)
            .then(response => response.json())
    }

    const createChat = (query: Query) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", authToken);
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify({
          "other_user_id": query.creator_id,
          "avatar": query.photos.length > 0 ? query.photos[0].photo : null
        });
    
        var requestOptions: RequestInit = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
    
        fetch("https://api.ishemine.com/chat/get/", requestOptions)
            .then<[boolean, any]>(response => response.json().then(data => [response.ok, data]))
            .then((value: [boolean, any]) => {
                const [ok, result] = value;
                if (ok) {
                    navigate(`/chat/${result.id}`);
                }
            })
            .catch(error => alert(error));
    }

    useEffect(() => {
        if (!user_id) {
            return
        } else if (!subject) {
            getSubjectHistory(authToken).then(queriesAndPreviewPhotoData => {
                const _subject: Subject = {
                    user_id: user_id,
                    preview_photo: queriesAndPreviewPhotoData["preview_photo"],
                    queries: queriesAndPreviewPhotoData["queries"],
                    tinder_profiles: [],
                    confessions: []
                }
                setSubject(_subject);
                setLoading(false);
                postRefs.current = Array.from({length: (_subject.queries.length )}, (_, i) => postRefs.current[i] ?? createRef())
            }).catch(error => {
                setError(`Could not get history: ${error}`)
            })
        }
    }, [user_id, authToken]);

    useEffect(() => {
        if (subject) {
            if (!subject.confessions) {
                getSubjectComments(authToken).then(confessions => {
                    // creating a mapping between confession's id and the confession object
                    let idToConfession: { [id: string]: Confession } = {};
                    // creating a mapping between a confession's id and its replies
                    let idToReplies: { [id: string]: Confession[] } = {};

                    // populating the mappings
                    for(let confession of confessions) {
                        if (confession.reply_to === null) {
                            idToConfession[confession.id] = confession;
                        } else {
                            if (!(confession.reply_to in idToReplies)) {
                                idToReplies[confession.reply_to] = [];
                            }
                            idToReplies[confession.reply_to].push(confession);
                        }
                    }

                    // filling up the replies lists 
                    for(let id in idToConfession) {
                        if (id in idToReplies) {
                            idToConfession[id].replies = idToReplies[id];
                        }
                    }

                    // get final Confessions (with replies)
                    let finalConfessions: Confession[] = Object.values(idToConfession);

                    setSubject({
                        ...subject,
                        confessions: finalConfessions
                    })
                })
            } else if (!subject.tinder_profiles) {
                getTinderProfiles(authToken).then(json => {
                    setSubject({
                        ...subject,
                        tinder_profiles: json
                    })
                })
            }
            postRefs.current = Array.from({length: (subject.confessions.length + subject.tinder_profiles.length + subject.queries.length)}, (_, i) => postRefs.current[i] ?? createRef())
        }
    }, [subject, authToken])

    const handleDefinitelyNotHimClick = () => {
        // TODO: Display alternative matches modal fr
        setShowAlternativeMatchesModal(true);
    };

    const scrollToPost = (index: number) => {
        postRefs.current[index]?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const calculateContentHeight = (ta: HTMLTextAreaElement) => {
        const computedStyle = window.getComputedStyle(ta);
        const origHeight = computedStyle.height;
        let overflow = computedStyle.overflow;
        
        // Temporarily change the styles for measurement
        ta.style.overflow = 'hidden';
        ta.style.height = 'auto';
      
        let contentHeight = ta.scrollHeight;
      
        // Check if content is overflowing
        if (ta.offsetHeight < contentHeight) {
          let adjustedHeight = contentHeight;
          while (ta.offsetHeight < ta.scrollHeight) {
            adjustedHeight++;
            ta.style.height = `${adjustedHeight}px`;
          }
          contentHeight = adjustedHeight;
        }
      
        // Reset the styles
        ta.style.height = origHeight;
        ta.style.overflow = overflow;
      
        return contentHeight;
      };

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        // TODO: fix this line counting system.
        setTextareaValue(event.target.value);
        
        if (textareaRef.current) {
            const lineHeight = parseInt(window.getComputedStyle(textareaRef.current).lineHeight, 10);
            const height = calculateContentHeight(textareaRef.current);
            const lines = Math.ceil(height / lineHeight);
        
            setTextareaLineCount(lines);
        }
    };

    const calculatePaddingBottom = () => {
        const lineCountBeyondTwo = textareaLineCount > 2 ? textareaLineCount - 2 : 0;
        return `${lineCountBeyondTwo * 20}px`; // Assuming a line height of 20px
    };

    const removePhoto = (index: number) => {
        setSelectedPhotos(selectedPhotos.filter((_, i) => i !== index));
    }

    const handleRatingSelect = (attribute: string) => {
        setRatingSelected(prev => ({ ...prev, [attribute]: !prev[attribute] }));
    };

    const handleRatingChange = (attribute: string, value: number) => {
        setRatings(prevRatings => ({ ...prevRatings, [attribute]: value }));
    };

    const handleShowMoreClick = () => {
        setShowMoreAttributes(!showMoreAttributes);
    };

    const toggleTag = (tag: string) => {
        setSelectedTags(prevTags =>
            prevTags.includes(tag) ? prevTags.filter(t => t !== tag) : [...prevTags, tag]
        );
    };

    const handleCloseModal = () => {
        setShowConfirmationModal(false);
    };

    const handleSubmitConfession = () => {
        setShowConfirmationModal(true);
    };

    const confirmSubmission = () => {
        // TODO: Send to API (include ratings + tags)
    }

    const renderRatingSlider = (attribute: string, index: number) => {
        if (index > 1 && !showMoreAttributes) return null;
    
        return (
          <div key={attribute} className="mb-4">
            <label htmlFor={attribute} className="block mb-2 capitalize font-semibold text-left">{attributeEmojis[attribute]} {attribute}:</label>
            <div className="flex items-center">
              <button
                onClick={() => handleRatingSelect(attribute)}
                className={`mr-2 px-2 py-1 text-sm font-semibold rounded-full ${ratingSelected[attribute] ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-800'}`}
              >
                {ratingSelected[attribute] ? 'Selected' : 'Select'}
              </button>
              <input
                type="range"
                id={attribute}
                name={attribute}
                min="1"
                max="10"
                value={ratings[attribute]}
                onChange={(e) => handleRatingChange(attribute, parseInt(e.target.value))}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer slider-thumb"
                disabled={!ratingSelected[attribute]}
              />
              <span className={`ml-2 text-sm font-semibold ${!ratingSelected[attribute] ? 'text-gray-400' : ''}`}>
                {ratingSelected[attribute] ? ratings[attribute] : '-'}
              </span>
            </div>
          </div>
        );
    };
    
    const displaySelectedRatings = () => {
        const selectedRatings = Object.keys(ratings).filter(attribute => ratingSelected[attribute]);
        if (selectedRatings.length === 0) {
            return null;
        }
        return selectedRatings.map(attribute => (
            <div key={attribute} className="mb-2">
            <span className="font-semibold">{attributeEmojis[attribute]} {attribute}: </span>
            <span>{ratings[attribute]}</span>
            </div>
        ));
    };

    if (error) {
        return <h1>{error}</h1>
    } else if (loading || !subject) {
        return <Loading></Loading>
    } else {
        return (
            <div className="flex flex-col bg-gray-900 relative">
              {/* Disclaimer modal */}
              {showDisclaimer && (
                <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 p-8">
                  <div className="bg-red-100 p-4 rounded-lg text-center border border-blue-200 w-full md:w-1/2 lg:w-1/3">
                    <h2 className="font-bold text-xl mb-2 dela-gothic">Disclaimer</h2>
                    <p className="mb-4 text-left">Our facial detection algorithm operates with ~95% confidence. This means, if your man is in our database, he <b>will</b> show up in this search. We have found <b>{ranked_matches.length} matches</b> in total.</p>
                    <p className="mb-4 text-left">If you believe the main match shown is not the correct person, <b>please click <span className="underline text-red-700">Definitely not him?</span></b> to view other possible matches.</p>
                    <p className="mb-8 text-left">If you find individual uploads or posts that seem out of place, <b>click <span className="underline text-red-700">Not Him?</span></b> to report these specific instances as <b>not your boyfriend.</b></p>
                    <button className="bg-red-600 text-white px-4 py-2 rounded text-sm font-bold transition duration-300 ease-in-out transform hover:scale-105" onClick={handleDisclaimerClose}>I understand!</button>
                  </div>
                </div>
              )}
              <div className="min-h-screen">
                <header className="fixed p-4 z-10 w-full bg-gray-900">
                  <div className="container mx-auto flex items-center justify-between hover:cursor-pointer" onMouseUp={() => navigate("/")}>
                    <LightLogo/>
                  </div>
                </header>
                {/* <div className="h-px bg-gray-500"></div> */}
                <main className="flex-1 pt-20 p-4 text-center text-white">
                  <h1 className="dela-gothic text-xl mb-1">Hey girly...<br/>We found a match. 🙊</h1>
                  <h3 className="text-sm">We're here for you.</h3>
                  <div className="flex justify-center flex-col w-full md:w-2/3 lg:w-1/2 mx-auto">
                    <div className="mt-4 mb-4">
                      <div className="p-4 bg-gray-700 rounded-lg shadow-lg max-w-md mx-auto">
                        <h2 className="font-bold text-lg mb-2 italic">His first upload ({Intl.DateTimeFormat('en-US').format(new Date(subject.queries[0].created_at))})</h2>
                        <img src={subject.preview_photo} alt="First upload" className="w-64 h-auto rounded-lg mb-4 mx-auto"/>
                        <div className="flex flex-col gap-4 text-base">
                        <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl flex items-center justify-center gap-2 mb-2 transition duration-300 ease-in-out"
                            onClick={() => createChat(subject.queries[0])}>
                          <BsChat className="w-5 h-5" />
                          Chat with the Uploader
                        </button>
                        <button 
                          className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-xl flex items-center justify-center gap-2 transition duration-300 ease-in-out"
                          onClick={handleDefinitelyNotHimClick}
                          >
                          <FiXCircle className="w-5 h-5" />
                          Definitely not him?
                        </button>
                        </div>
                      </div>
                    </div>
                    {showAlternativeMatchesModal && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-8 text-black">
                        <div className="bg-white p-6 rounded-lg text-center w-full md:w-1/2 lg:w-1/3 relative">
                            {/* Close Button */}
                            <button
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 font-bold text-lg"
                            onClick={() => setShowAlternativeMatchesModal(false)}
                            >
                            &times;
                            </button>

                            <h3 className="text-xl font-semibold mb-4">Other Possible Matches</h3>
                            <div className="flex flex-wrap justify-center gap-4 mb-4">
                            {ranked_matches.filter(match => match[0] != subject.user_id).map((match, index) => (
                                <div key={index} className="text-center" onClick={() => navigate(`/subject/${match[0]}`)}>
                                    <img src={match.url} alt={`Match ${index + 1}`} className="w-16 h-16 object-cover rounded-lg mb-2 mx-auto" />
                                    <p className="text-sm">{match[1] * 100}% Likelihood</p>
                                </div>
                            ))}
                            </div>
                            <button
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg font-bold transition duration-300 ease-in-out transform hover:bg-blue-600"
                            onClick={() => setShowAlternativeMatchesModal(false)}
                            >
                            None of these are him
                            </button>
                        </div>
                        </div>
                    )}
                    <TLDRTimeLine subject={subject} scrollToPost={scrollToPost} />
                    {/* Experience Sharing Title */}
                    <h2 className="text-2xl font-bold mb-3 mt-2 text-left w-full">Share Your Experience</h2>
                    {/* What's on your mind section */}
                    <div className="w-full relative">
                      <div className="relative w-full">
                      <textarea
                        ref={textareaRef}
                        className="w-full p-3 bg-gray-800 text-white rounded-lg relative z-0"
                        placeholder="Confess an experience... (It's anonymous.)"
                        onChange={handleTextChange}
                        style={{ minHeight: '180px', height:'180px', paddingBottom: calculatePaddingBottom() }}
                        value={textareaValue}
                      ></textarea>
                      
                      {/* Photos container positioned inside the textarea */}
                      <div className="absolute left-0 bottom-0 pl-3 pb-8 flex flex-wrap gap-2 z-2">
                        {
                          selectedPhotos.slice(0, 3).map((photo, index) => (
                            <div key={photo} className="relative w-16 h-16">
                              <img src={photo} alt={`Selected for upload ${index + 1}`} className="object-cover rounded-lg" />
                              <button
                                className="absolute top-0 right-0 bg-black text-white rounded-full p-1 hover:bg-red-600 transition duration-150 ease-in-out"
                                onClick={() => removePhoto(index)}
                                style={{ width: '22px', height: '22px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              >
                                &times;
                              </button>
                            </div>
                          ))
                        }
      
                        {
                          selectedPhotos.length > 3 &&
                          <div className="relative w-16 h-16 bg-gray-300 flex items-center justify-center rounded-lg text-black">
                            +{selectedPhotos.length - 3} others
                          </div>
                        }
                      </div>
                      </div>
                    </div>
                    {/* Rating Sliders Section */}
                    {Object.keys(ratings).map((attribute, index) => renderRatingSlider(attribute, index))}
      
                    {Object.keys(ratings).length > 2 && (
                      <button
                        onClick={handleShowMoreClick}
                        className="flex items-center text-blue-400 text-sm underline pb-2 mt-2 justify-center font-bold"
                      >
                        {showMoreAttributes ? (
                          <>
                            Show Less <ChevronUp className="w-4 h-4 ml-1" />
                          </>
                        ) : (
                          <>
                            Show More <ChevronDown className="w-4 h-4 ml-1" />
                          </>
                        )}
                      </button>
                    )}
                    {/* Tags Section */}
                    <div className="flex flex-wrap justify-center gap-2 p-4 mx-auto w-full">
                      {tags.map(tag => (
                        <button
                          key={tag}
                          className={`px-3 py-1 text-sm font-semibold rounded-full border-2 
                                      ${selectedTags.includes(tag) ? 'border-green-300 bg-green-100 text-green-700' : 'border-white text-white'}
                                      transition-colors duration-300 transform hover:bg-green-200 hover:text-green-800`}
                          onClick={() => toggleTag(tag)}
                        >
                          {tag}
                        </button>
                      ))}
                    </div>
                    {/* Share Experience Button */}
                    <div className="flex justify-center p-4">
                      <button
                        className="bg-green-500 text-white px-6 py-2 rounded-lg text-lg font-bold shadow-lg transform transition duration-300 ease-in-out hover:bg-green-600"
                        onClick={handleSubmitConfession}
                      >
                        Share Experience(s) / Photos
                      </button>
                    </div>
      
                    {/* Confirmation Modal */}
                    {showConfirmationModal && (
                      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-8 text-black">
                      <div className="bg-white p-6 rounded-lg text-center w-full md:w-1/2 lg:w-1/3 max-h-[80vh] overflow-y-auto">
                        <h3 className="text-xl font-semibold mb-4">Review Your Submission</h3>
                        <p className="text-gray-700 mb-4 whitespace-pre-wrap">{textareaValue}</p>
                        <div className="flex flex-wrap justify-center gap-4 mb-4">
                          {selectedPhotos.map((photo, index) => (
                            <img key={photo} src={photo} alt={`Attachment ${index + 1}`} className="w-16 h-16 object-cover rounded-lg" />
                          ))}
                        </div>
                        {/* Display Selected Tags */}
                        <div className="flex flex-wrap justify-center gap-2 mb-4">
                          {selectedTags.map(tag => (
                            <span key={tag} className="px-3 py-1 text-sm font-semibold rounded-full bg-green-100 text-green-700">
                              {tag}
                            </span>
                          ))}
                        </div>
                        {/* Display selected ratings */}
                        {displaySelectedRatings() && (
                          <div className="mb-4">
                            <h4 className="text-lg font-semibold mb-2">Selected Ratings:</h4>
                            {displaySelectedRatings()}
                          </div>
                        )}
                        <div className="flex flex-col md:flex-row gap-4 justify-center mb-4">
                          <button
                            className="bg-red-500 text-white px-4 py-2 rounded-lg font-bold transition duration-300 ease-in-out transform hover:bg-red-600"
                            onClick={handleCloseModal}
                          >
                            Cancel
                          </button>
                          <button
                            className="bg-green-500 text-white px-4 py-2 rounded-lg font-bold transition duration-300 ease-in-out transform hover:bg-green-600"
                            onClick={() => confirmSubmission}
                          >
                            Confirm Submission
                          </button>
                        </div>
                      </div>
                    </div>              
                    )}
                    {subject ? 
                      <div className="text-left mt-4 w-full md:w-2/3 mx-auto">
                          {makeTimeline(subject).map((event, idx: number) => {
                              if (event.type == "query") {
                                return <Post
                                            id={event.id}
                                            postType={PostTypes.SEARCH}
                                            onChatClick={() => { navigate('/ugc/chat') }}
                                            full={true}
                                            images={event.photos.map(attachment => attachment.photo)}
                                            initialRating={0}
                                            elapsedTime={dayjs(new Date(event.created_at)).fromNow()}
                                            caption={"Spotted: Our state-of-the-art love radar just caught a wild boyfriend in his natural habitat - the gym! Reportedly, he was flexing more than just his muscles. Stay tuned for more juicy updates in this romantic saga."}
                                            authToken={authToken}
                                            initialComments={[]}
                                        />
                              } else if (event.type == "tinder_profile") {
                                return <Post
                                            id={event.id}
                                            postType={PostTypes.TINDER}
                                            onChatClick={() => { console.log("no chat for Tinder submissions") }}
                                            full={true}
                                            images={event.photos.map(attachment => attachment.photo)}
                                            initialRating={0}
                                            elapsedTime={dayjs(new Date(event.created_at)).fromNow()}
                                            caption={"Potentially spotted on Tinder!"}
                                            authToken={authToken}
                                            initialComments={[]}
                                        />
                              } else if (event.type == "confession") {
                                return <Post
                                            id={event.id}
                                            postType={PostTypes.TINDER}
                                            onChatClick={() => { console.log("no chat for Tinder submissions") }}
                                            full={true}
                                            images={event.attachments.map(attachment => attachment.photo)}
                                            initialRating={event.num_upvotes - event.num_downvotes}
                                            elapsedTime={dayjs(new Date(event.created_at)).fromNow()}
                                            caption={event.content}
                                            authToken={authToken}
                                            initialComments={event.replies}
                                        />
                              }
                          })}
                      </div> : <div className="h-px bg-gray-500">Loading...</div> // TODO: loading animation!
                    }
                  </div>
                </main>
              </div>
              <Footer/>
            </div>
        );
                }
}

export default SubjectDetailPage;