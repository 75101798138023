import React from 'react';
import Layout from "../Layout";
import ExampleReveal from '../Assets/example-reveal.png';

type ScanInfo = {
    date: Date;
    imgSrc: string;
};

const RevealInfo:React.FC = () => {
    const scanData: ScanInfo[] = [
        {
            date: new Date('2023-08-17'),
            imgSrc: ExampleReveal,
        },
        {
            date: new Date('2023-08-16'),
            imgSrc: ExampleReveal,
        },
        {
            date: new Date('2023-08-11'),
            imgSrc: ExampleReveal,
        },
        {
            date: new Date('2023-08-10'),
            imgSrc: ExampleReveal,
        },
    ];

    const formatDate = (date: Date) => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    return (
        <Layout>
        <div className="bg-white w-full max-w-lg mx-auto p-4 rounded-lg shadow-lg">
            <h2 className="text-center text-xl font-bold mb-4">Scan History</h2>

            {scanData.map((scan, index) => (
                <div 
                    key={index} 
                    className="flex items-center mb-3 hover:bg-gray-100 p-2 rounded-lg cursor-pointer border border-gray-300 transition-all duration-200"
                    title="Click to chat" // Tooltip
                >
                    <img src={scan.imgSrc} alt={`User who scanned on ${formatDate(scan.date)}`} className="w-16 h-16 rounded-full mr-4" />
                    <div className="text-gray-700 flex-grow text-left font-bold">
                        {formatDate(scan.date)}
                    </div>
                    {/* <img src={ChatIcon} alt="Chat Icon" className="w-5 h-5 ml-2" /> Chat Icon */}
                </div>
            ))}
        </div>
    </Layout>
    );
};

export default RevealInfo;