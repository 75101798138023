import React, { useState, useEffect } from 'react';

const NewScannedCounter: React.FC = () => {
    const [count, setCount] = useState<number>(35200);

    useEffect(() => {
        // Fetch the count from the API
        fetch('https://api.ishemine.com/evals/count/')
            .then(response => response.json())
            .then((data: { count: number }) => {
                // Set the count state with the value from the API
                setCount(data.count);
            })
            .catch((error: Error) => {
                console.error("Error fetching count:", error);
            });
    }, []);

    // Function to add comma separation to numbers
    const numberWithCommas = (x: number): string => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className="bg-gray-100 p-6 shadow-md border-b-2 border-gray-300">
            <div className="flex flex-col md:flex-row justify-between items-center">
                <div className="flex flex-col w-full md:w-1/2 text-left">
                    <p className="text-left text-gray-800 text-md md:text-lg font-semibold mb-4">Our Mission</p>
                    <h2 className="font-bold text-xl md:text-2xl mb-4">A community committed to transparency and trust in relationships.</h2>
                    <p className="text-gray-700 text-md md:text-lg leading-relaxed">
                        Our goal is to bring peace of mind by verifying the sincerity of your partner. Trust us to help you discover the truth, because when it comes to matters of the heart, certainty is a right, not a luxury.
                    </p>
                </div>
                <div className="flex flex-col items-center w-full md:w-1/2 mt-4 md:mt-0">
                    <h2 className="text-4xl md:text-5xl font-extrabold text-gray-800 mb-2 dela-gothic">{numberWithCommas(count)}</h2>
                    <p className="md:text-lg text-gray-600 font-semibold">men uploaded and counting...</p>
                </div>
            </div>
        </div>
    );
};

export default NewScannedCounter;