import React, { useState } from 'react';
import FakePhotoUpload from './FakePhotoUpload';
import placeholder from '../Assets/demo-upload-him.png';

type FakePhotoUploadGridProps = {
    numPhotos: number;
    setNumPhotos: (value: number) => void;
    setPhotos: React.Dispatch<React.SetStateAction<string[]>>;
    photos: string[];
}

const FakePhotoUploadGrid: React.FC<FakePhotoUploadGridProps> = ({ numPhotos, setNumPhotos, setPhotos, photos }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <div className="flex flex-wrap justify-center gap-4 mt-4">
            <div className="flex gap-4 mt-4">
                <FakePhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotos={setPhotos} defaultPhoto={placeholder} arrayIndex={0} />
                <FakePhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotos={setPhotos} defaultPhoto={""} arrayIndex={1} />
                <FakePhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotos={setPhotos} defaultPhoto={""} arrayIndex={2} />
            </div>
            <div className="flex gap-4 mt-4">
                <FakePhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotos={setPhotos} defaultPhoto={""} arrayIndex={3} />
                <FakePhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotos={setPhotos} defaultPhoto={""} arrayIndex={4} />
                <FakePhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotos={setPhotos} defaultPhoto={""} arrayIndex={5} />
            </div>
        </div>
    );
};

export default FakePhotoUploadGrid;