import React, { useState } from 'react';
import PhotoUpload from './PhotoUpload';

type PhotoUploadGridProps = {
    numPhotos: number;
    setNumPhotos: (value: number) => void;
    authToken: string;
    setPhotoIds: React.Dispatch<React.SetStateAction<Array<string>>>;
}

const PhotoUploadGrid: React.FC<PhotoUploadGridProps> = ({ numPhotos, setNumPhotos, authToken, setPhotoIds }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <div className="flex flex-col items-center">
            <div className="flex gap-4 mt-4">
                <PhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} authToken={authToken} setPhotoIds={setPhotoIds} />
                <PhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} authToken={authToken} setPhotoIds={setPhotoIds} />
                <PhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} authToken={authToken} setPhotoIds={setPhotoIds} />
            </div>
            <div className="flex gap-4 mt-4">
                <PhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} authToken={authToken} setPhotoIds={setPhotoIds} />
                <PhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} authToken={authToken} setPhotoIds={setPhotoIds} />
                <PhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} authToken={authToken} setPhotoIds={setPhotoIds} />
            </div>
        </div>
    );
};

export default PhotoUploadGrid;