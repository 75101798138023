import React from 'react';

interface FailureProps {
    errorMessage: string;
}

const FailureComponent:React.FC<FailureProps> = ({ errorMessage }) => {
    return (
        <div className="mt-8 m-4 bg-slate-100 rounded">
            <p className="text-red-500">{ errorMessage }</p>
        </div>
    );
}

export default FailureComponent;