import Layout from "../Layout"
import { useNavigate } from "react-router-dom";

const LoyaltyInst = () => {
    const navigate = useNavigate();

    return (
        <Layout>
            <h1 className="dela-gothic text-2xl">Welcome to the LOYALTY DEMO.</h1>
            <p className="m-5">The following workflow simulates the experience of uploading your significant other when there <i>isn’t</i> a match, meaning he’s yours.</p>
            <button 
                className="w-64 h-14 mt-6 flex-shrink-0 bg-purple-500 rounded-full text-white font-bold text-lg"
                onClick={() => navigate("/ugc/upload-him")}
            >
                BEGIN DEMO
            </button>
        </Layout>
    );
};

export default LoyaltyInst;
