import React, { useState } from 'react';
import Close from './Close';
import { useNavigate } from 'react-router-dom';

interface ConfirmLockModalProps {
    setShowModal: (show: boolean) => void;
    callback: () => void;
    queryId: number;
}

const ConfirmLockModal: React.FC<ConfirmLockModalProps> = ({ setShowModal, callback, queryId }) => {
    const navigate = useNavigate();

    const [hasSubmitted, setHasSubmitted] = useState(false);

    const handleConfirmClick = () => {
        callback();
        setHasSubmitted(true);
    };

    return (
        <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-amber-100 p-6 rounded-lg text-black max-w-lg mx-4">
                {/* Close button */}
                <button 
                    className="absolute top-4 right-4 text-xl font-bold"
                    onClick={() => {
                        setShowModal(false);
                        if (hasSubmitted) {
                            navigate(`/hes-mine/${queryId}`);
                        }
                    }}
                >
                    <Close/>
                </button>

                {hasSubmitted ? (
                    // Submitted state
                    <div className="text-center">
                        <h2 className="mb-4 text-lg font-bold">Thank you for sharing!</h2>
                        <div className="text-left mb-8">
                            <p className="mb-4">Your photos have been successfully added to our database.</p>
                            <p className="mb-4">You will receive text messages whenever someone uploads a matching photo and wants to reach out to you anonymously. Additionally, you can track future updates directly on our site by signing in with your phone number.</p>
                            <p>To establish trustworthiness for others, you can fill out an application to <a href="https://airtable.com/appc8CLuIK7gTr4dB/shr9fJjhNq2lgbbXi"><b>get verified</b></a> in 1-3 business days. Verified users have a badge on their posts (uploads) and are able to access search results free-of-charge.</p>
                        </div>
                        <a href="https://airtable.com/appc8CLuIK7gTr4dB/shr9fJjhNq2lgbbXi">
                            <button className="mb-8 w-3/4 md:w-auto transition-transform transform scale-100 hover:scale-105 font-bold text-gray-700 border border-2 border-gray-700 py-2 px-5 md:px-8 rounded-xl">
                                Get Verified
                            </button>
                        </a>
                        <p className="underline cursor-pointer" onClick={() => {
                            setShowModal(false);
                            navigate(`/hes-mine/${queryId}`);
                        }}>Close</p>
                    </div>
                ) : (
                    // Initial state
                    <>
                        <h2 className="mb-4 text-lg font-bold">Confirm that you want to join our network</h2>
                        <div className="text-left mb-6">
                            <p className="mb-4">By clicking the “I confirm” button, you consent to having your photos be featured on IsHeMine.com and to being contacted by another IsHeMine.com user in the event of a match on your upload (all contact will be done through IsHeMine.com and your personal information will never be shared).</p>
                            <p>Be sure to check out our <a href="/privacy" className="font-bold">Privacy Policy</a> for further information.</p>
                        </div>
                        <button 
                            className="bg-blue-500 text-white py-2 px-4 rounded"
                            onClick={handleConfirmClick}
                        >
                            I confirm
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default ConfirmLockModal;