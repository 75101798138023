import React, { useState, useEffect } from 'react';

const LoadBar = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 10;
                }
                clearInterval(interval);
                return 100;
            });
        }, 500); // adjust the time for faster/slower animation

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="w-full md:w-1/2 lg:w-1/3 h-8 border border-black border-4 rounded-full overflow-hidden">
            <div 
                style={{ width: `${progress}%` }} 
                className="h-full bg-F260FF transition-all duration-500 rounded-full"
            ></div>
        </div>
    );
}

export default LoadBar;
