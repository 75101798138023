import React, { useState, useEffect, useRef } from 'react';
import GetStarted from '../Components/GetStarted';
import ThankMeLater from '../Components/ThankMeLater';
import LandingBg from '../Components/LandingBg';
import WhiteLogo from '../Components/WhiteLogo';
import { useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer';
import TestimonialsCarousel from '../Components/TestimonialCarousel';
import NewHowOurServiceWorks from '../Components/NewHowOurServiceWorks';
import NewScannedCounter from '../Components/NewScannedCounter';
import Logo from '../Components/Logo';
import MiniUploadHim from '../Components/MiniUploadHim';
// import ReportModal from '../Components/ReportModal';
// import ChatInstPopup from '../Components/ChatInstPopup';
import LandingBgImg from '../Assets/landing-bg.png';
import LandingBgImgPlaceholder from '../Assets/landing-bg-placeholder.png';
import { ImageData } from './FindYourMan';

type LandingProps = {
    phoneNumberMem: string;
    setPhoneNumberMem: (value: string) => void;
    setImageData: React.Dispatch<React.SetStateAction<Array<ImageData>>>;
    setLoginMethod: React.Dispatch<React.SetStateAction<string>>;
}

const NewLanding: React.FC<LandingProps> = ({ phoneNumberMem, setPhoneNumberMem, setImageData, setLoginMethod }) => {
    const navigate = useNavigate();
    const [isImageLoaded, setImageLoaded] = useState(false);

    const [offset, setOffset] = useState(0);
    const miniUploadRef = useRef<HTMLDivElement>(null);

    const scrollToMiniUpload = () => {
        miniUploadRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const [isGSOpen, setIsGSOpen] = useState<boolean>(false);
    const [headerBg, setHeaderBg] = useState<string>('bg-transparent');

    let backgroundImageStyle = useRef({
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Full viewport height on mobile
        width: '100%',
        top: offset,
        left: '0',
        zIndex: '-1', // Ensure it stays behind the content
    });

    useEffect(() => {
        const handleScroll = () => {
            const scannedCounterEl = document.querySelector('.scanned-counter') as HTMLElement;
            const howServiceEl = document.querySelector('.how-service') as HTMLElement;

            const scannedCounterOffset = scannedCounterEl.offsetTop;

            const scrollPosition = window.scrollY + 70;

            if (scrollPosition >= scannedCounterOffset && scrollPosition < howServiceEl.offsetTop) {
                setHeaderBg('bg-gray-100');
            } else if (scrollPosition >= howServiceEl.offsetTop) {
                setHeaderBg('bg-gray-900');
            } else {
                setHeaderBg('bg-transparent');
            }
            if (window.innerWidth >= 768) { // No parallax on mobile
                setOffset(window.pageYOffset);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        console.log("isImageLoaded", isImageLoaded);
        backgroundImageStyle.current = {
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh', // Full viewport height on mobile
            width: '100%',
            top: offset,
            left: '0',
            zIndex: '-1', // Ensure it stays behind the content
        };
    }, [isImageLoaded]);

    function openGSModal() {
        setIsGSOpen(true);
        setLoginMethod("login");
    }

    useEffect(() => {
        const img = new Image();
        img.src = LandingBgImg;
        if (img.complete) {
            setImageLoaded(true);
        } else {
            img.onload = () => setImageLoaded(true);
        }

        // clear login method
        setLoginMethod('');
    }, []);

    return (
        <div className="flex flex-col min-h-scree
        n relative overflow-hidden">
            <img
                src={LandingBgImgPlaceholder}
                alt="Background Placeholder"
                className={`fixed object-cover ${isImageLoaded ? 'hidden' : ''}`} style={backgroundImageStyle.current}
            />
            <img
                src={LandingBgImg}
                alt="Background"
                onLoad={() => setImageLoaded(true)}
                className={`fixed object-cover ${isImageLoaded ? '' : 'hidden'}`} style={backgroundImageStyle.current}
            />
            <header className={`fixed p-4 z-10 w-full ${headerBg}`}>
                <div className="container mx-auto flex items-center justify-between">
                    <div onMouseUp={() => navigate("/")}>
                        {
                            (headerBg === 'bg-transparent' || headerBg === 'bg-gray-900')
                            ? <WhiteLogo/>
                            : <Logo/>
                        }
                    </div>
                    <button 
                        onClick={openGSModal} 
                        className={`px-4 py-2 dela-gothic text-sm font-medium rounded-md border transition-all ${
                            headerBg === 'bg-transparent' 
                            ? 'text-gray-300 border-gray-300 hover:bg-gray-700 hover:text-white' 
                            : headerBg === 'bg-gray-900'
                            ? 'text-white border-gray-400 hover:border-gray-500 hover:bg-red-300 hover:text-gray-800'
                            : 'text-gray-600 border-gray-400 hover:border-gray-500 hover:bg-gray-700 hover:text-white'
                        }`}
                    >
                        Login
                    </button>
                </div>
            </header>
            <main className="flex-1 flex flex-col text-center">
                {/* Use section tags for semantic structure */}
                <section aria-labelledby="main-heading" className="text-white px-4 pt-16 pb-8 bg-black bg-opacity-50">
                    <h1 id="main-heading" className="dela-gothic text-3xl md:text-4xl lg:text-4xl font-bold pt-4 mb-2">
                        What's the <span className="text-red-500">tea</span>?
                    </h1>
                    <p className="text-md md:text-lg mb-6">
                        Find out what other women say about him. <br/>
                        Search dating apps.
                    </p>
                    <div ref={miniUploadRef}>
                        <MiniUploadHim setImageData={setImageData} setLoginMethod={setLoginMethod} />
                    </div>
                </section>
                <section className="scanned-counter">
                    <NewScannedCounter />
                </section>
                <section className="testimonials-carousel">
                    <TestimonialsCarousel />
                </section>
                <section className="how-service">
                    <NewHowOurServiceWorks openGSModal={scrollToMiniUpload}/>
                </section>
                <GetStarted isOpen={isGSOpen} phoneNumberMem={phoneNumberMem} setIsOpen={setIsGSOpen} setPhoneNumberMem={setPhoneNumberMem} />
            </main>
            <footer>
                <Footer/>
            </footer>
        </div>
    );
};

export default NewLanding;