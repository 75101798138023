import React, { useState, useEffect } from 'react';
import LightLogo from '../Components/LightLogo';
import { useNavigate } from 'react-router-dom';
import './Pages.css';
import BlueSubmitBtn from '../Components/BlueSubmitBtn';

export interface ImageData {
    id: string;
    photo: string;
    status: string;
    face: string;
    bounding_box: { 
        left: number, 
        top: number, 
        width: number, 
        height: number
    };
}

export interface SearchResult {
    found: boolean;
    num_photos: number | null;
    num_queries: number | null;
    query_id: string;
    available: boolean;
    intent_client_secret: string | null;
}

interface FindYourManProps {
    imageData: Array<ImageData>;
    authToken: string;
    setQueryResult: React.Dispatch<React.SetStateAction<SearchResult>>;
}

type ImageDimensions = Record<string, { width: number, height: number }>;

const FindYourMan:React.FC<FindYourManProps> = ({ imageData, authToken, setQueryResult }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    
    const [imageDimensions, setImageDimensions] = useState<ImageDimensions>({});
    const [selectedImages, setSelectedImages] = useState<Array<boolean>>([]);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        setSelectedImages(new Array(imageData.length).fill(false));
    }, [imageData]);

    function searchPeople(failed: (error: any) => void) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", authToken);

        var requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let endpoint = "https://api.ishemine.com/evals/query/create/?";
        imageData.forEach((image, idx) => {
            if (selectedImages[idx]) {
                endpoint += `face_ids=${image.face}&`
            }
        })

        fetch(endpoint, requestOptions)
        .then(response => response.json())
        .then(result => {
            setIsLoading(false);
            setQueryResult(result);
            navigate("/status-update");
        })
        .catch(failed);
    }
    
    function handleClick() {
        setIsLoading(true);

        searchPeople((error) => {
            setIsLoading(false);
            console.log("error", error);
        });
    }

    const getImageDimensions = (url: string): Promise<{width: number, height: number}> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                resolve({ width: img.width, height: img.height });
            };
            img.onerror = () => {
                reject(new Error('Could not load image.'));
            };
            img.src = url;
        });
    };

    useEffect(() => {
        const fetchDimensions = async () => {
            const dimensions: ImageDimensions = {};
    
            for (const image of imageData) {
                const dim = await getImageDimensions(image.photo);
                dimensions[image.id] = dim;
            }
    
            setImageDimensions(dimensions);
        };
    
        fetchDimensions();
    }, [imageData]);

    useEffect(() => {
        console.log(selectedImages);
        setDisabled(!selectedImages.some(value => value));
    }, [selectedImages]);

    const autoSelectFaces = (face_id: string, originIdx: number) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", authToken);
        myHeaders.append("Content-Type", "application/json");
        let endpoint = "https://api.ishemine.com/evals/select/";
        var raw = JSON.stringify({
            "face_ids": imageData.map((image) => image.face),
            "selected_face": face_id
        });
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(endpoint, requestOptions)
        .then(response => response.text())
        .then(resp => {
            const parsed = JSON.parse(resp);
            console.log(parsed);
            setSelectedImages(selectedImages.map((_, idx) => idx === originIdx || parsed.face_ids.includes(imageData[idx].face)));
        })
        .catch(error => console.log('error', error));
    };
    

    return (
        <div className="find-your-man-bg flex flex-col min-h-screen">
            <header className="p-4">
                <div className="container mx-auto flex items-center justify-between hover:cursor-pointer" onMouseUp={() => navigate("/")}>
                    <LightLogo/>
                </div>
            </header>
            <main className="flex-1 mx-auto p-4 text-center text-white">
                <h1 className="dela-gothic text-2xl">Which of these is him?</h1>
                <p className="text-base mt-2">Please select all photos that contain his face.</p>

                {/* Photo Display Grid */}
                <div className="grid grid-cols-3 gap-4 mt-4">
                    {imageData.map((result, index) => {
                        const imageDim = imageDimensions[result.id];
                        if (!imageDim) return null;

                        // Convert the relative coordinates to pixels based on the original image dimensions.
                        const leftPx = result.bounding_box.left * imageDim.width;
                        const topPx = result.bounding_box.top * imageDim.height;
                        const widthPx = result.bounding_box.width * imageDim.width;
                        const heightPx = result.bounding_box.height * imageDim.height;
                        
                        // calculate the scale factor to fit the div height to 150px
                        const scaleFactor = 150 / heightPx;

                        return (
                            <div 
                                key={result.id}
                                style={{
                                    backgroundImage: `url(${result.photo})`,
                                    backgroundPosition: `${-leftPx}px ${-topPx}px`,
                                    backgroundSize: `${imageDim.width}px ${imageDim.height}px`,
                                    width: `${widthPx}px`,  // Scale width
                                    height: `${150/scaleFactor}px`,  // Set height to 150px
                                    border: selectedImages[index] ? '3px solid green' : '1px solid white',
                                    transformOrigin: '0 0', // Set the origin of transformation to top left
                                    transform: `scale(${scaleFactor})`,  // Apply scaling
                                    marginBottom: `${(150/scaleFactor) - 150}px}`
                                }}
                                className="bg-cover cursor-pointer"
                                onClick={() => {
                                    console.log("clicked");
                                    setSelectedImages(prev => {
                                        if (prev.every((val) => val === false)) {
                                            autoSelectFaces(result.face, index);
                                        }
                                        const updated = [...prev];  // Create a copy
                                        updated[index] = !updated[index]; // Toggle the value at the current index
                                        return updated;
                                    });
                                }}
                            ></div>
                        );
                    })}
                </div>
                <div className="absolute bottom-5 mx-auto left-5 right-5 md:w-1/2 lg:w-1/3">
                    <BlueSubmitBtn text={"Confirm"} numberEntered={!disabled} isLoading={isLoading} handleClick={handleClick} />
                </div>
            </main>
        </div>
    );
};

export default FindYourMan;