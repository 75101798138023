import React from 'react';
import Layout from '../Layout';

const Error = () => {
    // TODO: file out Error page
    return (
        <Layout>
            <div className="flex flex-col items-center justify-center w-full">
                <h1 className="dela-gothic text-4xl font-bold mb-4 text-red-500">OOPS!</h1>
                <p className="text-lg text-gray-700">The page you are looking for does not exist.</p>
            </div>
        </Layout>
    );
};

export default Error;