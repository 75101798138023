import React, { useEffect, useState } from "react";
import Layout from "../Layout"
import FakePhotoUpload from "../Components/FakePhotoUpload";
import { useNavigate } from "react-router-dom";
import placeholderImage from "../Assets/demo-couple-2.png";


type CheatingInstProps = {
    otherGirlPhotos: string[];
    setOtherGirlPhotos: React.Dispatch<React.SetStateAction<string[]>>;
}

const CheatingInst:React.FC<CheatingInstProps> = ({ otherGirlPhotos, setOtherGirlPhotos }) => {
    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(false);
    const [numPhotos, setNumPhotos] = useState(0);

    useEffect(() => {
        setOtherGirlPhotos(["", "", ""]);
    }, []);

    useEffect(() => {
        console.log("new photos:", otherGirlPhotos);
    }, [otherGirlPhotos]);

    return (
        <Layout>
            <h1 className="dela-gothic text-2xl">Welcome to the CHEATING DEMO.</h1>
            <p className="m-5"><strong>Attach up to 3 photos</strong> you’d like to get “matched” with. These are the <strong>“other girl’s photos”</strong> you will see once you upload your pictures, so <i>make sure they are different</i>.</p>

            <p>The following photo is <strong>a sample for simplicity</strong>. You may remove it if you wish to customize the experience!</p>
            <div className="flex flex-col items-center">
                <div className="flex gap-4 mt-4">
                    {otherGirlPhotos.map((photo, index) => (
                        <FakePhotoUpload
                            key={index}
                            sModalOpen={modalOpen}
                            setSModalOpen={setModalOpen}
                            numPhotos={numPhotos}
                            setNumPhotos={setNumPhotos}
                            setPhotos={setOtherGirlPhotos}
                            defaultPhoto={(index === 0) ? placeholderImage : ""}
                            arrayIndex={index}
                        />
                    ))}
                </div>
            </div>
            <button 
                className="w-64 h-14 mt-6 flex-shrink-0 bg-purple-500 rounded-full text-white font-bold text-lg"
                onClick={() => navigate("/ugc/upload-him")}
            >
                BEGIN DEMO
            </button>
        </Layout>
    );
};

export default CheatingInst;