import Layout from "../Layout"

const CookiePolicy = () => {
    return (
        <Layout>
            <div className="text-left mx-auto p-6 max-w-3xl bg-pink-100 shadow-md rounded-lg">
                <h1 className="text-3xl font-bold mb-6 border-b pb-2">Cookie Policy for IsHeMine.com</h1>

                <p className="mb-4 text-gray-600"><strong>Last updated:</strong> 9/30/23</p>

                <p className="mb-4">
                    Welcome to IsHeMine.com! Our Cookie Policy explains how we use cookies and similar technologies to recognize you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control our use of them.
                </p>

                <h2 className="text-xl font-semibold my-4">1. What are cookies? 🍪</h2>
                <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. They are widely used by website owners to make their websites work, or to work more efficiently, and provide reporting information.</p>

                <h2 className="text-xl font-semibold my-4">2. How do we use cookies? 🌐</h2>
                <p>We use cookies solely to store authentication tokens client-side for authentication persistence. This helps remember your logged-in state, so you don’t have to log in again on subsequent visits.</p>

                <h2 className="text-xl font-semibold my-4">3. How to control and delete cookies? 🛠️</h2>
                <p>You can control and delete cookies through your browser settings. For more information on how to do this, visit <a href="http://www.allaboutcookies.org" className="text-blue-500 hover:underline">www.allaboutcookies.org</a>.</p>

                <h2 className="text-xl font-semibold my-4">4. Changes to this Cookie Policy 🔄</h2>
                <p>We may update our Cookie Policy from time to time. Please revisit this policy regularly to stay informed about our use of cookies and related technologies.</p>

                <h2 className="text-xl font-semibold my-4">5. More Information 📧</h2>
                <p>For any queries related to our use of cookies, please contact us at <a href="mailto:info@ishemine.com" className="text-blue-500 hover:underline">info@ishemine.com</a>.</p>

                <h2 className="text-xl font-semibold my-4">6. Consent ✅</h2>
                <p>By using our website, you hereby consent to our Cookie Policy and agree to its terms.</p>

            </div>
        </Layout>
    );
};

export default CookiePolicy;