import React from "react";
import { useNavigate } from "react-router-dom";
import FakeLockHimDown from "../Components/FakeLockHimDown";
import WordleCongrats from "../Components/WordleCongrats";
import Layout from "../Layout";
import Confetti from 'react-confetti';
import JoinFB from "../Components/JoinFB";

interface FakeCongratsProps {
    uploadedImages: Array<string>;
}
const FakeCongrats:React.FC<FakeCongratsProps> = ({ uploadedImages }) => {

    return (
        <Layout>
            <div className="flex justify-center">
                <WordleCongrats/>
            </div>
            <div className="font-bold mt-5 text-lg">
                He's yours.
            </div>
            <div className="flex justify-center mt-6">
                <FakeLockHimDown uploadedImages={uploadedImages} callback={() => {}}/>
            </div>
            <JoinFB/>
            <Confetti recycle={false} numberOfPieces={600} />
        </Layout>
    );
};

export default FakeCongrats;