import React, { useState } from 'react';
import AuthlessPhotoUpload from './AuthlessPhotoUpload';

type NewPhotoUploadGridProps = {
    numPhotos: number;
    setNumPhotos: (value: number) => void;
    setPhotoIds: React.Dispatch<React.SetStateAction<Array<string>>>;
}

const NewPhotoUploadGrid: React.FC<NewPhotoUploadGridProps> = ({ numPhotos, setNumPhotos, setPhotoIds }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <div className="flex flex-col items-center">
            <div className="flex mt-4">
                <div className="mr-4">
                    <AuthlessPhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotoIds={setPhotoIds} />
                </div>
                <div className="mr-4">
                    <AuthlessPhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotoIds={setPhotoIds} />
                </div>
                <AuthlessPhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotoIds={setPhotoIds} />
            </div>
            <div className="flex mt-4">
                <div className="mr-4">
                    <AuthlessPhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotoIds={setPhotoIds} />
                </div>
                <div className="mr-4">
                    <AuthlessPhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotoIds={setPhotoIds} />
                </div>
                <AuthlessPhotoUpload sModalOpen={modalOpen} setSModalOpen={setModalOpen} numPhotos={numPhotos} setNumPhotos={setNumPhotos} setPhotoIds={setPhotoIds} />
            </div>
        </div>
    );
};

export default NewPhotoUploadGrid;