import React from 'react';
import Slideshow from './Slideshow';
import ConfirmLockModal from './ConfirmLockModal';

interface LockHimDownProps {
    uploadedImages: Array<string>;
    callback: () => void;
    queryId: number;
}
const LockHimDown: React.FC<LockHimDownProps> = ({ uploadedImages, callback, queryId }) => {
    const [showModal, setShowModal] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);

    return (
        <div className="h-full w-full md:w-1/2 lg:w-1/3 bg-slate-900 text-white p-5 rounded-xl">
            <div className="dela-gothic text-2xl">
                <h1>Want to</h1>
                <h1><span className="text-cyan-500">LOCK HIM DOWN</span>?</h1>
            </div>
            <div className="mt-3 text-left text-sm">
                If you do, the photos you uploaded will be added to our database and you open yourself to being reached out to <i>anonymously</i> by anyone who checks him in the future.
            </div>
            <div className="flex justify-center m-6">
                <Slideshow images={uploadedImages} full={false} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}/>
            </div>
            <div 
                className="bg-fuchsia-700 text-white py-3 px-20 rounded-3xl shadow-sm font-bold text-base w-full transition duration-300 hover:bg-opacity-40 border border-fuchsia-700 cursor-pointer flex items-center justify-center"
                onClick={() => setShowModal(true)}
            >
                Yes, he's mine
            </div>
            {/* <div className="mt-2 text-white py-3 px-14 rounded-3xl shadow-sm font-bold text-base border border-sky-400 w-full transition duration-300 ease-in-out transform hover:shadow-lg hover:bg-sky-400 hover:bg-opacity-50 cursor-pointer">
                No, I'll think about it
            </div> */}

        {showModal && (
                <ConfirmLockModal setShowModal={setShowModal} callback={callback} queryId={queryId} />
            )}
        </div>
    );
};

export default LockHimDown;