import React, { useState, useEffect } from 'react';
import LightLogo from '../Components/LightLogo';
import { useNavigate } from 'react-router-dom';
import './Pages.css';
import BlueSubmitBtn from '../Components/BlueSubmitBtn';
import ReCAPTCHA from 'react-google-recaptcha';

export interface ImageData {
    id: string;
    photo: string;
    status: string;
    face: string;
    bounding_box: { 
        left: number, 
        top: number, 
        width: number, 
        height: number
    };
}

interface FindYourManProps {
    imageData: Array<ImageData>;
    authToken: string;
    setFaceIds: React.Dispatch<React.SetStateAction<string[]>>;
}

type ImageDimensions = Record<string, { width: number, height: number }>;

const AuthlessFindYourMan:React.FC<FindYourManProps> = ({ imageData, authToken, setFaceIds }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    
    const [imageDimensions, setImageDimensions] = useState<ImageDimensions>({});
    const [selectedImages, setSelectedImages] = useState<Array<boolean>>([]);
    const [disabled, setDisabled] = useState(true);
    const recaptchaRef = React.createRef<ReCAPTCHA>();

    useEffect(() => {
        setSelectedImages(new Array(imageData.length).fill(false));
    }, [imageData]);

    function saveFacesToSearch() {
        const faceIds = imageData.filter((_, idx) => selectedImages[idx]).map((image) => image.face);
        setFaceIds(faceIds);
    }
    
    function handleClick() {
        setIsLoading(true);
        saveFacesToSearch();
        navigate("/one-moment");
    }

    const getImageDimensions = (url: string): Promise<{width: number, height: number}> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                resolve({ width: img.width, height: img.height });
            };
            img.onerror = () => {
                reject(new Error('Could not load image.'));
            };
            img.src = url;
        });
    };

    useEffect(() => {
        const fetchDimensions = async () => {
            const dimensions: ImageDimensions = {};
    
            for (const image of imageData) {
                const dim = await getImageDimensions(image.photo);
                dimensions[image.id] = dim;
            }
    
            setImageDimensions(dimensions);
        };
    
        fetchDimensions();
    }, [imageData]);

    useEffect(() => {
        console.log(selectedImages);
        setDisabled(!selectedImages.some(value => value));
    }, [selectedImages]);

    const autoSelectFaces = async (face_id: string, originIdx: number) => {
        const token = recaptchaRef.current ? await recaptchaRef.current.executeAsync() : null;
        if (!token) {
            // Handle the case when recaptchaRef.current is null
            console.log("Recaptcha ref not found");
            return;
        }
        const myHeaders = new Headers();
        myHeaders.append("Authorization", authToken);
        myHeaders.append("Content-Type", "application/json");
        let endpoint = "https://api.ishemine.com/evals/select/"; // TODO: Add captcha
        var raw = JSON.stringify({
            "face_ids": imageData.map((image) => image.face),
            "selected_face": face_id,
            "captcha": token
        });
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(endpoint, requestOptions)
        .then(response => response.text())
        .then(resp => {
            const parsed = JSON.parse(resp);
            console.log(parsed);
            setSelectedImages(selectedImages.map((_, idx) => idx === originIdx || parsed.face_ids.includes(imageData[idx].face)));
        })
        .catch(error => console.log('error', error));
    };
    

    return (
        <div className="find-your-man-bg flex flex-col min-h-screen">
            <header className="p-4">
                <div className="container mx-auto flex items-center justify-between hover:cursor-pointer" onMouseUp={() => navigate("/")}>
                    <LightLogo/>
                </div>
            </header>
            <main className="flex-1 mx-auto p-4 text-center text-white">
                <h1 className="dela-gothic text-2xl">Which of these is him?</h1>
                <p className="text-base mt-2">Please select all photos that contain his face.</p>

                {/* Photo Display Grid */}
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
                    {imageData.map((result, index) => {
                        const imageDim = imageDimensions[result.id];
                        if (!imageDim) return null;

                        // Convert the relative coordinates to pixels based on the original image dimensions.
                        const leftPx = result.bounding_box.left * imageDim.width;
                        const topPx = result.bounding_box.top * imageDim.height;
                        const widthPx = result.bounding_box.width * imageDim.width;
                        const heightPx = result.bounding_box.height * imageDim.height;
                        
                        // calculate the scale factor to fit the div height to 150px
                        const scaleFactor = 150 / heightPx;

                        return (
                            <div 
                                key={result.id}
                                style={{
                                    backgroundImage: `url(${result.photo})`,
                                    backgroundPosition: `${-leftPx}px ${-topPx}px`,
                                    backgroundSize: `${imageDim.width}px ${imageDim.height}px`,
                                    width: `${widthPx}px`,  // Scale width
                                    height: `${150/scaleFactor}px`,  // Set height to 150px
                                    border: selectedImages[index] ? '8px solid green' : '1px solid white',
                                    transformOrigin: '0 0', // Set the origin of transformation to top left
                                    transform: `scale(${scaleFactor})`,  // Apply scaling
                                    marginBottom: `${(150/scaleFactor) - 150}px}`,
                                    boxShadow: selectedImages[index] ? '0px 0px 10px 5px rgba(50,205,50,0.5)' : 'none',
                                }}
                                className={`bg-cover cursor-pointer relative transition-transform duration-300 ease-in-out ${
                                    selectedImages[index] ? 'transform scale-105' : '' // Scale up slightly when selected
                                }`}
                                onClick={() => {
                                    console.log("clicked");
                                    setSelectedImages(prev => {
                                        if (prev.every((val) => val === false)) {
                                            autoSelectFaces(result.face, index);
                                        }
                                        const updated = [...prev];  // Create a copy
                                        updated[index] = !updated[index]; // Toggle the value at the current index
                                        return updated;
                                    });
                                }}
                            >
                                {selectedImages[index] && (
                                    <div className="absolute top-0 right-0 p-1 bg-green-500 rounded-full">
                                        <svg className="w-4 h-4 text-white" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M5 13l4 4L19 7"></path>
                                        </svg>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="absolute bottom-5 mx-auto left-5 right-5 md:w-1/2 lg:w-1/3">
                    <BlueSubmitBtn text={"Confirm"} numberEntered={!disabled} isLoading={isLoading} handleClick={handleClick} />
                </div>
            </main>
            <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LcdznYnAAAAAFicIrLfu9EJuODfhkIScxm1_nCS"
                    />
        </div>
    );
};

export default AuthlessFindYourMan;