import Layout from "../Layout";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import HorizontalCarousel from '../Components/HorizontalCarousel';
import ChatThumbnail, { ChatThumbnailProps } from "../Components/ChatThumbnail";
import Loading from "./Loading";

interface PhotoData {
    url: string;
    date: string;
}

export interface PastQuery {
    query_id: number;
    photos: PhotoData[];
    subject_id: string;
    creator_id: number;
    date: string;
}
interface AuthLandingProps {
    authToken: string;
    setUploadedImages: React.Dispatch<React.SetStateAction<string[]>>;
    previousUploads: PastQuery[];
    setPreviousUploads: React.Dispatch<React.SetStateAction<PastQuery[]>>;
}

const AuthLanding:React.FC<AuthLandingProps> = ({ authToken, setUploadedImages, previousUploads, setPreviousUploads }) => {
    const navigate = useNavigate();

    const [chatStatuses, setChatStatuses] = useState<ChatThumbnailProps[]>([]);

    const [chatLoading, setChatLoading] = useState<boolean>(true);
    const [previousUploadsLoading, setPreviousUploadsLoading] = useState<boolean>(true);
    const [chatError, setChatError] = useState<string | null>(null);
    const [previousUploadsError, setPreviousUploadsError] = useState<string | null>(null);

    useEffect(() => {
        const getPreviousUploads = () => {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", authToken);
    
            var requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch("https://api.ishemine.com/evals/history/", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setPreviousUploads(result);
                setPreviousUploadsLoading(false);
            })
            .catch(error => {
                setPreviousUploadsLoading(false);
                setPreviousUploadsError("Error loading previous uploads");
                console.log('error', error);
            });
        }
    
        const getChats = () => {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", authToken);
    
            var requestOptions: RequestInit = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
    
            fetch("https://api.ishemine.com/chat/", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setChatStatuses(result);
                setChatLoading(false);
            })
            .catch(error => {
                setChatLoading(false);
                setChatError("Error loading chats");
                console.log('error', error)
            });
        }
        getPreviousUploads();
        getChats();
    }, [authToken]);
    

    return (
        <Layout>
            <div className="container mx-auto px-4 md:px-0 py-8 flex flex-col items-center">

                {/* Start New Upload Section */}
                <section className="text-center w-full md:w-1/2">
                    <button 
                        className="bg-white hover:bg-gray-200 text-salmon font-bold py-2 px-6 rounded-full border border-salmon shadow-md mx-auto" 
                        onClick={() => navigate("/upload")}
                    >
                        Start New Check
                    </button>
                </section>

                {/* Chat Status Section */}
                {chatStatuses.length > 0 ? 
                    <section className="my-6 w-full md:w-1/2">
                        <h2 className="dela-gothic text-base font-semibold mb-4 text-white text-left">MESSAGES</h2>
                        <div className="border border-gray-200 rounded-lg p-4 bg-white shadow-md">
                            {chatStatuses.map(chat => (
                                <ChatThumbnail id={chat.id} avatar={chat.avatar} other_user={chat.other_user} last_message={chat.last_message} created_at={chat.created_at} status={chat.status}  />
                            ))}
                        </div>
                    </section> : 
                    chatLoading ? 
                        <Loading /> :
                        <section className="my-6 w-full md:w-1/2">
                            <h2 className="dela-gothic text-xl font-semibold mb-4 text-white text-left">{chatError ? chatError : chatStatuses.length === 0 ? "" : "Unknown error retrieving chats."}</h2>
                        </section>
                }

                {previousUploads.length > 0 ? 
                    <section className="my-6 w-full md:w-1/2">
                        <h2 className="dela-gothic text-base font-semibold mb-4 text-white text-left">PREVIOUS SEARCHES</h2>
                        <HorizontalCarousel setUploadedImages={setUploadedImages} data={previousUploads} />
                    </section> : 
                    previousUploadsLoading ?
                        <Loading /> :
                        <section className="my-6 w-full md:w-1/2">
                            <h2 className="dela-gothic text-xl font-semibold mb-4 text-white text-left">{previousUploadsError ? previousUploadsError : previousUploads.length === 0 ? "" : "Unknown error retrieving past uploads."}</h2>
                        </section>
                }

            </div>
        </Layout>
    )
};

export default AuthLanding;
