import { Appearance, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { SearchResult } from "./FindYourMan";
import CheckoutForm from "../Components/CheckoutForm";
import Layout from '../Layout';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
interface CheckoutProps {
    queryResult: SearchResult;
    authToken: string;
}
export default function Checkout(props: CheckoutProps) {
    const stripePromise = loadStripe("pk_live_51Nju2AGEcckvLglJ9Zib4d67dKyTY9CaIQrcEhPc3fghW6zD8IR6BqCMQj8Zzn9eJDsIjF5PHZnnL71WqOrx7JIb00BhsaA9aT");
    const appearance: Appearance = {
    theme: 'stripe',
    variables: {
        fontWeightNormal: '500',
        borderRadius: '2px',
        colorPrimary: '#f360a6',
        colorIconTabSelected: '#fff',
        spacingGridRow: '16px'
    },
    rules: {
        '.Tab, .Input, .Block, .CheckboxInput, .CodeInput': {
        boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)'
        },
        '.Block': {
        borderColor: 'transparent'
        },
        '.BlockDivider': {
        backgroundColor: '#ebebeb'
        },
        '.Tab, .Tab:hover, .Tab:focus': {
        border: '0'
        },
        '.Tab--selected, .Tab--selected:hover': {
        backgroundColor: '#f360a6',
        color: '#fff'
        }
    }
    };

    const { id } = useParams();
    const [clientSecret, setClientSecret] = useState<string | null>(props.queryResult.intent_client_secret);

    useEffect(() => {
        if (!props.queryResult.intent_client_secret) {
            fetch(`https://api.ishemine.com/payments/get-secret/?query_id=${id}`, {
              method: "GET",
              headers: {
                Authorization: props.authToken,
              }
            }).then((response) => {
                if (response.ok) {
                  response.json().then((json) => {
                    setClientSecret(json.client_secret)
                  })
                }
              }
            )
            return;
          }
    }, []);

    return (
        <Layout>
            <div className="py-8 px-5 md:px-32 bg-red-400 rounded-lg">
                <h3 className="text-xl font-bold mb-2 text-white">Checkout</h3>
                <p className="text-lg mb-4 text-gray-100 font-semibold">$4.99</p>
                <p className="mb-4 text-sm text-gray-100">
                    By proceeding, you will be charged <span className="font-bold text-white">$4.99</span> for immediate access to the matching results. This includes uploaded photos, comments from others, and the option for anonymous chat with the uploader (if they used IsHeMine.com).
                </p>
                <div className="bg-white rounded-md shadow-lg">
                    { clientSecret && <Elements options={{
                        clientSecret: clientSecret,
                        appearance,
                    }} stripe={stripePromise}>
                        <CheckoutForm authToken={props.authToken} clientSecret={clientSecret} />
                    </Elements>}
                </div>
            </div>
        </Layout>
    )
}