import React, { useState, useRef, useEffect } from 'react';
import Testimonial from "./Testimonial";
import './Components.css';

// TODO(sofiane + liam): get real girls to write testimonials
const TestimonialsCarousel: React.FC = () => {
    const testimonials = [
        { 
            quote: "I stumbled upon this service by accident. Tried it on a whim and... let's just say I'm grateful. My suspicions were confirmed, and I was able to move on from a dishonest relationship.", 
            author: "Rachel S." 
        },
        { 
            quote: "I've always been a bit paranoid when it comes to trust. Knowing that my partner hasn't been scanned by anyone else puts my mind at ease. This tool is more valuable than I initially thought.",
            author: "Samantha L." 
        },
        { 
            quote: "Online dating can be a minefield. Thanks to this tool, I dodged a bullet. Discovered that the person I was about to meet had a shady track record. Better safe than sorry!", 
            author: "Cindy T." 
        },
        { 
            quote: "At first, I used this just out of curiosity. Now, I see it as a backup. If ever I get that odd feeling, I know there's a way to get some clarity.", 
            author: "Jenny M."
        },
        { 
            quote: "Who would've thought that technology could be this helpful in matters of the heart? I'm just glad I found out sooner rather than later. Saved me a ton of heartache.", 
            author: "Linda P." 
        }
    ];

    const [isScrolledRight, setIsScrolledRight] = useState(false);
    const [isScrolledLeft, setIsScrolledLeft] = useState(false);
    const testimonialsRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const container = testimonialsRef.current;
        const checkScroll = () => {
            if (container) {
                setIsScrolledRight(container.scrollWidth - container.scrollLeft === container.clientWidth);
                setIsScrolledLeft(container.scrollLeft > 0);
            }
        };
    
        if (container) {
            container.addEventListener("scroll", checkScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", checkScroll);
            }
        };
    }, []);

    return (
        <div className="bg-gray-100 p-4 relative">
            <h2 className="text-left text-lg md:text-xl font-bold mb-4 mt-2">What Our Users Say</h2>
            <div className="flex overflow-x-auto hide-scrollbar" ref={testimonialsRef}>
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="flex-none w-3/4 md:w-2/5 lg:w-1/5 px-2">
                        <Testimonial quote={testimonial.quote} author={testimonial.author} />
                    </div>
                ))}
            </div>
            {/* {!isScrolledRight && (
                <div className="absolute top-0 right-0 bottom-0 w-12 bg-gradient-to-l from-red-300 to-bg-red-200 pointer-events-none"></div>
            )}
            {isScrolledLeft && (
                <div className="absolute top-0 left-0 bottom-0 w-12 bg-gradient-to-r from-red-300 to-bg-red-200 pointer-events-none"></div>
            )} */}
            {/* <div className="absolute top-0 right-0 bottom-0 w-12 bg-gradient-to-l from-red-300 to-bg-red-200 pointer-events-none"></div> */}
        </div>
    );
};

export default TestimonialsCarousel;