import React, { useState, useRef } from 'react';

interface SlideshowProps {
    images: string[];
    full: boolean;
    currentIndex: number;
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

const Slideshow: React.FC<SlideshowProps> = ({ images, full, currentIndex, setCurrentIndex }) => {
    const [startX, setStartX] = useState<number | null>(null);
    const [dragging, setDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState(0);

    const containerRef = useRef<HTMLDivElement>(null);

    const handleDragStart = (event: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        let x;
        if ('touches' in event) {
            x = event.touches[0].clientX;
        } else {
            x = event.clientX;
        }
        setStartX(x);
        setDragging(true);
    };
    
    const handleDragMove = (event: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
        if (!dragging || startX === null) return;
        event.preventDefault();
        let x;
        if ('touches' in event) {
            x = event.touches[0].clientX;
        } else {
            x = event.clientX;
        }
        setDragOffset(x - startX);
    };

    const handleDragEnd = () => {
        if (!containerRef.current) return;

        const containerWidth = containerRef.current.offsetWidth;

        if (Math.abs(dragOffset) > containerWidth / 2) {
            if (dragOffset < 0 && currentIndex < images.length - 1) {
                setCurrentIndex(currentIndex + 1);
            } else if (dragOffset > 0 && currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
            }
        }
        setDragging(false);
        setStartX(null);
        setDragOffset(0);
    };
    
    return (
        <div
            ref={containerRef}
            className="flex flex-col items-center cursor-pointer"
            onMouseDown={handleDragStart}
            onMouseMove={handleDragMove}
            onMouseUp={handleDragEnd}
            onMouseLeave={handleDragEnd}
            onTouchStart={handleDragStart}
            onTouchMove={handleDragMove}
            onTouchEnd={handleDragEnd}
        >
            {full ? (
                <div className="relative w-full overflow-hidden">
                    <div className="relative w-full pb-[133.33%]">
                        {images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Slide ${index + 1}`}
                                style={{ transform: `translateX(calc(${index - currentIndex} * 100% + ${dragOffset}px))` }}
                                className="absolute object-cover top-0 left-0 w-full pb-[133.33%] transition-transform duration-500 ease-in-out"
                            />
                        ))}
                    </div>
                </div>
            ) : (
                <div className="relative overflow-hidden w-60 h-80">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Slide ${index + 1}`}
                            style={{ transform: `translateX(calc(${index - currentIndex} * 100% + ${dragOffset}px))` }}
                            className="absolute top-0 w-60 h-80 transition-transform duration-500 ease-in-out"
                        />
                    ))}
                </div>
            )}
            {
                images.length > 1 &&
                <div className="mt-3 flex space-x-2">
                    {images.map((_, index) => (
                        <span
                            key={index}
                            className={`block w-2 h-2 rounded-full ${currentIndex === index ? 'bg-blue-500' : 'bg-gray-400'}`}
                        ></span>
                    ))}
            </div>
            }
        </div>
    );    
}

export default Slideshow;
