import Layout from "../Layout";

const Privacy = () => {
    return (
        <Layout>
            <div className="text-left mx-auto p-6 max-w-3xl bg-red-100 shadow-md rounded-lg">
                <h1 className="text-3xl font-bold mb-6 border-b pb-2">Privacy Policy for IsHeMine.com</h1>
                <p className="mb-4 text-gray-600"><strong>Last updated:</strong> 8/26/23</p>

                <p className="mb-4">Welcome to IsHeMine.com. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data, your privacy rights, and how the law protects you.</p>

                <h2 className="text-xl font-semibold my-4">1. Important Information and Who We Are</h2>
                <h3 className="text-lg font-medium mt-2">Purpose of this Privacy Policy:</h3>
                <p className="mb-4">This policy aims to give you information on how IsHeMine.com collects and processes your personal data through your use of this website, including any data you may provide when you sign up for a newsletter or purchase a service.</p>

                <h3 className="text-lg font-medium mt-2">Controller:</h3>
                <p className="mb-4">IsHeMine.com is the controller and responsible for your personal data.</p>

                <h3 className="text-lg font-medium mt-2">Contact Details:</h3>
                <div className="bg-red-200 p-4 rounded mb-4">
                    <p>Email: <a href="mailto:help@ishemine.com" className="text-blue-500 hover:underline">help@ishemine.com</a></p>
                </div>

                <h2 className="text-xl font-semibold my-4">2. The Data We Collect About You</h2>
                <p>We may collect, use, store, and transfer the following kinds of personal data:</p>
                <ul className="list-disc pl-5 mb-4">
                    <li><strong>Identity Data</strong> includes first name, last name, username, or similar identifier.</li>
                    <li><strong>Contact Data</strong> includes billing address, delivery address, email address, and phone numbers.</li>
                    <li><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and version, time zone setting, location, and other technology on the devices you use to access this website.</li>
                    <li><strong>Image Data</strong> includes photographs that users upload to our platform. Please ensure you only upload images that you own or have permission to use. Unauthorized sharing of images without consent may violate privacy regulations and personal rights.</li>
                </ul>

                <h2 className="text-xl font-semibold my-4">3. How We Use Your Personal Data</h2>
                <p>We will only use your personal data when the law allows us to. Commonly, we will use your personal data in the following circumstances:</p>
                <ul className="list-disc pl-5 mb-4">
                    <li>To register you as a new customer.</li>
                    <li>To process and deliver your order.</li>
                    <li>To manage our relationship with you.</li>
                    <li>To send you newsletters or marketing communications.</li>
                </ul>

                <h2 className="text-xl font-semibold my-4">4. Image Ownership and Opt-Out</h2>
                <p>We respect the rights of individuals and acknowledge the importance of personal privacy. If you believe an image of you has been uploaded without your consent, you can request its removal. To opt-out and have your image removed, please contact us using the provided contact details. We emphasize that users should only upload images they own or have the right to share.</p>

                <h2 className="text-xl font-semibold my-4">5. Data Security</h2>
                <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way. We limit access to your personal data to those employees, agents, and other third parties who have a need to know.</p>

                <h2 className="text-xl font-semibold my-4">6. Data Retention</h2>
                <p>We will only retain your personal data for as long as necessary to fulfill the purposes for which we collected it.</p>

                <h2 className="text-xl font-semibold my-4">7. Your Legal Rights</h2>
                <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data:</p>
                <ul className="list-disc pl-5 mb-4">
                    <li>Request access to your personal data.</li>
                    <li>Request correction of your personal data.</li>
                    <li>Request erasure of your personal data.</li>
                    <li>Object to processing of your personal data.</li>
                </ul>
                <p>If you wish to exercise any of these rights, please contact us.</p>

                <h2 className="text-xl font-semibold my-4">8. Third-Party Links</h2>
                <p>This website may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy policies.</p>

                <h2 className="text-xl font-semibold my-4">9. Updates to this Privacy Policy</h2>
                <p>Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by email.</p>
            </div>
        </Layout>
    );
};

export default Privacy;
