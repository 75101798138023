const Footer = () => {


    return (
        <footer className="bottom-0 left-0 w-full bg-white shadow-md p-6 text-left">
            <div className="mx-auto text-sm">
                <div className="flex flex-col flex-wrap md:w-1/3 justify-between items-start space-y-4 lg:space-y-0 md:flex-row">
                    {/* <div>
                        <h6 className="font-semibold text-gray-700 mb-2">Contact</h6>
                        <p>Email: support@example.com</p>
                        <p>Phone: (123) 456-7890</p>
                    </div> */}
                    <div>
                        <h6 className="font-semibold text-gray-700 mb-2">Follow Us</h6>
                        <p><span className="hover:underline hover:opacity-70"><a href="https://instagram.com/is.he.mine?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr">Instagram</a></span> | <span><a href="https://www.facebook.com/groups/1396228454264710/">Facebook</a></span> | <span className="hover:underline hover:opacity-70"><a href="https://www.tiktok.com/@is.he.mine?_t=8gLWDh4xZYF&_r=1">TikTok</a></span> | <span className="hover:underline hover:opacity-70"><a href="https://twitter.com/IsHeMine_com">Twitter</a></span></p>
                    </div>
                    <div>
                        <h6 className="font-semibold text-gray-700 mb-2">Company</h6>
                        <p className="hover:underline hover:opacity-70"><a href="/contact">Contact Us</a></p>
                        <p className="hover:underline hover:opacity-70"><a href="/privacy">Privacy Policy</a></p>
                        <p className="hover:underline hover:opacity-70"><a href="/comm-guide">Community Guidelines</a></p>
                        {/* <p className="hover:underline hover:opacity-70"><a href="/opt-out">Opt Out of Results</a></p> */}
                    </div>
                </div>
                <div className="mt-4 border-t pt-4">
                    <p className="text-center text-gray-600">© 2023 OuttaControl Inc. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;