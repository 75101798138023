import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import './Pages.css';
import PhotoUploadGrid from '../Components/PhotoUploadGrid';
import BlueSubmitBtn from '../Components/BlueSubmitBtn';
import { useNavigate } from 'react-router-dom';
import { ImageData } from './FindYourMan';

type UploadHimProps = {
    authToken: string;
    photoIds: Array<string>;
    setPhotoIds: React.Dispatch<React.SetStateAction<Array<string>>>;
    setImageData: React.Dispatch<React.SetStateAction<Array<ImageData>>>;
}

const UploadHim: React.FC<UploadHimProps> = ({ authToken, photoIds, setPhotoIds, setImageData }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [photoUploaded, setPhotoUploaded] = useState(false);
    const [numPhotos, setNumPhotos] = useState(0); // What is the point of this when we can and should do photoIds.length ?
    const [submitted, setSubmitted] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [showAltContinue, setShowAltContinue] = useState(false);

    useEffect(() => {
        // clear out any old photoIds from local storage
        setPhotoIds([]);
    }, []);

    useEffect(() => {
        setShowAltContinue(false);
        if (numPhotos > 0) setPhotoUploaded(true);
        else {
            setPhotoUploaded(false);
            if (submitted) setSubmitted(false);
        }
    }, [numPhotos]);

    function toggleLoading() {
        setIsLoading((prevLoading) => !prevLoading);
    }

    const handleContinue = () => {
        setShowAltContinue(false);
        toggleLoading();
        setSubmitted(true);
        const myHeaders = new Headers();
        myHeaders.append("Authorization", authToken);
        let endpoint = "https://api.ishemine.com/evals/results/?";
        if (photoIds.length === 0) {
            // hacky - leave until we figure out what the real issue is
            setTimeout(handleContinue, 1000);
            return;
        }
        for (const id of photoIds) {
            endpoint += `ids=${id}&`
        }
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(endpoint, requestOptions)
        .then(response => response.text())
        .then(resp => {
            toggleLoading();
            const results = JSON.parse(resp);
            console.log(results);
            if (!photoIds.every(id => results[id])) {
                // Some photos were not returned in the response. This would be really weird.
                setErrorMsg("Error occured. Please try again and reupload photos!");
                setPhotoUploaded(false);
                setSubmitted(false);
                return;
            }
            if (photoIds.every(id => results[id].status === "complete" && results[id].results.length === 0)) {
                // All photos were indexed but no faces were found. Cannot continue.
                setErrorMsg("No faces were found in any of the photos. Upload new photos.");
                setPhotoUploaded(false);
                setSubmitted(false);
                return;
            }
            const loadedFaces = photoIds.filter(id => results[id].status === "complete" && results[id].results.length > 0);
            if (loadedFaces.length > 0) {
                var imageDatas: Array<ImageData> = []
                for (const id of loadedFaces) {
                    const newImageDatas = results[id].results;
                    imageDatas = imageDatas.concat(newImageDatas.map((imageData: any) => {
                        return {
                            id: id,
                            status: "complete",
                            ...imageData
                        }
                    }));
                }
                // Needs to be set for next page before any alt-continue can happen
                setImageData(imageDatas);
            }
            const notFinished = photoIds.filter(id => results[id].status === "pending");
            if (notFinished.length > 0) {
                // Some photos are still being indexed
                setErrorMsg("Some photos were not indexed yet. Try again in a second.");
                setPhotoUploaded(false);
                setSubmitted(false);
                setShowAltContinue(true);
                return;
            }
            const noFaces = photoIds.filter(id => results[id].status === "complete" && results[id].results.length === 0);
            if (noFaces.length > 0) {
                // Some photos were indexed but no faces were found
                setErrorMsg("Some photos did not contain a face. Try again?");
                setPhotoUploaded(false);
                setSubmitted(false);
                setShowAltContinue(true);
                return;
            }
            navigate("/find-your-man")
        })
        .catch(error => {
            console.log('error in indexing', error);
            setErrorMsg("Error occured. Please try again!");
            setPhotoUploaded(true);
            setSubmitted(false);
        })
    };

    return (
        <Layout>
            <div className="center-container">
                <h1 className="dela-gothic text-2xl md:text-4xl mt-4 mb-4 md:m-4">Upload Pictures of Him</h1>
                <div className="flex flex-col items-center">
                    <p className="font-bold text-zinc-700 w-7/8 md:w-4/5 text-sm md:text-base text-center">(Max 6. Min. 1) The more photos you upload, the more likely a match will be found (if there is one).</p>
                </div>
                <PhotoUploadGrid numPhotos={numPhotos} setNumPhotos={setNumPhotos} authToken={authToken} setPhotoIds={setPhotoIds} />
                <div className="text-left mt-5 text-sm font-bold">
                    <p>
                        We keep all photos confidential to everyone except others who upload the same man. 
                    </p>
                    <p className="mt-2">
                        Make sure his face is pointed at the camera and unobstructed. Pics that contain both of you tend to do best...
                    </p>
                </div>
                <div className="md:bottom-5 w-full md:w-3/4 lg:w-1/2 p-4">
                    { errorMsg && <p className="text-red-500 text-sm mb-4">{errorMsg}</p>
                    }
                    {showAltContinue && 
                        <BlueSubmitBtn isLoading={isLoading} numberEntered={true} handleClick={handleContinue} text={"Try Again"}/>
                    }
                    {showAltContinue ? 
                        <BlueSubmitBtn isLoading={isLoading} numberEntered={true} handleClick={() => navigate("/find-your-man")} text={"Continue anyway"}/> :
                        <BlueSubmitBtn isLoading={isLoading} numberEntered={photoUploaded} handleClick={handleContinue} text={"Continue"}/>}
                </div>
            </div>
        </Layout>
    );
};

export default UploadHim;