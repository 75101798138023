import React from 'react';

type WordleCardBackProps = {
    letter: string;
};

const WordleCardBack:React.FC<WordleCardBackProps> = ({ letter }) => {
    return (
        <div className="w-8 md:w-10 h-8 md:h-10 bg-green-600 flex items-center justify-center">
            {letter}
        </div>
    );
};

export default WordleCardBack;